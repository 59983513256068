import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DropdownContainer = styled.div`
  background-color: ${Colors.WHITE};
`;

export const LabelContainer = styled.div`
  padding: 0 15px;
  height: 43px;
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => (props.transparent ? Colors.WHITE : Colors.DROPDOWN_ITEM)};
  line-height: 43px;
`;

export const DropdownItemContainer = styled.div`
  height: 50px;
  padding: 0 20px;
  display: flex;
  min-width: 185px;
  align-items: center;
  background-color: ${(props) => (props.active ? 'rgba(64, 75, 105, 0.07)' : Colors.WHITE)};

  &:hover {
    cursor: pointer;
    background-color: rgba(64, 75, 105, 0.07);
  }
`;

export const DropdownItem = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${Colors.DROPDOWN_ITEM};
  white-space: nowrap;
`;
