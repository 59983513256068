import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: ${Colors.WHITE};
  flex-direction: ${(props) => (props.reversed ? 'column-reverse' : 'column')};

  @media only screen and (max-width: 1110px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${Colors.NEWSLETTER_BACKGROUND};
  border-radius: 8px;
  position: relative;
  margin-bottom: ${(props) => (props.reversed ? 0 : 20)}px;
  margin-top: ${(props) => (props.reversed ? 20 : 0)}px;

  @media only screen and (max-width: 1110px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

export const Details = styled.div``;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 15px;
  z-index: 99;
`;
