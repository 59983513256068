import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.form`
  width: 100%;
`;

export const Title = styled.h4`
  color: ${Colors.TITLE};
  font-size: 32px;
  font-weight: 700;
`;
