import React from 'react';
import PropTypes from "prop-types";
import {Container, Title, Description, Details, IconContainer, Icon} from './styles';
import {Images} from "styles";

function WarningMessage(props) {
  const {style, title, description, borderRadius} = props;

  return (
    <Container borderRadius={borderRadius} style={style}>
      <IconContainer>
        <Icon src={Images.WARNING}/>
      </IconContainer>
      <Details>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Details>
    </Container>
  );
}

WarningMessage.propTypes = {
  style: PropTypes.object,
  borderRadius: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

WarningMessage.defaultProps = {
  style: {},
  borderRadius: 6,
};

export default WarningMessage;
