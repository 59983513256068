import {SubscribersTypes} from "types";
import {SubscribersService} from "services";

export function addSubscriber(request) {
    return async dispatch => {
        try {
            dispatch({ type: SubscribersTypes.ADD_SUBSCRIBER_START });
            await SubscribersService.addSubscriber(request);
            dispatch({type: SubscribersTypes.ADD_SUBSCRIBER});
        } catch (exception) {
            dispatch({ type: SubscribersTypes.ADD_SUBSCRIBER_ERROR });
        }
    };
}
