import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 15px;
`;

export const Item = styled.span`
  font-size: 14px;
  line-height: 2.57;
  color: ${Colors.INCLUSIVE_TITLE};
`;

export const Icon = styled.img`
  margin-right: 15px;
`;
