import styled from 'styled-components/macro';
import { Colors } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
  width: 100%;
`;

export const TextInput = styled.textarea`
  height: 160px;
  resize: none;
  line-height: 1.5;
  width: 100%;
  border: 1px solid ${(props) => (props.error ? Colors.DANGER : Colors.CHECKBOX_BORDER)};
  border-radius: 6px;
  background-color: ${Colors.WHITE};
  padding: 15px 20px;
  font-size: 14px;
  color: ${Colors.BLACK};
  transition: 0.3s;

  &:focus,
  &:hover {
    box-shadow: 0 1px 6px ${hexToRgba(Colors.LIGHT_PRIMARY, 0.7)};
    border: 1px solid ${Colors.LIGHT_PRIMARY};
  }
`;
