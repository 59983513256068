import React from 'react';
import PropTypes from "prop-types";
import {Container, Content, PageContent} from './styles';
import {Grid} from 'styles';
import {ProfileNavigation} from "containers";

function ProfileWrapper(props) {
  const {style, children} = props;

  return (
    <Container style={style}>
      <Grid.Container>
        <Content>
          <ProfileNavigation/>
          <PageContent>
            {children}
          </PageContent>
        </Content>
      </Grid.Container>
    </Container>
  );
}

ProfileWrapper.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

ProfileWrapper.defaultProps = {
  style: {},
};

export default ProfileWrapper;
