import React, {useState, useRef} from "react";
import PropTypes from 'prop-types';
import {Container, Content, DropdownContainer, Tab, DatePickerWrapper, LoaderContainer} from './styles';
import {Form} from "styles";
import Dropdown from "../Dropdown";
import {DateUtils, SharedTypes} from "utils";
import Tabs from "../Tabs";
import MonthSelect from "./MonthSelect";
import DateSelect from "./DateSelect";
import {useDates} from "hooks";
import Loader from "../Loader";
import _ from 'lodash';

const TABS = [
  {key: 'date', title: 'Tiksli data'},
  {key: 'interval', title: 'Intervalas'},
  {key: 'season', title: 'Sezonas'},
];

function DatePicker(props) {
  const {label, style, value, dirty, error, placeholder, onChange, name, large, onBlur, departureId, destinationId, disabled, forceOpen, monthsShown, transparent, blueTop} = props;
  const {availableDates, isLoading} = useDates({departureId, destinationId});
  const [activeTab, setTabActive] = useState(DateUtils.getActiveTab(value));
  const ref = useRef();

  const handleOnChange = (newValue) => {
    if (!_.isEqual(newValue, value)) {
      onChange({name, value: newValue});
    }

    if (ref && ref.current) {
      ref.current.close();
    }
  };

  const handleOnBlur = () => {
    onBlur({name});
  };

  const renderContent = () => {
    const dateValue = DateUtils.getDateValue(value);

    if (dateValue) {
      return <Form.Value large={large}>{dateValue}</Form.Value>
    }

    if (placeholder) {
      return <Form.Placeholder large={large}>{placeholder}</Form.Placeholder>
    }
  };

  const renderButton = () => (
    <Form.Container large={large} error={dirty && error}>
      <Content>
        {renderContent()}
      </Content>
    </Form.Container>
  );

  const renderDropdown = () => (
    <DropdownContainer>
      <Tabs
        blueTop={blueTop}
        tabs={TABS}
        onSelect={(tab) => setTabActive(tab)}
        active={activeTab}
      />
      <Tab active={activeTab === 'season'}>
        <MonthSelect
          value={value}
          onChange={handleOnChange}
        />
      </Tab>
      <Tab active={activeTab === 'interval' || activeTab === 'date'}>
        <DateSelect
          monthsShown={monthsShown}
          availableDates={availableDates}
          value={value}
          isRange={activeTab === 'interval'}
          onChange={handleOnChange}
        />
      </Tab>
    </DropdownContainer>
  );

  return (
    <Container style={style}>
      {label && <Form.Label>{label}</Form.Label>}
      <DatePickerWrapper>
        <Dropdown
          transparent={transparent}
          forceOpen={forceOpen}
          ref={ref}
          disabled={isLoading || disabled}
          onClose={handleOnBlur}
          fullWidth={false}
          renderButton={renderButton}
          renderDropdown={renderDropdown}
        />
        {isLoading && (
          <LoaderContainer>
            <Loader/>
          </LoaderContainer>
        )}
      </DatePickerWrapper>
      {dirty && error && <Form.Error>{error}</Form.Error>}
    </Container>
  )
}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: SharedTypes.DateValueType,
  dirty: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  forceOpen: PropTypes.bool,
  destinationId: PropTypes.number,
  departureId: PropTypes.number,
  monthsShown: PropTypes.number,
  transparent: PropTypes.bool,
  blueTop: PropTypes.bool,
};

DatePicker.defaultProps = {
  style: {},
  label: null,
  departureId: null,
  destinationId: null,
  onBlur: () => {},
  value: {dateFrom: null, dateTo: null},
  dirty: false,
  large: false,
  error: null,
  disabled: false,
  forceOpen: false,
  transparent: false,
  blueTop: false,
  placeholder: null,
  monthsShown: 2
};

export default DatePicker;
