import React from 'react';
import PropTypes from "prop-types";
import {Buttons, Grid, Images} from 'styles';
import {Avatar, Button, Dropdown} from "components";
import {useHistory} from "react-router-dom";
import CategorySelect from "../CategorySelect";
import {AuthenticationActions} from "actions";
import {connect} from "react-redux";
import {
  Container,
  Navigation,
  Logo,
  NavigationItemContainer,
  LogoLink,
  NavigationItem,
  NavigationLink,
  NavigationIcon,
  ProfileName,
  ProfileExpandIcon,
  ProfileButton,
  Badge,
  PhoneIcon,
  PhoneContainer,
  Phone,
  Help,
  PhoneContent,
  PhoneContentWrapper,
} from './styles';
import {SharedTypes} from "utils";
import ProfileNavigation from "../ProfileNavigation";
import MobileNavigation from './MobileNavigation'

function Header(props) {
  const {style, transparent, isLogged, profile} = props;
  const history = useHistory();


  const renderNavigationItem = (to, label, uppercase = false, badge = null) => (
    <NavigationLink activeClassName="active" to={to}>
      <NavigationItemContainer transparent={transparent}>
        <NavigationItem transparent={transparent} uppercase={uppercase}>{label}</NavigationItem>
        {badge && <Badge>{badge}</Badge>}
      </NavigationItemContainer>
    </NavigationLink>
  );

  const renderCategoriesButton = () => (
    <NavigationItemContainer transparent={transparent} noPaddingRight>
      <NavigationItem transparent={transparent} uppercase>Kelionės</NavigationItem>
      <NavigationIcon src={transparent ? Images.ARROW_DOWN : Images.ARROW_DOWN_GREY}/>
    </NavigationItemContainer>
  );

  const renderProfileButton = () => (
    <ProfileButton transparent={transparent}>
      <Grid.Col mr={10}>
        <Avatar size={40}/>
      </Grid.Col>
      <Grid.Col>
        <ProfileName>{`${profile.firstName} ${profile.lastName}`}</ProfileName>
      </Grid.Col>
      <Grid.Col>
        <ProfileExpandIcon src={transparent ? Images.ARROW_DOWN : Images.ARROW_DOWN_GREY}/>
      </Grid.Col>
    </ProfileButton>
  );

  const renderProfileMenu = () => (
    <ProfileNavigation dropdown/>
  );

  const renderPhoneDropdown = () => (
    <PhoneContainer>
      <PhoneIcon src={transparent ? Images.PHONE_WHITE : Images.PHONE}/>
      <PhoneContentWrapper>
        <PhoneContent>
          <Phone href="tel:+37052661616">+370 5 266 1616</Phone>
          <Help>Darbo dienomis 8:00 - 18:00</Help>
        </PhoneContent>
      </PhoneContentWrapper>
    </PhoneContainer>
  );

  return (
    <Container transparent={transparent} style={style}>
      <Navigation>
        <Grid.Desktop>
          <Grid.Container>
            <Grid.Row>
              <Grid.Col flex={1}>
                <Grid.Row>
                  <Grid.Col mr={25}>
                    <LogoLink to="/">
                      <Logo src={transparent ? Images.LOGO_WHITE : Images.LOGO}/>
                    </LogoLink>
                  </Grid.Col>
                  <Grid.Col>
                    {renderNavigationItem('/specialus-pasiulymai', 'Top pasiūlymai', true, 10)}
                  </Grid.Col>
                  <Grid.Col>
                    <CategorySelect renderButton={renderCategoriesButton} transparent={transparent}/>
                  </Grid.Col>
                  <Grid.Col>
                    {renderNavigationItem('/paskutines-minutes-pasiulymai', 'Paskutinė minutė', true)}
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col>
                <Grid.Row>
                  <Grid.Col>
                    {renderNavigationItem('/duk', 'DUK')}
                  </Grid.Col>
                  <Grid.Col>
                    {renderNavigationItem('/apie-mus', 'Apie mus')}
                  </Grid.Col>
                  <Grid.Col mr={15}>
                    {renderNavigationItem('/kontaktai', 'Kontaktai')}
                  </Grid.Col>
                  <Grid.Col mr={30}>
                    {renderPhoneDropdown()}
                  </Grid.Col>
                  <Grid.Col>
                    {!isLogged && (
                        <Button
                            theme={transparent ? Buttons.WHITE : Buttons.PRIMARY}
                            small
                            onClick={() => history.push('/prisijungimas')}
                            label="Prisijungti"
                        />
                    )}
                    {isLogged && (
                        <Dropdown
                            fullWidth={false}
                            renderButton={renderProfileButton}
                            renderDropdown={renderProfileMenu}
                        />
                    )}
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        </Grid.Desktop>
       <MobileNavigation
           transparent={transparent}
           renderNavigationItem={renderNavigationItem}
           isLogged={isLogged}
           history={history}
       />
      </Navigation>
    </Container>
  );
}

Header.propTypes = {
  style: PropTypes.object,
  transparent: PropTypes.bool,
  isLogged: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  profile: SharedTypes.ProfileType,
};

Header.defaultProps = {
  style: {},
  profile: null,
  transparent: false,
};

function mapStateToProps(state) {
  const {authentication} = state;
  const {isLogged, profile} = authentication;
  return {isLogged, profile};
}

const mapDispatchToProps = {
  logout: AuthenticationActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
