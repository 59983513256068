import styled from 'styled-components/macro';
import { Colors } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 ${hexToRgba(Colors.BLACK, 0.1)};
  overflow: hidden;
  position: relative;
`;

export const ImagesContainer = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  border-radius: 8px;
  background-color: ${Colors.NEWSLETTER_BACKGROUND};
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 15px;
  z-index: 99;
`;

export const Details = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;
