import React from 'react';
import {Container, Image, Name, Email, Phone} from './styles';
import PropTypes from "prop-types";
import {Grid} from 'styles';

function Person(props) {
  const {image, name, email, phone} = props;

  return (
    <Container>
      <Grid.Col mr={30}>
        <Image src={image}/>
      </Grid.Col>
      <Grid.Col>
        <Grid.Row mb={25}>
          <Name>{name}</Name>
        </Grid.Row>
        <Grid.Row mb={3}>
          <Email href={`mailto:${email}`}>{email}</Email>
        </Grid.Row>
        <Grid.Row>
          <Phone href={`tel:${phone}`}>{phone}</Phone>
        </Grid.Row>
      </Grid.Col>
    </Container>
  );
}

Person.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default Person;
