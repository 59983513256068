import update from 'immutability-helper';
import {EmployeesTypes} from 'types';

const initialState = {
  employees: [],
  isLoaded: false,
  isLoading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case EmployeesTypes.GET_EMPLOYEES_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case EmployeesTypes.GET_EMPLOYEES: {
      return update(state, {
        employees: {$set: payload.employees},
        isLoaded: {$set: true},
        isLoading: {$set: false},
      });
    }

    case EmployeesTypes.GET_EMPLOYEES_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
