import {Images} from "styles";
import moment from "moment";
import {DateUtils} from "utils/index";

export const SUMMER_MONTHS = [
  {value: 6, label: 'Birželis'},
  {value: 7, label: 'Liepa'},
  {value: 8, label: 'Rugpjūtis'},
];

export const SPRING_MONTHS = [
  {value: 3, label: 'Kovas'},
  {value: 4, label: 'Balandis'},
  {value: 5, label: 'Gegužė'},
];

export const AUTUMN_MONTHS = [
  {value: 9, label: 'Rugsėjis'},
  {value: 10, label: 'Spalis'},
  {value: 11, label: 'Lapkritis'},
];

export const WINTER_MONTHS = [
  {value: 12, label: 'Gruodis'},
  {value: 1, label: 'Sausis'},
  {value: 2, label: 'Vasaris'},
];

export const SEASONS = [
  {
    name: 'Žiema',
    icon: Images.WINTER,
    months: WINTER_MONTHS
  },
  {
    name: 'Pavasaris',
    icon: Images.SPRING,
    months: SPRING_MONTHS
  },
  {
    name: 'Vasara',
    icon: Images.SUMMER,
    months: SUMMER_MONTHS
  },
  {
    name: 'Ruduo',
    icon: Images.AUTUMN,
    months: AUTUMN_MONTHS
  }
];

export const getDateValue = (value) => {
  if (value && value.dateFrom && value.dateTo) {

    if (value.dateFrom === value.dateTo) {
      return value.dateFrom;
    }

    return `${value.dateFrom} - ${value.dateTo}`;
  }

  return null;
};

export const getActiveTab = () => {
  return 'interval';
};

export const formatDate = (date) => {
  if (!date) {
    return null;
  }

  return moment(date).format('YYYY-MM-DD');
};

export const getMonthRange = (month) => {
  const currentMonth = moment().month() + 1;
  const dateFrom = moment().month(month - 1).startOf("month");
  const dateTo = moment().month(month - 1).endOf("month");

  if (currentMonth > month) {
    dateFrom.add(1, 'year');
    dateTo.add(1, 'year');
  }

  return {dateFrom: DateUtils.formatDate(dateFrom.toDate()), dateTo: DateUtils.formatDate(dateTo.toDate())};
};
