import styled from 'styled-components/macro';
import { Media } from 'styles';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.itemsCount}, 1fr);
  grid-row-gap: ${(props) => props.rowGap}px;
  grid-column-gap: ${(props) => props.columnGap}px;

  @media only screen and (max-width: ${Media.SMALL_DESKTOP}px) {
    grid-template-columns: repeat(${(props) => props.smallDesktopItemsCount}, 1fr);
  }

  @media only screen and (max-width: ${Media.TABLET}px) {
    grid-template-columns: repeat(${(props) => props.tabletItemsCount}, 1fr);
  }

  @media only screen and (max-width: ${Media.MOBILE}px) {
    grid-template-columns: repeat(${(props) => props.mobileItemsCount}, 1fr);
  }
`;
