import {Route, Redirect} from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useLocation} from 'react-router-dom';

function ProtectedRoute(props) {
  const {isLogged} = props;
  let {pathname} = useLocation();

  if (!isLogged) {
    const redirectPath = {pathname: '/prisijungimas', state: {from: pathname}};
    return <Redirect to={redirectPath}/>;
  }

  return <Route {...props}/>;
}

ProtectedRoute.propTypes = {
  isLogged: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { isLogged } = authentication;
  return { isLogged };
}

export default connect(mapStateToProps)(ProtectedRoute);
