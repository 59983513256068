import React from "react";
import {Content, Text, BoldText} from "./styles";
import CookieConsent from "react-cookie-consent";

function CookiesPopup() {
  return (
    <CookieConsent
      containerClasses="cookies-container"
      contentClasses="cookies-content"
      buttonClasses="cookies-button"
      disableStyles
      buttonText="Sutinku"
    >
      <Content>
        <Text>
          🍪 <BoldText>Pssst, mes naudojame sausainiukus.</BoldText> Siekdami pagerinti Jūsų naršymo kokybę,
          statistiniais ir
          rinkodaros tikslais šioje svetainėje naudojame slapukus (angl. &quatcookies&quat), kuriuos galite
          bet kada tvarkyti pakeisdami savo interneto naršyklės nustatymus. Daugiau apie tai
          skaitykite: privatumo politika.
        </Text>
      </Content>
    </CookieConsent>
  )
}

export default CookiesPopup;
