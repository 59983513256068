import React from 'react';
import PropTypes from "prop-types";
import Collapse, {Panel} from "rc-collapse";
import {Content, Container} from './styles';

function CollapsibleList(props) {
  const {items} = props;

  return (
    <Container className="collapsible-list plus-content">
      <Collapse accordion>
        {items.map((item, index) => (
          <Panel key={index} header={item.title}>
            <Content dangerouslySetInnerHTML={{__html: item.content}}/>
          </Panel>
        ))}
      </Collapse>
    </Container>
  );
}

const CollapsibleItemType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
});

CollapsibleList.propTypes = {
  items: PropTypes.arrayOf(CollapsibleItemType).isRequired,
};

export default CollapsibleList;
