import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  transition: 0.3s;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 11px 0 rgba(13, 115, 185, 0.37);
  }
`;

export const Image = styled.img`
  width: 100%;
  height: ${(props) => (props.largeHeight ? props.largeHeight : 150)}px;
  border-radius: 8px;
  object-fit: cover;
`;

export const Details = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.39px;
  color: ${Colors.TITLE};
  margin-bottom: 5px;
`;

export const OffersCount = styled.span`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2.25px;
  color: ${Colors.SEASON};
  text-transform: uppercase;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 15px;
  background-color: ${Colors.RATING};
  height: 22px;
  border-radius: 4px;
  padding: 0 10px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Badge = styled.span`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${Colors.BLACK};
`;
