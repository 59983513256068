import tvsAxiosInstance from "../tvsAxiosInstance";

class ArticlesService {
  static async getArticles() {
    return await tvsAxiosInstance.get(`/blog`);
  }

  static async getArticle(slug) {
    return await tvsAxiosInstance.get(`/blog/${slug}`);
  }
}

export default ArticlesService;
