import styled from 'styled-components/macro';
import { Colors } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';

export const Container = styled.div`
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid ${Colors.BORDER};
  transition: 0.3s;

  &:hover {
    box-shadow: rgb(198, 207, 224) 0px 0px 5px;
    cursor: pointer;
  }
`;

export const Sender = styled.span`
  font-size: 13px;
  font-weight: 700;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Date = styled.span`
  font-size: 11px;
  color: ${Colors.HELP};
`;

export const Title = styled.span`
  color: ${Colors.TITLE};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;

export const Description = styled(LinesEllipsis)`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 13px;
  line-height: 18px;
`;
