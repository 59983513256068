import React from 'react';
import PropTypes from "prop-types";
import {Container, Content, Title} from './styles';
import {Grid, Images} from 'styles';

function Banner(props) {
  const {style, title, backgroundImage, renderBottomContent, fontSize} = props;

  return (
    <Container backgroundImage={backgroundImage} style={style}>
      <Grid.Container>
        <Content>
          <Title fontSize={fontSize}>{title}</Title>
          {renderBottomContent && renderBottomContent()}
        </Content>
      </Grid.Container>
    </Container>
  );
}

Banner.propTypes = {
  style: PropTypes.object,
  backgroundImage: PropTypes.string,
  renderBottomContent: PropTypes.func,
  title: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
};

Banner.defaultProps = {
  style: {},
  fontSize: 54,
  renderBottomContent: null,
  backgroundImage: Images.BANNER_BACKGROUND,
};

export default Banner;
