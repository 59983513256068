import React from 'react';
import {Email, FinanceText, Help, Icon, Phone, SocialContainer, Location} from './styles';
import {Grid, Images, Typography} from "styles";
import {ContactsForm} from "containers";
import {Collapsible, Map} from "components";
import {PageLayout} from "layouts";
import Person from "./Person";

function ContactsPage() {

  return (
    <PageLayout title="Kontaktai">
      <Grid.Container>
        <Grid.Row mb={70} alignItems="flex-start">
          <Grid.Col flex={1}>
            <Grid.Row className="no-break" mt={20} mb={45}>
              <Grid.Col className="no-break" mr={15}>
                <Icon src={Images.PHONE_GREY}/>
              </Grid.Col>
              <Grid.Col className="no-break">
                <Phone href="tel:+37052661616">+370 5 266 1616</Phone>
                <Help>Darbo dienomis 8:00 - 18:00</Help>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="no-break" mb={45}>
              <Grid.Col className="no-break" mr={15}>
                <Icon src={Images.MAIL_GREY}/>
              </Grid.Col>
              <Grid.Col className="no-break">
                <Email href="mailto:info@baltictours.lt">info@baltictours.lt</Email>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="no-break" mb={45}>
              <Grid.Col className="no-break" mr={15}>
                <Icon src={Images.PIN_GREY}/>
              </Grid.Col>
              <Grid.Col className="no-break">
                <Location
                  target="_blank"
                  href="https://www.google.com/maps?q=Va%C5%A1ingtono+a.+1,+Vilnius&um=1&ie=UTF-8&sa=X&ved=2ahUKEwij99Xu6OznAhVFlosKHRyOAwMQ_AUoAXoECAwQAw">
                  Vašingtono a. 1, Vilnius
                </Location>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="no-break" mb={45}>
              <Grid.Col className="no-break" mr={15}>
                <SocialContainer target="_blank" href="https://www.facebook.com/baltictoursgroup/">
                  <Icon src={Images.FACEBOOK_GREY}/>
                </SocialContainer>
              </Grid.Col>
              <Grid.Col className="no-break" mr={15}>
                <SocialContainer target="_blank" href="https://www.instagram.com/baltictours.lt/">
                  <Icon src={Images.INSTAGRAM_GREY}/>
                </SocialContainer>
              </Grid.Col>
              <Grid.Col className="no-break">
                <SocialContainer target="_blank" href="https://www.linkedin.com/company/baltic-tours-group/">
                  <Icon src={Images.LINKEDIN_GREY}/>
                </SocialContainer>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row mb={45}>
              <Collapsible width={210} renderHeader={() => <FinanceText>Įmonės rekvizitai</FinanceText>}>
                <FinanceText>
                  Baltic Tours Group, UAB
                </FinanceText>
                <FinanceText>
                  Įmonės kodas 111518422
                </FinanceText>
                <FinanceText>
                  PVM mokėtojo kodas LT115184219
                </FinanceText>
                <FinanceText>
                  A.s. LT127300010075875762
                </FinanceText>
                <FinanceText>
                  SWIFT HABALT22
                </FinanceText>
              </Collapsible>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col flex={2}>
            <ContactsForm/>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <Grid.Row mb={70}>
        <Map icon={Images.LOGO_PIN}/>
      </Grid.Row>
      <Grid.Container>
        <Grid.Row mb={50}>
          <Typography.PageTitle>Poilsinių ir pažintinių kelionių ekspertų kontaktai</Typography.PageTitle>
        </Grid.Row>
        <Grid.Row>
          <Grid.Grid itemsCount={2} columnGap={50}>
            <Person
              image="https://baltic.webstrum.com/uploads/5dfa130f1bcf9.png"
              name="Andrius Paulionis"
              email="andrius@baltictours.lt"
              phone="+370 5 241 0000"
            />
            <Person
              image="https://baltic.webstrum.com/uploads/5dfa2734d99bd.png"
              name="Ramunė Krivkaitė"
              email="ramune@baltictours.lt"
              phone="+370 5 200 0120"
            />
          </Grid.Grid>
        </Grid.Row>
      </Grid.Container>
    </PageLayout>
  );
}

export default ContactsPage;
