import React from 'react';
import {Container} from "./styles";
import {ArticleImageCard, Button} from "components";
import {Grid} from "styles";
import {useHistory} from 'react-router-dom';
import {useArticles} from "hooks";
import {SectionLayout} from "layouts";

function Blog() {
  const {allArticles, isLoaded} = useArticles();
  const history = useHistory();

  return (
    <Container>
      <Grid.Container>
        <SectionLayout title="Naujausios kelionių istorijos" center>
          {isLoaded && allArticles.length > 2 && (
            <Grid.Row mb={50}>
              <Grid.Col mr={10} flex={2}>
                <ArticleImageCard large article={allArticles[0]}/>
              </Grid.Col>
              <Grid.Col flex={1}>
                <Grid.Row mb={10}>
                  <ArticleImageCard article={allArticles[1]}/>
                </Grid.Row>
                <Grid.Row>
                  <ArticleImageCard article={allArticles[2]}/>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row alignItems="center" justifyContent="center">
            <Button large label="Rodyti daugiau" onClick={() => history.push('/kelioniu-blogas')}/>
          </Grid.Row>
        </SectionLayout>
      </Grid.Container>
    </Container>
  );
}

export default Blog;
