import {Container} from "./styles";
import React from "react";
import PropTypes from "prop-types";
import {Grid, Typography} from "styles";
import {SharedTypes} from "utils";
import {RoomSelect} from "components";

function Description(props) {
  const {rooms, value, onSelect} = props;

  return (
    <Container>
      <Grid.Row mb={5}>
        <Typography.PageTitle>Kambario tipas</Typography.PageTitle>
      </Grid.Row>
      <Grid.Row mb={30}>
        <Typography.SecondaryDescription>
          Dėmesio! Specialūs pasiūlymai išgraibstomi akimirksniu, todėl prieš užsisakant būtinai patikrinkite
          visas kainas! Kaina asmeniui galioja keliaujant dviems suaugusiems.
        </Typography.SecondaryDescription>
      </Grid.Row>
      <Grid.Row>
        <RoomSelect
          value={value}
          onChange={(id) => onSelect(id)}
          rooms={rooms}
        />
      </Grid.Row>
    </Container>
  );
}

Description.propTypes = {
  value: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  rooms: PropTypes.arrayOf(SharedTypes.OfferRoomType).isRequired,
};

Description.defaultProps = {
  value: null,
};

export default Description;
