import React from "react";
import PropTypes from 'prop-types';
import {Container, Tab, Title, Icon} from './styles';

function Tabs(props) {
  const {style, tabs, active, onSelect, disabled, blueTop} = props;

  return (
    <Container style={style}>
      {tabs.map(tab => (
        <Tab blueTop={blueTop} disabled={disabled} onClick={() => onSelect(tab.key)} active={tab.key === active} key={tab.key}>
          {tab.icon && <Icon active={tab.key === active} src={tab.icon}/>}
          <Title active={tab.key === active}>{tab.title}</Title>
        </Tab>
      ))}
    </Container>
  )
}

const TabType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
});

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(TabType).isRequired,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  blueTop: PropTypes.bool,
  active: PropTypes.string.isRequired,
  style: PropTypes.object,
};

Tabs.defaultProps = {
  style: {},
  onSelect: () => {},
  disabled: false,
  blueTop: false,
};

export default Tabs;
