import styled from 'styled-components/macro';

export const Container = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Active = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: #17b25b;
`;
