import React, {useEffect, useState} from 'react';
import {Container, Content, Logo, LogoLink} from "./styles";
import {Grid, Images} from "styles";
import {Tabs} from "components";
import RightSideLayout from "../RightSideLayout";
import PropTypes from "prop-types";
import Side from './Side';
import {useCheckoutOffer} from "hooks";
import {useParams, useLocation} from 'react-router-dom';
import {NavigationUtils} from "utils";

const TABS = [
  {key: 'services', title: '1. Paslaugos'},
  {key: 'passengers', title: '2. Keleiviai'},
  {key: 'payment', title: '3. Apmokėjimas'},
];

function CheckoutLayout(props) {
  const {children} = props;
  const [activeTab, setActiveTab] = useState('services');
  const {id, roomId} = useParams();
  const location = useLocation();
  const {pathname} = location;
  const {isLoaded} = useCheckoutOffer({id, roomId});

  useEffect(() => {
    setActiveTab(NavigationUtils.getCheckoutTab(pathname));
  }, [location]);

  return (
    <Container>
      {isLoaded && (
        <Grid.Container>
          <Grid.Row mb={30}>
            <LogoLink to="/">
              <Logo src={Images.LOGO}/>
            </LogoLink>
          </Grid.Row>
          <RightSideLayout
            bottomOffset={25}
            renderSide={() => <Side/>}
            gap={30}
            sideWidth={350}
            showNewsletter={false}
          >
            <Tabs
              disabled
              tabs={TABS}
              active={activeTab}
            />
            <Content>
              {children}
            </Content>
          </RightSideLayout>
        </Grid.Container>
      )}
    </Container>
  );
}

CheckoutLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CheckoutLayout;
