import React from 'react';
import PropTypes from "prop-types";
import {Container, Title, Email, Description} from './styles';
import {SharedTypes} from "utils";
import {Grid} from 'styles';
import Avatar from "../Avatar";

function Employee(props) {
  const {style, employee} = props;

  return (
    <Container style={style}>
      <Grid.Row justifyContent="center" mb={15}>
        <Avatar size={120} source={employee.image}/>
      </Grid.Row>
      <Grid.Row justifyContent="center" mb={5}>
        <Title>{employee.fullName}</Title>
      </Grid.Row>
      <Grid.Row justifyContent="center" mb={10}>
        <Email href={`mailto:${employee.email}`}>{employee.email}</Email>
      </Grid.Row>
      <Grid.Row justifyContent="center">
        <Description text={employee.description} maxLine={2}/>
      </Grid.Row>
    </Container>
  );
}

Employee.propTypes = {
  style: PropTypes.object,
  employee: SharedTypes.EmployeeType.isRequired,
};

Employee.defaultProps = {
  style: {},
};

export default Employee;
