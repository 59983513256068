import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 70px;
`;

export const Content = styled.div`
  width: 100%;
`;
