import React, {useState} from 'react';
import {Container, Description, Image, Content, ImageContainer} from "./styles";
import {Grid, Images, Typography} from 'styles';
import {Tabs} from "components";
import {PARTNERS, PartnersCategories} from "constant";

const TABS = [
  {key: PartnersCategories.BRAND, title: 'Mūsų prekės ženklai', icon: Images.LABEL, activeIcon: Images.LABEL},
  {key: PartnersCategories.PARTNER, title: 'Partneriai', icon: Images.HANDSHAKE, activeIcon: Images.HANDSHAKE},
  {key: PartnersCategories.TROPHY, title: 'Apdovanojimai', icon: Images.BADGE, activeIcon: Images.BADGE},
];

function Partners() {
  const [activeTab, setTabActive] = useState(PartnersCategories.PARTNER);

  return (
    <Container>
      <Grid.Container>
        <Grid.Row mb={10}>
          <Typography.PageTitle center>Patirtis ir partnerystės</Typography.PageTitle>
        </Grid.Row>
        <Grid.Row justifyContent="center" mb={70}>
          <Description>
            Nuo 1991 m. kelionių organizatorius Baltic Tours puoselėja ryšius su patikimais partneriais
            visame pasaulyje. Mūsų kišenėje – žinojimas, kur ieškoti teisingų atsakymų.
          </Description>
        </Grid.Row>
        <Tabs tabs={TABS}  onSelect={(tab) => setTabActive(tab)} active={activeTab}/>
        <Content>
          {PARTNERS.filter(partner => partner.category === activeTab).map(partner => (
            <ImageContainer key={partner.id}>
              <Image src={partner.icon}/>
            </ImageContainer>
          ))}
        </Content>
      </Grid.Container>
    </Container>
  );
}

export default Partners;
