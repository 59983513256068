import React from "react";
import useFilter from "../useFilter";

function withFilter(Component) {
  return function WrappedFilterComponent(props) {
    const filter = useFilter();
    return <Component {...props} filter={filter} />;
  }
}

export default withFilter;
