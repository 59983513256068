import {ArticleTypes} from "types";
import {ArticlesService} from "services";

export function getArticle(slug) {
  return async dispatch => {
    try {
      dispatch({type: ArticleTypes.GET_ARTICLE_START});
      const response = await ArticlesService.getArticle(slug);
      dispatch({type: ArticleTypes.GET_ARTICLE, payload: {article: response}});
    } catch (exception) {
      dispatch({type: ArticleTypes.GET_ARTICLE_ERROR});
    }
  };
}
