import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {Container, CategoryContainer} from "./styles";
import {Badge} from "components";
import {NumberUtils, SharedTypes} from "utils";
import {connect} from "react-redux";
import {ArticlesActions} from "actions";
import _ from "lodash";
import {Grid} from 'styles';
import {BLOG_CATEGORIES} from "constant";

function BlogCategories(props) {
  const {categories, selectedCategories, setSelectedCategories, isLoading, isLoaded} = props;

  useEffect(() => {
    if (isLoaded) {
      const allCategories = categories.map(category => category.name);
      setSelectedCategories({selectedCategories: allCategories});
    }
  }, [isLoaded]);

  const handleClick = (category) => {
    if (category.name !== BLOG_CATEGORIES.ALL_CATEGORIES) {
      handleClickOne(category.name);
    } else {
      handleClickAll();
    }
  };

  const handleClickOne = (name) => {
    let newSelectedCategories = _.xor(selectedCategories, [name]);

    if (newSelectedCategories.length !== categories.length) {
      newSelectedCategories = newSelectedCategories.filter(name => name !== BLOG_CATEGORIES.ALL_CATEGORIES);
    }

    setSelectedCategories({selectedCategories: newSelectedCategories});
  };

  const handleClickAll = () => {
    if (selectedCategories.includes(BLOG_CATEGORIES.ALL_CATEGORIES)) {
      setSelectedCategories({selectedCategories: []});
    } else {
      const allCategories = categories.map(category => category.name);
      setSelectedCategories({selectedCategories: allCategories});
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return _.range(8).map(index => (
        <CategoryContainer key={index}>
          <Grid.Skeleton
            borderRadius={15}
            width={NumberUtils.random(120, 180)}
            height={30}
          />
        </CategoryContainer>
      ));
    }

    return categories.map(category => (
      <CategoryContainer key={category.name}>
        <Badge
          onClick={() => handleClick(category)}
          selected={selectedCategories.includes(category.name)}
          title={category.name}
        />
      </CategoryContainer>
    ));
  };

  return (
    <Container>
      {renderContent()}
    </Container>
  );
}

BlogCategories.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(SharedTypes.BlogCategoryType).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedCategories: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {articles} = state;
  const {isLoading, categories, selectedCategories, isLoaded} = articles;
  return {isLoading, categories, selectedCategories, isLoaded};
}

const mapDispatchToProps = {
  setSelectedCategories: ArticlesActions.setSelectedCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogCategories);
