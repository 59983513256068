import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div``;

export const Title = styled.h2`
  color: ${Colors.TITLE};
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
`;

export const Description = styled.p`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 15px;
  line-height: 26px;
  text-align: justify;
`;
