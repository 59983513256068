import React, {useEffect} from 'react';
import {Container, Title, Description} from "./styles";
import {Grid} from "styles";
import {MessagesActions} from "actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useParams} from 'react-router-dom';
import {MessagesService} from "services";

function ProfileMessagePage(props) {
  const {getMessages} = props;
  const {id} = useParams();
  const selectedMessage = MessagesService.getMessageById(Number(id));

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <Container>
      <Grid.Row mb={35}>
        <Title>{selectedMessage.title}</Title>
      </Grid.Row>
      <Grid.Row mb={20}>
        <Description>{selectedMessage.description}</Description>
      </Grid.Row>
    </Container>
  );
}

ProfileMessagePage.propTypes = {
  getMessages: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {messages} = state;
  const {isLoading} = messages;
  return {isLoading};
};

const mapDispatchToProps = {
  getMessages: MessagesActions.getMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMessagePage);
