import React from "react";
import {TVS_URL} from "../../config";
import {Images} from "styles";

export const getHighQualityImage = (image) => {
  if (!image) {
    return Images.IMAGE_PLACEHOLDER;
  }

  if (Array.isArray(image) && image.length > 0) {
    return image[0].replace('_small','');
  }

  return image.replace('_small','');
};

export const getTvsImage = (source) => {
  return `${TVS_URL}/${source}`;
};

export const transformTvsImage = (node) => {
  if (node.type === 'tag' && node.name === 'img') {
    return (
      <img
        alt={node.attribs.alt}
        key={node.attribs.src}
        src={getTvsImage(node.attribs.src)}
      />
    );
  }
};

export const getOfferFirstImage = (images) => {
  return images && images.length > 0 ? images[0] : Images.IMAGE_PLACEHOLDER;
};

export const getOfferImages = (images) => {
  return images && images.length > 0 ? images : [Images.IMAGE_PLACEHOLDER];
};
