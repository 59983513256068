import React from "react";
import {Container, Description, Icon, Title, Item} from './styles';
import {Images, Grid} from "styles";
import {Swiper, SwiperSlide} from "swiper/react";

function Statistics() {

  const renderItem = (icon, text, description) => (
    <SwiperSlide>
      <Item>
        <Grid.Row fullWidth justifyContent="center" mb={35}>
          <Icon src={icon}/>
        </Grid.Row>
        <Grid.Row fullWidth justifyContent="center" mb={10}>
          <Title>{text}</Title>
        </Grid.Row>
        <Grid.Row fullWidth justifyContent="center">
          <Description>{description}</Description>
        </Grid.Row>
      </Item>
    </SwiperSlide>
  );

  return (
    <Container>
      <Grid.Container>
        <Swiper
          className="about-us-container"
          centeredSlidesBounds
          centeredSlides
          slidesPerView={'auto'}
        >
          {renderItem(Images.DIRECTION, '64 kryptimis', 'Išlydėjome keliautojus 2018 metais')}
          {renderItem(Images.FLIGHT, '255 tūkst.', 'Klientų išlydime į keliones kasmet')}
          {renderItem(Images.PEOPLE, '28 metus', 'Kuriame kelionių melodijas')}
        </Swiper>
      </Grid.Container>
    </Container>
  );
}

export default Statistics;
