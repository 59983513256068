import {ArticlesTypes} from "types";
import {ArticlesService} from "services";

export function getArticles() {
  return async dispatch => {
    try {
      dispatch({type: ArticlesTypes.GET_ARTICLES_START});
      const {categories, posts} = await ArticlesService.getArticles();
      dispatch({type: ArticlesTypes.GET_ARTICLES, payload: {articles: posts, categories}});
    } catch (exception) {
      dispatch({type: ArticlesTypes.GET_ARTICLES_ERROR});
    }
  };
}

export function setSelectedCategories(request) {
  return {
    type: ArticlesTypes.SET_SELECTED_CATEGORIES,
    payload: request,
  }
}
