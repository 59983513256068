import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-left: 8px;
`;

export const GroupTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2.25px;
  color: ${Colors.HELP};
  text-transform: uppercase;
`;
