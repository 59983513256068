import React from 'react';
import { Grid } from 'styles';
import { SectionTitle, Container, CategoryLink, CategoryRow } from './styles';

function Categories() {
  return (
    <Container>
      <Grid.Row>
        <Grid.Col mr={50} flex={1}>
          <SectionTitle>Populiariausi miestai</SectionTitle>
          <CategoryRow fullWidth className="no-break" justifyContent="space-between">
            <Grid.StaticCol className="no-break">
              <CategoryLink to="/">Pigios kelionės į Hurgadą</CategoryLink>
              <CategoryLink to="/">Pigios kelionės į Barseloną</CategoryLink>
              <CategoryLink to="/">Pigios kelionės į Ibizą</CategoryLink>
            </Grid.StaticCol>
            <Grid.StaticCol>
              <CategoryLink to="/">Kelionės į Dubajų</CategoryLink>
              <CategoryLink to="/">Kelionės į Maroką</CategoryLink>
              <CategoryLink to="/">Kelionės į Veneciją</CategoryLink>
            </Grid.StaticCol>
          </CategoryRow>
        </Grid.Col>
        <Grid.Col mr={50} flex={1}>
          <SectionTitle>Populiariausi miestai</SectionTitle>
          <CategoryRow fullWidth className="no-break" justifyContent="space-between">
            <Grid.StaticCol className="no-break">
              <CategoryLink to="/">Pigios kelionės į Hurgadą</CategoryLink>
              <CategoryLink to="/">Pigios kelionės į Barseloną</CategoryLink>
              <CategoryLink to="/">Pigios kelionės į Ibizą</CategoryLink>
            </Grid.StaticCol>
            <Grid.StaticCol>
              <CategoryLink to="/">Kelionės į Dubajų</CategoryLink>
              <CategoryLink to="/">Kelionės į Maroką</CategoryLink>
              <CategoryLink to="/">Kelionės į Veneciją</CategoryLink>
            </Grid.StaticCol>
          </CategoryRow>
        </Grid.Col>
        <Grid.Col flex={1}>
          <SectionTitle>Populiariausi miestai</SectionTitle>
          <CategoryRow fullWidth className="no-break" justifyContent="space-between">
            <Grid.Col className="no-break">
              <CategoryLink to="/">Pigios kelionės į Hurgadą</CategoryLink>
              <CategoryLink to="/">Pigios kelionės į Barseloną</CategoryLink>
              <CategoryLink to="/">Pigios kelionės į Ibizą</CategoryLink>
            </Grid.Col>
            <Grid.Col>
              <CategoryLink to="/">Kelionės į Dubajų</CategoryLink>
              <CategoryLink to="/">Kelionės į Maroką</CategoryLink>
              <CategoryLink to="/">Kelionės į Veneciją</CategoryLink>
            </Grid.Col>
          </CategoryRow>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
}

export default Categories;
