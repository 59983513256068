import React from 'react';
import {Container, Content} from "./styles";
import {Banner, DestinationsSlider} from "components";
import {useDestination, useFilter} from "hooks";
import {OffersLayout} from "layouts";

function DestinationPage() {
  const filter = useFilter();
  const {destination, isLoaded} = useDestination(filter.destinationId);

  return (
    <Container>
      <Banner
        backgroundImage={destination ? destination.image : undefined}
        title={destination ? destination.title : isLoaded ? 'Pasiūlymai' : 'Ieškoma kryptis...'}
      />
      <DestinationsSlider/>
      <Content>
        <OffersLayout filter={filter}/>
      </Content>
    </Container>
  );
}

export default DestinationPage;
