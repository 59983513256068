import React from 'react';
import PropTypes from "prop-types";
import {Buttons} from "styles";
import {Container, Title, Description} from './styles';
import Button from "../Button";

function InformationBox(props) {
  const {style, title, onClick, description, buttonLabel} = props;

  return (
    <Container onClick={onClick} style={style}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button onClick={onClick} label={buttonLabel} theme={Buttons.WHITE}/>
    </Container>
  );
}

InformationBox.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

InformationBox.defaultProps = {
  style: {},
};

export default InformationBox;
