import React from "react";
import PropTypes from 'prop-types';
import {Container, Label} from './styles';

function MultiSelectContainer(props) {
  const {style, label, values} = props;

  return (
    <Container active={values.length > 0} style={style}>
      <Label active={values.length > 0}>{values.length > 0 ? `${label} (${values.length})` : label}</Label>
    </Container>
  )
}

MultiSelectContainer.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  style: PropTypes.object,
  label: PropTypes.string,
};

MultiSelectContainer.defaultProps = {
  style: {},
  values: [],
  label: null,
};

export default MultiSelectContainer;
