import PropTypes from "prop-types";
import {Select} from "components";
import React from "react";
import {GroupContainer, GroupTitle} from './styles';
import {useDestinations} from "hooks";

function DestinationSelect(props) {
  const {style, large, label, value, dirty, error, onChange, name, placeholder, onBlur, menuIsOpen} = props;
  let {allDestinations, isLoading} = useDestinations();

  const groups = [{
    label: 'Populiariausios šalys',
    options: allDestinations.map(destination => {
      return {value: destination.id, label: destination.title}
    })
  }];

  const renderGroupLabel = data => (
    <GroupContainer>
      <GroupTitle>{data.label}</GroupTitle>
    </GroupContainer>
  );

  return (
    <Select
      menuIsOpen={menuIsOpen}
      onBlur={onBlur}
      searchable
      renderGroupLabel={renderGroupLabel}
      loading={isLoading}
      style={style}
      large={large}
      label={label}
      options={groups}
      value={value}
      dirty={dirty}
      error={error}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
    />
  );
}

DestinationSelect.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  dirty: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
};

DestinationSelect.defaultProps = {
  style: {},
  disabled: false,
  value: null,
  large: false,
  label: null,
  dirty: false,
  error: null,
  placeholder: null,
  onBlur: () => {},
};

export default DestinationSelect;
