import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const Title = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Name = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
`;

export const Distance = styled.span`
  color: ${Colors.HELP};
  font-size: 14px;
`;

export const Item = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ItemSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Section = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export const Icon = styled.img`
  margin-right: 15px;
  width: 15px;
`;

export const Address = styled.span`
  color: ${Colors.TITLE};
  font-size: 15px;
  line-height: 18px;
`;
