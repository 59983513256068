import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DropdownContent = styled.div`
  padding: ${(props) => (props.forceOpen ? 0 : 20)}px;
`;
