import styled from 'styled-components/macro';

export const Container = styled.div`
  padding: 66px 0 ${(props) => props.pb}px 0;
`;

export const Header = styled.div`
  margin-bottom: 60px;
`;

export const Content = styled.div``;
