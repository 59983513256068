import React from 'react';
import {Container, Description} from "./styles";
import {Employees} from "containers";
import {Grid, Typography} from 'styles';

function Team() {
  return (
    <Container>
      <Grid.Container>
        <Grid.Row mb={10}>
          <Typography.PageTitle center>Pažinkite mus</Typography.PageTitle>
        </Grid.Row>
        <Grid.Row justifyContent="center" mb={70}>
          <Description>
            Baltic Tours ekspertų komanda dirba tam, jog tobulų atostogų vaizdinys nepasislėptų po kasdienybės
            dulkėmis, rytinė kava dažniau būtų gurkšnojama oro uoste, o gastronominiai stebuklai pilvą glostytų
            palmių pavėsyje! Ateikite pas mus – kelionių ekspertus, kiekvieną dieną su šypsena pildančius svajones.
          </Description>
        </Grid.Row>
        <Employees/>
      </Grid.Container>
    </Container>
  );
}

export default Team;
