import React from 'react';
import PropTypes from "prop-types";
import {Grid} from 'styles';
import {Pluralisation, SharedTypes} from "utils";
import Button from "../Button";
import {TRIP_STATUSES} from "constant";
import {
  Container,
  Header,
  OrderNumberLabel,
  OrderNumber,
  Content,
  Image,
  Title,
  Description,
  ItemLabel,
  ItemValue,
} from './styles';
import Status from "components/TripsListItem/Status";

function TripsListItem(props) {
  const {onClick, style, trip} = props;

  const showPayButton = (trip.status === TRIP_STATUSES.PENDING || trip.status === TRIP_STATUSES.PARTIALLY_PAID && !trip.inPast);

  return (
    <Container style={style} onClick={onClick}>
      <Header>
        <Grid.Col flex={1}>
          <OrderNumberLabel>Užsakymo numeris</OrderNumberLabel>
          <OrderNumber>{trip.orderNumber}</OrderNumber>
        </Grid.Col>
        {trip.status !== TRIP_STATUSES.PENDING && (
          <Grid.Col ml={30}>
            <Status past={trip.inPast} status={trip.status}/>
          </Grid.Col>
        )}
        {showPayButton && (
          <Grid.Col ml={30}>
            <Button small label="Apmokėti" onClick={() => {
            }}/>
          </Grid.Col>
        )}
      </Header>
      <Content>
        <Grid.Row>
          <Grid.Col mr={20}>
            <Image src={trip.image}/>
          </Grid.Col>
          <Grid.Col flex={1}>
            <Grid.Row mb={5}>
              <Title>{trip.title}</Title>
            </Grid.Row>
            <Grid.Row mb={15}>
              <Description maxLine={1} text={trip.description}/>
            </Grid.Row>
            <Grid.Row fullWidth>
              <Grid.Col flex={1}>
                <ItemLabel>Vykstate į</ItemLabel>
                <ItemValue>{trip.destination}</ItemValue>
              </Grid.Col>
              <Grid.Col flex={1}>
                <ItemLabel>Išvykimas</ItemLabel>
                <ItemValue>{trip.dateFrom}</ItemValue>
              </Grid.Col>
              <Grid.Col flex={1}>
                <ItemLabel>Grįžimas</ItemLabel>
                <ItemValue>{trip.dateTo}</ItemValue>
              </Grid.Col>
              <Grid.Col flex={1}>
                <ItemLabel>Trukmė</ItemLabel>
                <ItemValue>{Pluralisation.nightsText(trip.nightsCount)}</ItemValue>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Content>
    </Container>
  );
}

TripsListItem.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  trip: SharedTypes.TripListItemType.isRequired,
};

TripsListItem.defaultProps = {
  style: {},
};

export default TripsListItem;
