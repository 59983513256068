import {NumberUtils} from "utils";

export function setQueryParam(name, value) {
  const params = new URLSearchParams(window.location.search);
  params.set(name, value);
  return params.toString();
}

export function setQueryArrayParam(name, values) {
  const params = new URLSearchParams(window.location.search);
  params.delete(name);

  for (let value of values) {
    params.append(name, value);
  }

  return params.toString();
}

export function setQueryParams(newParams) {
  const params = new URLSearchParams(window.location.search);

  for (let key of Object.keys(newParams)) {
    if (newParams[key] !== undefined && newParams[key] !== null) {
      params.set(key, newParams[key]);
    }
  }

  return params.toString();
}

export function resetQueryParams(keys) {
  const params = new URLSearchParams(window.location.search);

  for (let key of keys) {
    params.delete(key);
  }

  return params.toString();
}

export function formatQueryString(location) {
  return new URLSearchParams(location).toString();
}

export function queryParamsToObject(queryParams) {
  const urlParams = new URLSearchParams(queryParams);
  const object = {};

  for (const key of urlParams.keys()) {
    if (urlParams.getAll(key).length > 1) {
      object[key] = NumberUtils.tryParseArrayToNumbers(urlParams.getAll(key));
    } else {
      object[key] = NumberUtils.tryParseToNumber(urlParams.get(key));
    }
  }

  return object;
}
