import styled from 'styled-components/macro';
import { Grid } from 'styles';

export const Container = styled.div`
  padding-bottom: 70px;
`;

export const Content = styled(Grid.Container)`
  margin-top: 40px;
`;
