import React from "react";
import autoBind from "auto-bind";
import {Colors, Grid} from 'styles';
import Form from "../Form";
import {DatePicker, PassengersCountSelect, Select, DestinationSelect, Modal} from "components";
import {DURATIONS} from "constant";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {withFilter} from "hooks";
import {Overlay, Title} from "../HomeSearchForm/styles";
import {BlueTop} from "../../components/DatePicker/styles";

class SearchFormMobile extends Form {
  constructor(props) {
    super(props);

    this.state = {
      destinationOpen: false,
      dateOpen: false,
      durationOpen: false,
      passengersOpen: false,
    };

    autoBind(this);
  }


  render() {
    const {destinationOpen, dateOpen, durationOpen, passengersOpen} = this.state;
    const {fields, handleChange, handleBlur} = this.props;

    const searchModalStyle = {
      top: 0,
      height: '100vh',
      borderRadius: 0,
      maxWidth: '100vw',
      width: '100vw',
      overflowY: 'auto',
      zIndex: 9,
      padding: '50px 15px'
    };

    return (
        <Grid.Mobile>
          <Grid.Row mb={17} fullWidth onClick={() => this.setState({destinationOpen:true})} style={{position:'relative'}}>
            <Overlay/>
            <DestinationSelect
                label="Kryptis"
                value={fields.destinationId.value}
                dirty={fields.destinationId.dirty}
                error={fields.destinationId.error}
                onBlur={handleBlur}
                onChange={handleChange}
                name="destinationId"
                placeholder="Rinkitės šalį/kurortą"
            />
          </Grid.Row>
          <Modal className='searchMobile'
                 style={searchModalStyle}
                 large
                 visible={destinationOpen}
                 onClose={() => this.setState({destinationOpen:false})}>
            <Title>Kur planuojate keliauti?</Title>
            <DestinationSelect
                menuIsOpen
                value={fields.destinationId.value}
                dirty={fields.destinationId.dirty}
                error={fields.destinationId.error}
                onBlur={handleBlur}
                onChange={(e)=>{handleChange(e);this.setState({destinationOpen:false})}}
                name="destinationId"
                placeholder="Rinkitės šalį/kurortą"
            />
          </Modal>

          <Grid.Row mb={17} fullWidth onClick={() => this.setState({dateOpen:true})} style={{position:'relative'}}>
            <Overlay/>
            <DatePicker
                large
                label="Data"
                destinationId={fields.destinationId.value}
                departureId={1}
                value={fields.date.value}
                dirty={fields.date.dirty}
                error={fields.date.error}
                onBlur={handleBlur}
                onChange={handleChange}
                name="date"
                placeholder="Rinkitės datą"
            />
          </Grid.Row>
          <Modal className='searchMobile'
                 closeWhite
                 style={{...searchModalStyle, paddingTop:0}}
                 large
                 visible={dateOpen}
                 onClose={() => this.setState({dateOpen:false})}>
            <BlueTop>
              <Title color={Colors.WHITE}>Kada planuojate keliauti?</Title>
            </BlueTop>
            <DatePicker
                style={{width:'calc(100% + 30px)',margin:'-40px -15px 0'}}
                monthsShown={1}
                forceOpen
                large
                transparent
                blueTop
                destinationId={fields.destinationId.value}
                departureId={1}
                value={fields.date.value}
                dirty={fields.date.dirty}
                error={fields.date.error}
                onBlur={handleBlur}
                onChange={(e)=>{handleChange(e);this.setState({dateOpen:false})}}
                name="date"
                placeholder="Rinkitės datą"
            />
          </Modal>

          <Grid.Row className='no-break'>
            <Grid.Col flex={2} style={{marginRight:7,position:'relative'}} onClick={() => this.setState({durationOpen:true})} >
              <Overlay/>
              <Select
                  large
                  label="Trukmė"
                  options={DURATIONS}
                  value={fields.duration.value}
                  dirty={fields.duration.dirty}
                  error={fields.duration.error}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="duration"
                  placeholder="Rinkitės trukmę"
              />
            </Grid.Col>
            <Modal className='searchMobile'
                   style={{...searchModalStyle, height:'auto'}}
                   large
                   visible={durationOpen}
                   onClose={() => this.setState({durationOpen:false})}>
              <Title>Kelionės trukmė</Title>
              <Select
                  menuIsOpen
                  large
                  options={DURATIONS}
                  value={fields.duration.value}
                  dirty={fields.duration.dirty}
                  error={fields.duration.error}
                  onChange={(e)=>{handleChange(e);this.setState({durationOpen:false})}}
                  onBlur={handleBlur}
                  name="duration"
                  placeholder="Rinkitės trukmę"
              />
            </Modal>

            <Grid.Col flex={2} style={{marginLeft:7,position:'relative'}} onClick={() => this.setState({passengersOpen:true})} >
              <Overlay/>
              <PassengersCountSelect
                  large
                  label="Asm. sk."
                  value={fields.passengers.value}
                  error={fields.passengers.error}
                  dirty={fields.passengers.dirty}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="passengers"
                  placeholder="Keleiviai"
              />
            </Grid.Col>
            <Modal className='searchMobile'
                   style={{...searchModalStyle, height:'auto'}}
                   large
                   visible={passengersOpen}
                   onClose={() => this.setState({passengersOpen:false})}>
              <Title>Kiek keleivių?</Title>
              <PassengersCountSelect
                  withButton
                  forceOpen
                  large
                  value={fields.passengers.value}
                  error={fields.passengers.error}
                  dirty={fields.passengers.dirty}
                  onBlur={handleBlur}
                  onChange={(e)=>{handleChange(e);this.setState({passengersOpen:false})}}
                  name="passengers"
                  placeholder="Keleiviai"
              />
            </Modal>
          </Grid.Row>
        </Grid.Mobile>
    );
  }
}

SearchFormMobile.propTypes = {
  history: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
};

export default withFilter(withRouter(SearchFormMobile));
