import React from 'react';
import {Container, Title, Link, Content, Icon} from "./styles";
import {Files, Grid, Images} from 'styles';

function Report() {
  return (
    <Container>
      <Grid.Container>
        <Content>
          <Grid.Row mb={20}>
            <Title>Susipažinkite su išsamia Baltic Tours veiklos ataskaita</Title>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col mr={10}>
              <Icon src={Images.FILE}/>
            </Grid.Col>
            <Grid.Col>
              <Link href={Files.REPORT} target="_blank" download>Baltic Tours 2018 metų veiklos ataskaita</Link>
            </Grid.Col>
          </Grid.Row>
        </Content>
      </Grid.Container>
    </Container>
  );
}

export default Report;
