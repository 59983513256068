import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Container, DropdownContent} from "./styles";
import _ from "lodash";

import Dropdown from "../Dropdown";
import {Form, Grid} from "styles";
import PassengerCount from "../PassengerCount";
import {Pluralisation} from "utils";

function PassengersCountSelect(props) {
  const {label, style, value, onChange, name, dirty, error, placeholder, large, forceOpen, withButton} = props;
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleOnChange = (count, property) => {
    setLocalValue({...localValue, [property]: count});
  };

  const handleOnBlur = () => {
    if (!_.isEqual(value, localValue) || forceOpen) {
      onChange({name, value: localValue});
    }
  };

  const renderContent = () => {
    const passengersCount = localValue.adultsCount + localValue.childrenCount + localValue.infantsCount;
    if (passengersCount > 0) {
      return <Form.Value large={large}>{`${Pluralisation.passengersText(passengersCount)}`}</Form.Value>
    }

    if (placeholder) {
      return <Form.Placeholder large={large}>{placeholder}</Form.Placeholder>
    }
  };

  const renderButton = () => (
    <Form.Container large={large} error={dirty && error} style={style}>
      <Form.ValueContainer>
        {renderContent()}
      </Form.ValueContainer>
    </Form.Container>
  );

  const renderDropdown = () => (
    <DropdownContent forceOpen={forceOpen}>
      <Grid.Row mb={35}>
        <PassengerCount
          count={localValue.adultsCount}
          description="Virš 11"
          onChange={(value) => handleOnChange(value, 'adultsCount')}
          title="Suaugę"
        />
      </Grid.Row>
      <Grid.Row mb={35}>
        <PassengerCount
          count={localValue.childrenCount}
          description="2-11"
          onChange={(value) => handleOnChange(value, 'childrenCount')}
          title="Vaikai"
        />
      </Grid.Row>
      <Grid.Row>
        <PassengerCount
          count={localValue.infantsCount}
          description="0-2"
          onChange={(value) => handleOnChange(value, 'infantsCount')}
          title="Kūdikiai"
        />
      </Grid.Row>
    </DropdownContent>
  );

  return (
    <Container style={style}>
      {label && <Form.Label>{label}</Form.Label>}
      <Grid.Desktop>
        <Dropdown
            onClose={handleOnBlur}
            fullWidth={false}
            renderButton={renderButton}
            renderDropdown={renderDropdown}
        />
      </Grid.Desktop>
      <Grid.Mobile>
        <Dropdown
            withButton={withButton}
            transparent
            forceOpen={forceOpen}
            onClose={handleOnBlur}
            fullWidth
            renderButton={renderButton}
            renderDropdown={renderDropdown}
        />
      </Grid.Mobile>
      {dirty && error && <Form.Error>{error}</Form.Error>}
    </Container>
  )
}

const ValueType = PropTypes.shape({
  adultsCount: PropTypes.number.isRequired,
  childrenCount: PropTypes.number.isRequired,
  infantsCount: PropTypes.number.isRequired,
});

PassengersCountSelect.propTypes = {
  value: ValueType,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  error: PropTypes.string,
  label: PropTypes.string,
  dirty: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  forceOpen: PropTypes.bool,
  withButton: PropTypes.bool,
};

PassengersCountSelect.defaultProps = {
  style: {},
  disabled: false,
  value: {adultsCount: 0, childrenCount: 0, infantsCount: 0},
  label: null,
  dirty: false,
  error: null,
  large: false,
  forceOpen: false,
  withButton: false,
  placeholder: null,
};

export default PassengersCountSelect;
