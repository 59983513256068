import styled from 'styled-components/macro';
import { Typography } from 'styles';

const Container = styled.div`
  border-radius: 8px;
  background-color: #2394e2;
  padding: 50px 20px;
`;

const Title = styled(Typography.PageTitle)`
  color: #ffffff;
  margin-bottom: 25px;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.57;
  color: #ffffff;
  margin-bottom: 30px;
`;

export { Container, Title, Description };
