import React from 'react';
import {Container} from "./styles";
import {Banner} from "components";
import {Grid} from "styles";
import {CountriesLayout} from "layouts";

function CountriesPage() {
  return (
    <Container>
      <Grid.Row mb={50}>
        <Banner title="Visos šalys"/>
      </Grid.Row>
      <Grid.Container>
        <CountriesLayout/>
      </Grid.Container>
    </Container>
  );
}

export default CountriesPage;
