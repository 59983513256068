import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OfferActions } from 'actions';
import { connect } from 'react-redux';
import { ImageUtils, SharedTypes } from 'utils';
import { useParams } from 'react-router-dom';
import { Banner } from 'components';
import { Grid } from 'styles';
import { Container, Content } from './styles';
import { RightSideLayout } from 'layouts';
import { Element } from 'react-scroll';
import { useMediaQuery } from 'hooks';

import Details from './Details';
import Description from './Description';
import Rooms from './Rooms';
import Header from './Header';
import Side from './Side';
import Location from './Location';
import Footer from './Footer';
import Travels from './Travels';

function OfferPage(props) {
  const { getOffer, offer, isLoaded } = props;
  const [roomId, setRoomId] = useState();
  const { id } = useParams();
  const isMobile = useMediaQuery('(max-width: 400px)');

  useEffect(() => {
    getOffer(id);
  }, [id]);

  const renderSide = () => (
    <Side inclusive={offer.inclusive} roomId={roomId} advance={offer.advance} price={offer.price} />
  );

  const renderContent = () => (
    <React.Fragment>
      <Header />
      <Grid.Container>
        <RightSideLayout
          bottomOffset={100}
          topOffset={70}
          sideWidth={isMobile ? 300 : 350}
          showNewsletter={false}
          renderSide={renderSide}
        >
          <Content>
            <Element name="details">
              <Details rating={offer.hotelClass} title={offer.title} summary={offer.summary} images={offer.images} />
            </Element>
            {/*<Element name="perks">*/}
            {/*  <Perks perks={offer.perks}/>*/}
            {/*</Element>*/}
            <Element name="rooms">
              <Rooms value={roomId} rooms={offer.hotelRooms} onSelect={(id) => setRoomId(id)} />
            </Element>
            <Element name="travels">
              <Travels travels={offer.travels} />
            </Element>
            <Element name="location">
              <Location location={offer.location} />
            </Element>
            <Element name="description">
              <Description description={offer.description} />
            </Element>
          </Content>
        </RightSideLayout>
      </Grid.Container>
      <Footer roomId={roomId} price={offer.price} rating={offer.hotelClass} title={offer.title} />
    </React.Fragment>
  );

  return (
    <Container>
      <Banner
        backgroundImage={isLoaded ? ImageUtils.getOfferFirstImage(offer.images) : undefined}
        title={isLoaded ? offer.title : 'Ieškomas pasiūlymas...'}
      />
      {isLoaded && renderContent()}
    </Container>
  );
}

OfferPage.propTypes = {
  getOffer: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  offer: SharedTypes.OfferType,
};

OfferPage.defaultProps = {
  offer: null,
};

const mapStateToProps = (state) => {
  const { offer } = state;
  const { isLoaded } = offer;
  return { isLoaded, offer: offer.offer };
};

const mapDispatchToProps = {
  getOffer: OfferActions.getOffer,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferPage);
