import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  background-color: ${Colors.WHITE};
  cursor: pointer;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${Colors.SECTION_TITLE};
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 1.57;
  color: ${Colors.SECTION_DESCRIPTION};
`;

export const Icon = styled.img`
  max-width: 52px;
  max-height: 55px;
`;

export const Price = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${Colors.DROPDOWN_ITEM};
`;
