import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Colors } from 'styles';

export const MobileMenuContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  top: 0;
  left: auto;
  right: ${(props) => (props.menuOpen ? 0 : '-100%')};
  transition: 0.3s;
  background: ${Colors.PRIMARY};
  padding: 15px 0;
`;

export const MobileButton = styled.div`
  display: none;

  @media only screen and (max-width: 1110px) {
    display: inline-block;
    cursor: pointer;
    height: 20px;
    z-index: 9999;
    margin-right: ${(props) => (props.menuOpen ? -3 : 0)}px;
  }
`;

export const Bar = styled.div`
  width: 24px;
  height: 4px;
  background-color: ${(props) => (props.transparent || props.menuOpen ? Colors.WHITE : Colors.PRIMARY)};
  margin-bottom: 4px;
  transition: 0.3s;

  &:nth-child(2) {
    width: 30px;
    display: ${(props) => (props.menuOpen ? 'none' : 'block')};
  }

  &:first-child {
    transform: ${(props) => (props.menuOpen ? 'translate(-1px)rotate(45deg)' : 'none')};
    margin: ${(props) => (props.menuOpen ? '6px 0' : '0 0 3px')};
  }

  &:last-child {
    transform: ${(props) => (props.menuOpen ? 'translate(-1px)rotate(-45deg)' : 'none')};
    margin: ${(props) => (props.menuOpen ? '-10px 0' : '0 0 3px')};
  }
`;

export const MenuItem = styled(NavLink)`
  color: ${Colors.WHITE};
  padding: 15px 12.5px;
  font-weight: 700;
  font-size: 14px;
  display: block;
  margin-left: -12.5px;
  text-decoration: none;
`;

export const List = styled.div`
  border-top: ${(props) => (props.borderless ? 'none' : '1px solid rgba(220, 224, 229, .3)')};
  padding: 5px ${(props) => (props.noPx ? 0 : 15)}px;
  width: 100%;

  * {
    color: ${Colors.WHITE}!important;
  }
`;

export const ExternalLink = styled.a`
  width: auto;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
  padding: 15px 12.5px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const Social = styled.img``;
