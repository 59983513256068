import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Container} from "./styles";
import {DateUtils, SharedTypes} from "utils";
import {default as ReactDatePicker} from 'react-datepicker';

function DateSelect(props) {
  const {value, onChange, isRange, availableDates, monthsShown} = props;
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleOnChange = (value) => {
    if (isRange) {
      const [dateFrom, dateTo] = value;
      const formattedValue = {dateFrom: DateUtils.formatDate(dateFrom), dateTo: DateUtils.formatDate(dateTo)};
      setLocalValue(formattedValue);
      if (dateTo) onChange(formattedValue);
    } else {
      const formattedValue = {dateFrom: DateUtils.formatDate(value), dateTo: DateUtils.formatDate(value)};
      setLocalValue(formattedValue);
      onChange(formattedValue);
    }
  };

  const getAvailableDates = (date) => {
    return !availableDates || availableDates.includes(DateUtils.formatDate(date));
  };

  return (
    <Container>
      <ReactDatePicker
        minDate={new Date()}
        locale="lt"
        filterDate={getAvailableDates}
        selectsRange={isRange}
        selected={localValue.dateFrom ? new Date(localValue.dateFrom) : null}
        onChange={handleOnChange}
        startDate={localValue.dateFrom ? new Date(localValue.dateFrom) : null}
        endDate={isRange && localValue.dateTo ? new Date(localValue.dateTo) : null}
        monthsShown={monthsShown}
        shouldCloseOnSelect={false}
        inline
      />
    </Container>
  )
}

DateSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  availableDates: PropTypes.arrayOf(PropTypes.string),
  isRange: PropTypes.bool,
  value: SharedTypes.DateValueType,
  monthsShown: PropTypes.number,
};

DateSelect.defaultProps = {
  value: null,
  availableDates: null,
  isRange: false,
  monthsShown: 2,
};

export default DateSelect;
