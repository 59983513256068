import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  background-color: ${Colors.MENU_BACKGROUND};
  border-radius: 6px;
  padding: 50px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.FORM_BORDER};
`;

export const Title = styled.p`
  font-size: 14px;
  color: ${Colors.DROPDOWN_ITEM};
  font-weight: 700;
`;
