import {Grid, Images, Typography} from "styles";
import {
  Container,
  Content,
  Title,
  Description,
  Icon,
  Time,
  City,
  Flight,
  Header,
} from "./styles";
import React from "react";
import {SharedTypes} from "utils";

function Travels(props) {
  const {travels} = props;

  // const renderPerk = (perk) => (
  //   <Perk disabled={perk.disabled} key={perk.id}>
  //     <Grid.Col mr={15}>
  //       <Icon src={perk.icon}/>
  //     </Grid.Col>
  //     <Grid.Col>
  //       <Grid.Row mb={2}>
  //         <PerkTitle>{perk.title}</PerkTitle>
  //       </Grid.Row>
  //       <Grid.Row>
  //         <PerkDescription>{perk.description}</PerkDescription>
  //       </Grid.Row>
  //     </Grid.Col>
  //   </Perk>
  // );

  const renderFlights = (flights) => {
    return flights.map((flight, index) => (
      <Flight key={index}>
        {/*{flight.delayTime && (*/}
        {/*  <Grid.Row mb={35}>*/}
        {/*    <Grid.Col mr={10}>*/}
        {/*      <Icon src={Images.CLOCK}/>*/}
        {/*    </Grid.Col>*/}
        {/*    <Grid.Col>*/}
        {/*      <Delay>{`Persėdimas ${flight.delayTime}`}</Delay>*/}
        {/*    </Grid.Col>*/}
        {/*  </Grid.Row>*/}
        {/*)}*/}
        <Grid.Row>
          <Grid.Col mr={15}>
            <Icon src={Images.PLANE}/>
          </Grid.Col>
          <Grid.Col mr={25}>
            <Grid.Row mb={2}>
              <Grid.Col mr={5}>
                <Time>{flight.departureTime}</Time>
              </Grid.Col>
              <Grid.Col>
                <City>{flight.departureCity}</City>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col mr={5}>
                <Time>{flight.arrivalTime}</Time>
              </Grid.Col>
              <Grid.Col>
                <City>{flight.arrivalCity}</City>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          {/*<Grid.Col mr={10}>*/}
          {/*  <AirlineIcon src={flight.airlineIcon}/>*/}
          {/*</Grid.Col>*/}
          {/*<Grid.Col mr={10}>*/}
          {/*  <Grid.Row mb={2}>*/}
          {/*    <Airline>{`Oro linija: ${flight.airline}`}</Airline>*/}
          {/*  </Grid.Row>*/}
          {/*  <Grid.Row>*/}
          {/*    <Airline>{`Skrydžio nr.: ${flight.number}`}</Airline>*/}
          {/*  </Grid.Row>*/}
          {/*</Grid.Col>*/}
        </Grid.Row>
      </Flight>
    ));
  };

  return (
    <Container>
      <Grid.Row mb={5}>
        <Typography.PageTitle>Kelionės informacija</Typography.PageTitle>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Typography.SecondaryDescription>Skrydžių datos, laikai ir pervežimas iš/į oro uostą.</Typography.SecondaryDescription>
      </Grid.Row>
      <Grid.Row>
        <Content>
          <Header>
            <Grid.Row alignItems="flex-start">
              <Grid.Col flex={1}>
                <Grid.Row mb={10}>
                  <Title>Išvykimas</Title>
                </Grid.Row>
                <Grid.Row mb={20}>
                  <Description>{`${travels.departure.date} (trukmė - ${travels.departure.duration})`}</Description>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    {renderFlights(travels.departure.flights)}
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col flex={1}>
                <Grid.Row mb={10}>
                  <Title>Grįžimas</Title>
                </Grid.Row>
                <Grid.Row mb={20}>
                  <Description>{`${travels.arrival.date} (trukmė - ${travels.arrival.duration})`}</Description>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    {renderFlights(travels.arrival.flights)}
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          </Header>
          {/*<Footer>*/}
          {/*  <Grid.Row>*/}
          {/*    <GridContainer itemsCount={3} rowGap={25} columnGap={80}>*/}
          {/*      {travels.perks.map(perk => renderPerk(perk))}*/}
          {/*    </GridContainer>*/}
          {/*  </Grid.Row>*/}
          {/*</Footer>*/}
        </Content>
      </Grid.Row>
    </Container>
  );
}

Travels.propTypes = {
  travels: SharedTypes.OfferTravelsType.isRequired,
};

export default Travels;
