import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  width: calc(${(props) => 100 / props.imagesCount}% - 8px);
  background-color: ${Colors.BLACK};
  border-radius: 6px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: ${(props) => props.height}px;

  &:hover {
    cursor: pointer;

    > img {
      opacity: 0.75;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Overlap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  background-color: rgba(64, 75, 105, 0.8);
`;

export const OverlapText = styled.span`
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
`;

export const Image = styled.img`
  object-fit: cover;
  transition: 0.3s;
  width: 100%;
  height: 100%;
`;
