import styled from 'styled-components/macro';
import { Colors, Grid } from 'styles';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  padding: 35px 0;
  border-bottom: solid 1px ${Colors.MENU_SHADOW};
`;

export const CategoryRow = styled(Grid.StaticRow)`
  @media only screen and (max-width: ${(props) => (props.breakpoint ? props.breakpoint : '1110')}px) {
    max-width: 350px;
  }
`;

export const SectionTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: ${Colors.DROPDOWN_ITEM};
  margin-bottom: 15px;
  white-space: nowrap;
  width: 100%;
  text-align: center;
`;

export const CategoryLink = styled(Link)`
  font-size: 13px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 1.54;
  color: ${Colors.HELP};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
