import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.form`
  width: 100%;
  background-color: ${Colors.MENU_BACKGROUND};
  border-radius: 8px;
  padding: ${(props) => (props.side ? '30px 20px' : 0)};
`;

export const HelpText = styled.p`
  color: ${Colors.HELP};
  font-size: 12px;
  line-height: 15px;
`;

export const HelpLink = styled.a`
  color: ${Colors.LIGHT_PRIMARY};
  font-size: 12px;
  line-height: 15px;
  padding-left: 5px;
`;

export const Title = styled.h3`
  font-size: 32px;
  max-width: 390px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Description = styled.p`
  font-size: 14px;
  margin-top: 7px;
  letter-spacing: -0.26px;
  color: ${Colors.DROPDOWN_ITEM};
`;
