import React from 'react';
import PropTypes from 'prop-types';
import { Container, Name, Date, Message } from './styles';
import { DateUtils, SharedTypes } from 'utils';
import { Grid } from 'styles';
import RatingInput from '../RatingInput';

function ReviewCard(props) {
  const { style, review } = props;
  return (
    <Container className="card" style={style}>
      <Grid.Row mb={5}>
        <Name>{review.name}</Name>
      </Grid.Row>
      <Grid.Row mb={15}>
        <Date>{DateUtils.formatDate(review.date)}</Date>
      </Grid.Row>
      <Grid.Row mb={15}>
        <RatingInput value={review.rating} readonly size={10} />
      </Grid.Row>
      <Grid.Row>
        <Message text={review.message} maxLine={3} />
      </Grid.Row>
    </Container>
  );
}

ReviewCard.propTypes = {
  style: PropTypes.object,
  review: SharedTypes.ReviewType,
};

ReviewCard.defaultProps = {
  style: {},
};

export default ReviewCard;
