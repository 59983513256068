import React from 'react';
import PropTypes from "prop-types";
import {Container, Title} from './styles';

function NoData(props) {
  const {text} = props;

  return (
    <Container>
      <Title>{text}</Title>
    </Container>
  );
}

NoData.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NoData;
