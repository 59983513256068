import React from 'react';
import PropTypes from "prop-types";
import {Container} from './styles';
import {SharedTypes} from "utils";
import {useHistory} from 'react-router-dom';
import MessagesListItem from "../MessagesListItem";

function MessagesList(props) {
  const {style, messages} = props;
  let history = useHistory();

  return (
    <Container style={style}>
      {messages.map((message) => (
        <MessagesListItem
          key={message.id}
          onClick={() => history.push(`/profilis/zinutes/${message.id}`)}
          message={message}
        />
      ))}
    </Container>
  );
}

MessagesList.propTypes = {
  style: PropTypes.object,
  messages: PropTypes.arrayOf(SharedTypes.MessagesListItemType).isRequired,
  loading: PropTypes.bool,
};

MessagesList.defaultProps = {
  style: {},
  loading: false,
};

export default MessagesList;
