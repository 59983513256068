import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.form`
  width: 100%;
  padding: 15px 0;
  background-color: ${Colors.WHITE};
  border-bottom: 1px solid ${Colors.FORM_BORDER};
`;

export const SubmitIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
