import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  color: ${Colors.BLACK};
`;
