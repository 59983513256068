import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {DatesActions} from "actions";

function useDates(props) {
  const {departureId, destinationId} = props;
  const dispatch = useDispatch();
  const {dates, isLoading, isLoaded} = useSelector(state => state.dates);
  const [availableDates, setAvailableDates] = useState(null);

  useEffect(() => {
    if (destinationId && departureId) {
      dispatch(DatesActions.getDates({departureId, destinationId}))
    }
  }, [departureId, destinationId]);

  useEffect(() => {
    if (destinationId && departureId) {
      setAvailableDates(dates);
    }
  }, [dates]);

  return {
    availableDates,
    isLoading,
    isLoaded,
  };
}

export default useDates;
