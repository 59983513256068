import React from 'react';
import {Container, Details, ImageContainer} from './styles';
import {Grid} from 'styles';
import RatingSkeleton from "../RatingSkeleton";

function OfferCardSkeleton() {
  return (
    <Container>
      <ImageContainer/>
      <Details>
        <Grid.Row mb={10}>
          <RatingSkeleton/>
        </Grid.Row>
        <Grid.Row mb={10}>
          <Grid.Skeleton
            animationDelay={0.25}
            height={20}
          />
        </Grid.Row>
        <Grid.Row mb={3}>
          <Grid.Skeleton
            animationDelay={0.35}
            height={15}
          />
        </Grid.Row>
        <Grid.Row mb={20}>
          <Grid.Skeleton
            width={200}
            animationDelay={0.4}
            height={15}
          />
        </Grid.Row>
        <Grid.Row alignItems="flex-end">
          <Grid.Col flex={1}>
            <Grid.Skeleton
              animationDelay={0.3}
              width={100}
              height={30}
            />
          </Grid.Col>
          <Grid.Col>
            <Grid.Skeleton
              animationDelay={0.2}
              width={100}
              height={30}
            />
          </Grid.Col>
        </Grid.Row>
      </Details>
    </Container>
  );
}

export default OfferCardSkeleton;
