import React from 'react';
import {Images} from 'styles';
import {Container, Icon, Label, Content} from "./styles";
import PropTypes from "prop-types";
import {TRIP_STATUSES} from "constant";

function Status(props) {
  const {past, status} = props;

  const renderContent = () => {
    if (past) {
      return (
        <Content>
          <Icon src={Images.CHECK_MARK}/>
          <Label>Įvykusi</Label>
        </Content>
      )
    }

    if (status === TRIP_STATUSES.PARTIALLY_PAID) {
      return (
        <Content>
          <Icon src={Images.CLOCK}/>
          <Label>Dalinai apmokėta</Label>
        </Content>
      )
    }

    if (status === TRIP_STATUSES.PAID) {
      return (
        <Content>
          <Icon src={Images.LIKE}/>
          <Label>Pilnai apmokėta</Label>
        </Content>
      )
    }
  };

  return (
    <Container>
      {renderContent()}
    </Container>
  );
}

Status.propTypes = {
  past: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default Status;
