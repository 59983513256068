import {Address, Container, Divider, HotelImage, Label, Title, Total, Value} from "./styles";
import React from "react";
import {Grid} from "styles";
import {RatingInput} from "components";
import {ImageUtils, SharedTypes} from "utils";
import {connect} from "react-redux";

function Summary(props) {
  const {offer} = props;

  return (
    <Container>
      <Grid.Row mb={25}>
        <Grid.Col mr={15}>
          <HotelImage src={ImageUtils.getOfferFirstImage(offer.images)}/>
        </Grid.Col>
        <Grid.Col flex={1}>
          <Grid.Row mb={3}>
            <Title>{offer.title}</Title>
          </Grid.Row>
          <Grid.Row mb={10}>
            <RatingInput readonly value={offer.hotelClass}/>
          </Grid.Row>
          <Grid.Row>
            <Address text={offer.location.address} maxLine={1}/>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Divider/>
      </Grid.Row>
      <Grid.Row mb={15}>
        <Grid.Col flex={1}>
          <Label>Papildomas bagažas (2x20 kg)</Label>
        </Grid.Col>
        <Grid.Col>
          <Value>€ 89.80</Value>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row mb={15}>
        <Grid.Col flex={1}>
          <Label>Kaina saugusiems</Label>
        </Grid.Col>
        <Grid.Col>
          <Value>{offer.adultsCount} X € {offer.pricing.priceForAdult}</Value>
        </Grid.Col>
      </Grid.Row>
      {offer.childrenCount > 0 && (
        <Grid.Row mb={25}>
          <Grid.Col flex={1}>
            <Label>Kaina vaikams</Label>
          </Grid.Col>
          <Grid.Col>
            <Value>{offer.childrenCount} X € {offer.pricing.priceForChild}</Value>
          </Grid.Col>
        </Grid.Row>
      )}
      {offer.infantsCount > 0 && (
        <Grid.Row mb={25}>
          <Grid.Col flex={1}>
            <Label>Kaina kūdikiams</Label>
          </Grid.Col>
          <Grid.Col>
            <Value>{offer.infantsCount} X € {offer.pricing.priceForInfant}</Value>
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row mb={25}>
        <Divider/>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col flex={1}>
          <Total>Viso</Total>
        </Grid.Col>
        <Grid.Col>
          <Total>€ {offer.pricing.totalPrice.toFixed(2)}</Total>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
}

Summary.propTypes = {
  offer: SharedTypes.OfferType.isRequired
};

const mapStateToProps = (state) => {
  const {checkout} = state;
  const {offer} = checkout;
  return {offer};
};

export default connect(mapStateToProps)(Summary);
