import React from 'react'
import PropTypes from "prop-types";
import {Container, TextInput} from "./styles";
import {Form} from "styles";

function Input(props) {
  const {style, placeholder, error, name, value, onBlur, onChange, label, type, onFocus, autoComplete, dirty} = props;

  const handleOnChange = event => {
    const {target} = event;
    const {value} = target;
    onChange({name, value});
  };

  const handleOnBlur = () => {
    onBlur({name});
  };

  const handleOnFocus = () => {
    onFocus({name});
  };

  return (
    <Container style={style}>
      {label && <Form.Label>{label}</Form.Label>}
      <TextInput
        name={name}
        placeholder={placeholder}
        value={value}
        type={type}
        autoComplete={autoComplete ? "on" : "off"}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        error={error}/>
      {dirty && error && <Form.Error>{error}</Form.Error>}
    </Container>
  );
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  dirty: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.string,
  autoComplete: PropTypes.bool,
};

Input.defaultProps = {
  style: {},
  error: null,
  value: '',
  dirty: false,
  type: 'text',
  label: null,
  onBlur: () => {},
  onFocus: () => {},
  placeholder: null,
  autoComplete: true,
};

export default Input;
