import React, {useState} from 'react';
import {Container, VideoContainer, Image, Label, Frame, Details} from "./styles";
import {Grid, Images} from 'styles';

function Video() {
  const [visible, setVisible] = useState();

  return (
    <Container>
      <Grid.Container>
        <VideoContainer onClick={() => setVisible(true)}>
          {!visible && (
            <Details>
              <Image src={Images.PLAY}/>
              <Label>Žiūrėti video</Label>
            </Details>
          )}
          {visible && (
            <Frame height="100%" width="100%" src="https://www.youtube.com/embed/QhjF5X63zQA?autoplay=1&mute=1"/>
          )}
        </VideoContainer>
      </Grid.Container>
    </Container>
  );
}

export default Video;
