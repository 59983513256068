import styled from 'styled-components/macro';
import { Colors, Grid, Media } from 'styles';
import { Link } from 'react-scroll';

export const Container = styled.div`
  background-color: ${Colors.WHITE};
  z-index: 10;
  top: 0;
  position: sticky;
  border-bottom: 1px solid ${Colors.FORM_BORDER};
`;

export const Content = styled(Grid.Container)`
  display: flex;
  justify-content: center;
  height: 70px;
  align-items: center;
  @media only screen and (max-width: ${Media.SMALL_MOBILE}px) {
    justify-content: space-between;
  }
`;

export const HeaderItem = styled(Link)`
  padding: 0 30px;
  font-size: 14px;
  height: 71px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.FORM_BORDER};
  font-weight: 600;
  text-transform: uppercase;
  color: ${Colors.DROPDOWN_ITEM};
  text-align: center;

  &:hover,
  &.active {
    cursor: pointer;
    border-bottom: 1px solid ${Colors.LIGHT_PRIMARY};
    color: ${Colors.LIGHT_PRIMARY};
  }

  @media only screen and (max-width: ${Media.TABLET}px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: ${Media.MOBILE}px) {
    font-size: 12px;
    padding: 0 5px;
  }

  @media only screen and (max-width: ${Media.SMALL_MOBILE}px) {
    word-break: break-word;
    max-width: 50px;
    padding: 0;
  }
`;
