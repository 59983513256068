import styled from 'styled-components/macro';
import { Colors, Grid } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const EmptyContainer = styled(Grid.Row)`
  min-height: 105px;
  border: 1px dashed ${(props) => (props.error ? Colors.DANGER : Colors.HELP)};
  border-radius: 6px;
  transition: 0.3s;
  position: relative;

  &:focus,
  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 6px ${hexToRgba(Colors.LIGHT_PRIMARY, 0.7)};
    border: 1px dashed ${Colors.LIGHT_PRIMARY};
  }
`;

export const Title = styled.span`
  color: ${Colors.TITLE};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;

export const Help = styled.span`
  color: ${Colors.HELP};
  font-size: 12px;
  line-height: 15px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ImageContainer = styled.div`
  height: 105px;
  width: 152px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.BLACK};

  &:hover {
    > img:first-of-type {
      opacity: 0.75;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  transition: 0.3s;
  object-fit: cover;
`;

export const CloseIcon = styled(Icon)`
  top: 5px;
  right: 5px;
  position: absolute;
  z-index: 2;

  &:hover {
    cursor: pointer;
  }
`;

export const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
  border-radius: 15px;
  z-index: 2;
`;

export const LoadMoreContainer = styled(EmptyContainer)`
  height: 105px;
  width: 152px;
  flex-direction: column;
`;
