import React from "react";
import PropTypes from 'prop-types';
import {Container, DropdownItemContainer} from "./styles";
import {SharedTypes} from "utils";
import Radio from "../../Radio";

function MultiSelectOptions(props) {
  const {options, style, onChange, values} = props;

  const handleClick = (value) => {
    let newValue = [];

    if (!values.includes(value)) {
      newValue.push(value);
    }

    onChange(newValue);

  };

  const renderSelectOption = (option) => (
    <DropdownItemContainer key={option.value} onClick={() => handleClick(option.value)}>
      <Radio
        checked={values.includes(option.value)}
        name={option.value.toString()}
        label={option.label}
      />
    </DropdownItemContainer>
  );

  return (
    <Container style={style}>
      {options.map(option => renderSelectOption(option))}
    </Container>
  )
}

MultiSelectOptions.propTypes = {
  options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  style: PropTypes.object,
};

MultiSelectOptions.defaultProps = {
  style: {},
  values: [],
};

export default MultiSelectOptions;
