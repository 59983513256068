import styled from 'styled-components/macro';

const Container = styled.div`
  padding: 5px 40px 40px 40px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  @media only screen and (max-width: 1110px) {
    display: unset;
  }
`;

export { Container };
