import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Description = styled.p`
  font-size: 15px;
  line-height: 28px;
  color: ${Colors.DROPDOWN_ITEM};
  text-align: justify;
`;

export const Name = styled.span`
  font-size: 14px;
  line-height: 28px;
  color: ${Colors.TITLE}
  font-weight: 700;
`;

export const Date = styled.span`
  font-size: 13px;
  color: ${Colors.HELP};
`;
