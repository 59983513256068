import styled from 'styled-components/macro';
import { Colors, Grid, Media } from 'styles';

export const Container = styled.div`
  background-color: ${Colors.WHITE};
  z-index: 100;
  bottom: 0;
  position: sticky;
  border-top: 1px solid ${Colors.FORM_BORDER};
`;

export const Content = styled(Grid.Container)`
  display: flex;
  height: 70px;
  align-items: center;
`;

export const Title = styled.span`
  color: ${Colors.TITLE};
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 700;
`;

export const City = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 12px;
  font-weight: 700;
`;

export const Help = styled.span`
  color: ${Colors.HELP};
  font-size: 12px;
`;

export const TitleContainer = styled(Grid.StaticCol)`
  flex: 1;

  @media only screen and (max-width: ${Media.TABLET}px) {
    display: none;
  }
`;

export const TitleWrapper = styled(Grid.StaticRow)`
  margin-bottom: 5px;
`;

export const PriceSection = styled(Grid.StaticCol)`
  @media only screen and (max-width: ${Media.TABLET}px) {
    width: 100%;
    align-items: center;
  }
`;

export const ButtonRow = styled(Grid.StaticRow)`
  @media only screen and (max-width: ${Media.TABLET}px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const PriceColumn = styled(Grid.StaticRow)`
  margin-right: 20px;

  @media only screen and (max-width: ${Media.TABLET}px) {
    margin-bottom: 0;
  }
`;

export const PriceRow = styled(Grid.StaticRow)`
  margin-right: 20px;

  @media only screen and (max-width: ${Media.TABLET}px) {
    align-items: center;
    width: 100%;
  }
`;

export const ExplanationRow = styled(Grid.StaticRow)`
  @media only screen and (max-width: ${Media.TABLET}px) {
    text-align: center;
  }
`;
