import React from "react";
import PropTypes from 'prop-types';
import {Container, Label, CheckContainer, Icon} from './styles';
import {Images, Grid, Form} from "styles";

function Checkbox(props) {
  const {onChange, label, style, checked, disabled, name, highlighted, dirty, error, onBlur} = props;

  const handleOnChange = () => {
    onChange({ name, value: !checked });
    onBlur({name});
  };

  return (
    <Container onClick={handleOnChange} disabled={disabled} style={style}>
      <Grid.Row alignItems="flex-start">
        <CheckContainer checked={checked}>
          {checked && <Icon src={Images.CHECK_MARK_WHITE}/>}
        </CheckContainer>
        {label && <Label highlighted={highlighted}>{label}</Label>}
      </Grid.Row>
      {dirty && error && <Form.Error>{error}</Form.Error>}
    </Container>
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  highlighted: PropTypes.bool,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  dirty: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  style: {},
  label: null,
  onBlur: () => {},
  dirty: false,
  error: null,
  highlighted: false,
  onChange: () => {},
  checked: false,
  disabled: false,
};

export default Checkbox;
