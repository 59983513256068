import React from 'react';
import {Container, Header} from "./styles";
import {HomeSearchForm} from "containers";
import {useHistory} from 'react-router-dom';
import {Grid} from "styles";
import {UrlUtils} from "utils";

function Search() {
  let history = useHistory();

  const handleSearch = (value) => {
    const queryString = UrlUtils.formatQueryString(value);

    history.push({
      pathname: '/pasiulymai',
      search: queryString
    });
  };

  return (
    <Container>
      <Header>
        <Grid.Container>
          <HomeSearchForm onSubmit={handleSearch}/>
        </Grid.Container>
      </Header>
    </Container>
  );
}

export default Search;
