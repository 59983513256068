import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const SideContainer = styled.div`
  position: sticky;
  top: ${(props) => props.topOffset}px;
  padding-bottom: ${(props) => props.bottomOffset}px;
  width: ${(props) => props.sideWidth}px;
  min-width: ${(props) => props.sideWidth}px;

  @media only screen and (max-width: 1110px) {
    width: 100%;
  }
`;
