import {combineReducers} from "redux";

import contacts from './contacts';
import subscribers from './subscribers';
import reviews from './reviews';
import authentication from './authentication';
import offers from './offers';
import offer from './offer';
import faqs from './faqs';
import destinations from './destinations';
import trips from './trips';
import categories from './categories';
import tvsPage from './tvsPage';
import filters from './filters';
import messages from './messages';
import review from './review';
import articles from './articles';
import article from './article';
import dates from './dates';
import employees from './employees';
import checkout from './checkout';

const rootReducer = combineReducers({
  contacts,
  subscribers,
  reviews,
  authentication,
  offers,
  offer,
  faqs,
  destinations,
  trips,
  categories,
  tvsPage,
  filters,
  messages,
  review,
  articles,
  article,
  dates,
  employees,
  checkout
});

export {rootReducer};
