import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Content = styled.div``;

export const BoldText = styled.b`
  font-weight: 700;
`;

export const Text = styled.p`
  color: ${Colors.WHITE};
  font-family: 'Proxima Nova';
  font-size: 14px;
  line-height: 20px;
`;
