import update from 'immutability-helper';
import {ContactsTypes} from 'types';

const initialState = {
    isLoading: false,
};


export default (state = initialState, action) => {
    switch (action.type) {
        case ContactsTypes.SEND_MESSAGE_START: {
            return update(state, {
                isLoading: { $set: true },
            });
        }

        case ContactsTypes.SEND_MESSAGE_ERROR: {
            return update(state, {
                isLoading: { $set: false },
            });
        }

        case ContactsTypes.SEND_MESSAGE: {
            return update(state, {
                isLoading: { $set: false },
            });
        }

        default:
            return state
    }
}
