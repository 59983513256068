import React from 'react';
import PropTypes from "prop-types";
import {Grid} from 'styles';
import {Container, TopContent} from './styles';

import Newsletter from "./Newsletter";
import Partners from "./Partners";
import Navigation from "./Navigation";
import Description from "./Description";
import Categories from "./Categories";

function Footer(props) {
  const {style} = props;

  return (
    <Container style={style}>
      <TopContent>
        <Grid.Container>
          <Newsletter/>
          <Navigation/>
        </Grid.Container>
      </TopContent>
      <Grid.Container>
        <Partners/>
        <Categories/>
        <Description/>
      </Grid.Container>
    </Container>
  );
}

Footer.propTypes = {
  style: PropTypes.object,
};

Footer.defaultProps = {
  style: {},
};

export default Footer;
