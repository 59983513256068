import React, {useEffect} from 'react';
import {Container, Content} from './styles';
import {TvsPageActions} from "actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {ImageUtils, SharedTypes} from "utils";
import {Grid} from "styles";
import {useParams} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

function TvsPage(props) {
  const {getTvsPage, tvsPage, isLoaded} = props;
  const {slug} = useParams();

  useEffect(() => {
    getTvsPage(slug);
  }, [slug]);

  const renderContent = () => {
    return ReactHtmlParser(tvsPage.content, {transform: ImageUtils.transformTvsImage});
  };

  return (
    <Container>
      <Grid.Container>
        <Content className="static-page">
          {isLoaded && renderContent()}
        </Content>
      </Grid.Container>
    </Container>
  );
}

TvsPage.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  getTvsPage: PropTypes.func.isRequired,
  tvsPage: SharedTypes.TvsPageType,
};

const mapStateToProps = (state) => {
  const {tvsPage} = state;
  const {isLoaded} = tvsPage;
  return {isLoaded, tvsPage: tvsPage.tvsPage};
};

const mapDispatchToProps = {
  getTvsPage: TvsPageActions.getTvsPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TvsPage);
