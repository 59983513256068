import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const CheckContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  min-width: 20px;
  overflow: hidden;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.checked ? Colors.LIGHT_PRIMARY : 'transparent')};
  border: 1px solid ${(props) => (props.checked ? Colors.LIGHT_PRIMARY : Colors.CHECKBOX_BORDER)};
`;

export const Label = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  margin-left: 10px;
  font-size: 14px;
  font-weight: ${(props) => (props.highlighted ? 600 : 400)};
  line-height: 20px;
  white-space: ${(props) => (props.highlighted ? 'nowrap' : 'wrap')};
`;

export const Icon = styled.img`
  width: 14px;
  height: 14px;
`;
