import React from 'react';
import PropTypes from "prop-types";
import {Container, Title} from './styles';

function Badge(props) {
  const {onClick, selected, disabled, title} = props;

  return (
    <Container selected={selected} disabled={disabled} onClick={onClick}>
      <Title>{title}</Title>
    </Container>
  );
}

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

Badge.defaultProps = {
  onClick: () => {},
  selected: false,
  disabled: false,
};

export default Badge;
