import React from 'react';
import PropTypes from "prop-types";
import {Buttons, Images} from "styles";
import {Container} from './styles';
import {GOOGLE_TOKEN} from "../../config";
import GoogleLogin from 'react-google-login';
import AuthenticationButton from "../AuthenticationButton";

function GoogleButton(props) {
  const {onSuccess} = props;

  const handleSuccess = (response) => {
    const {accessToken} = response;
    if (response.accessToken) {
      onSuccess({provider: 'google', token: accessToken});
    }
  };

  const renderGoogleButton = (renderProps) => (
    <AuthenticationButton
      icon={Images.GOOGLE}
      theme={Buttons.GOOGLE}
      label="Tęskite su Google"
      onClick={renderProps.onClick}
    />
  );

  return (
    <Container>
      <GoogleLogin
        clientId={GOOGLE_TOKEN}
        render={renderGoogleButton}
        onSuccess={handleSuccess}
      />
    </Container>
  );
}

GoogleButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default GoogleButton;
