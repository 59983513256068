import React from 'react';
import PropTypes from "prop-types";
import {Container} from './styles';

function GridContainer(props) {
  const {style, children, rowGap, columnGap, itemsCount, mobileItemsCount, tabletItemsCount, smallDesktopItemsCount} = props;
  
  return (
    <Container
      smallDesktopItemsCount={smallDesktopItemsCount}
      mobileItemsCount={mobileItemsCount}
      tabletItemsCount={tabletItemsCount}
      itemsCount={itemsCount}
      columnGap={columnGap}
      rowGap={rowGap}
      style={style}
    >
      {children}
    </Container>
  );
}

GridContainer.propTypes = {
  style: PropTypes.object,
  rowGap: PropTypes.number,
  itemsCount: PropTypes.number,
  mobileItemsCount: PropTypes.number,
  smallDesktopItemsCount: PropTypes.number,
  tabletItemsCount: PropTypes.number,
  columnGap: PropTypes.number,
  children: PropTypes.node.isRequired,
};

GridContainer.defaultProps = {
  style: {},
  rowGap: 15,
  itemsCount: 4,
  mobileItemsCount: 1,
  tabletItemsCount: 2,
  smallDesktopItemsCount: 3,
  columnGap: 30,
};

export default GridContainer;
