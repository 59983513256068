import React from 'react';
import {Grid, Images} from 'styles';
import {Info, QualityImage, QualityInfo, Container} from "./styles";

function Description() {
  return (
    <Container>
      <Grid.Row mb={25}>
        <Grid.Desktop>
          <Grid.Col mr={50}>
            <QualityImage src={Images.STRONGEST}/>
          </Grid.Col>
        </Grid.Desktop>
        <Grid.Mobile style={{width:'100%'}}>
          <Grid.Col mr={40} alignItems='center'>
            <QualityImage src={Images.STRONGEST}/>
          </Grid.Col>
        </Grid.Mobile>
        <Grid.Col>
          <QualityInfo>
            Kokybės vadybos sistema, sukurta, įdiegta ir palaikoma
            UAB „Baltic Tours Group“ atitinka standarto LST EN ISO 9001:2015 reikalavimus.
            <br/>
            „Kelionės organizatoriaus prievolių įvykdymo užtikrinimo draudimo polisas: GJELT Nr. 2809198, ADB „Gjensidige“,
            Žalgirio g. 90, LT-09303, Vilnius, el.paštas info@gjensidige.lt, tel. 1626. Kelionių organizatoriaus
            pažymėjimo Nr. 051046.
          </QualityInfo>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Info>
          Baltic Tours - kelionių agentūra išskirtinių potyrių ieškantiems keliautojams. Geriausi kelionių pasiūlymai,
          profesionalūs gidai, patogios kelionės lėktuvu - tai Baltic Tours privalumas. Dabar yra geriausias metas
          keliauti! Ne veltui kelionės į užsienį yra vadinamos geriausiu poilsiu - kur galima atsipalaiduoti labiau
          nei pajūrio viešbutyje kaitinant saulei ar čiuožiant snieguota kalnų trasa? Jau šiandien leiskitės į skrydį -
          paprasta kelionių lėktuvu paieška ir prieinama kaina leis visada rasti tinkamiausią pasiūlymą. Su Baltic
          Tours kelionėmis rūpesčius pakeis nuostaba - populiariausios “viskas įskaičiuota” kelionės sužavi ir
          įnoringiausius keliautojus puikia organizacija ir įdomiais maršrutais. Savaitgalio išvykos, gurmaniški
          turai, aktyvus poilsis bei egzotiškos kelionės - idealios kelionės paieška bus tikras malonumas, o
          kelionės pranoks visus lūkesčius. Padovanokite sau bei savo artimiesiems neišdildomus įspūdžius su
          Baltic Tours!
        </Info>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Info>
          Pasiūlymų kiekis ribotas. Baltic Tours kontaktai +370 5 266 1616, el. paštas info@baltictours.lt.
          Jei internetiniame puslapyje kainos ir kita informacija apie pasiūlymus nesutampa su prekybos vietose
          esančiomis, prašome internetiniame puslapyje pateiktas kainas ir informaciją laikyti programavimo klaida.
        </Info>
      </Grid.Row>
      <Grid.Row>
        <Info active>
          2019 © Visos teisės saugomos. BALTIC TOURS. Vašingtono a. 1, LT-01108 Vilnius
        </Info>
      </Grid.Row>
    </Container>
  );
}

export default Description;
