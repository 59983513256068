import update from 'immutability-helper';
import {ArticleTypes} from 'types';

const initialState = {
  article: null,
  isLoading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ArticleTypes.GET_ARTICLE_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case ArticleTypes.GET_ARTICLE: {
      return update(state, {
        article: {$set: payload.article},
        isLoading: {$set: false},
      });
    }

    case ArticleTypes.GET_ARTICLE_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
