import { Colors, Grid } from 'styles';
import { Container, Header, Content, HeaderLabel, Advance, ButtonContainer, HeaderRow } from './styles';
import React from 'react';
import { Button, Price } from 'components';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { CheckoutActions } from 'actions';
import { connect } from 'react-redux';

function Inclusive(props) {
  const { roomId, price, advance, reset } = props;
  const history = useHistory();
  const { id } = useParams();

  const navigate = () => {
    reset();
    history.push(`/atsiskaitymas/${id}/${roomId}`);
  };

  return (
    <Container>
      <Header>
        <HeaderRow mb={15}>
          <HeaderLabel>Kaina asmeniui nuo</HeaderLabel>
        </HeaderRow>
        <HeaderRow mb={5}>
          <Price value={price} fontWeight={700} color={Colors.WHITE} fontSize={28} />
        </HeaderRow>
        <Grid.Row>
          <Grid.Col mr={3}>
            <Advance>Avansas:</Advance>
          </Grid.Col>
          <Grid.Col>
            <Price value={advance} fontWeight={400} color={Colors.HELP} fontSize={12} />
          </Grid.Col>
        </Grid.Row>
      </Header>
      <Content>
        <Grid.Row justifyContent="center">
          <ButtonContainer>
            <Button disabled={!roomId} fullWidth label="Užsakyti" onClick={navigate} />
          </ButtonContainer>
        </Grid.Row>
      </Content>
    </Container>
  );
}

Inclusive.propTypes = {
  roomId: PropTypes.number,
  price: PropTypes.number.isRequired,
  advance: PropTypes.number.isRequired,
  reset: PropTypes.func.isRequired,
  inclusive: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Inclusive.defaultProps = {
  roomId: null,
};

const mapDispatchToProps = {
  reset: CheckoutActions.reset,
};

export default connect(null, mapDispatchToProps)(Inclusive);
