import React from 'react';
import { Grid, Images } from 'styles';
import {
  Logo,
  Container,
  Phone,
  Help,
  Icon,
  Email,
  SectionTitle,
  NavigationLink,
  NavigationIcon,
  MobilePhoneRow,
} from './styles';

function Navigation() {
  return (
    <Container>
      <Grid.Desktop>
        <Grid.Row justifyContent="space-between" alignItems="flex-start">
          <Grid.Col mr={50}>
            <Grid.Row mb={40}>
              <Logo src={Images.LOGO} />
            </Grid.Row>
            <Grid.Row mb={25} className="no-break" fullWidth>
              <Grid.Col mr={15} className="no-break">
                <Icon src={Images.PHONE} />
              </Grid.Col>
              <Grid.Col>
                <Phone href="tel:+37052661616">+370 5 266 1616</Phone>
                <Help>Darbo dienomis 8:00 - 18:00</Help>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="no-break">
              <Grid.Col mr={15} className="no-break">
                <Icon src={Images.ENVELOPE} />
              </Grid.Col>
              <Grid.Col>
                <Email href="mailto:info@baltictours.lt">info@baltictours.lt</Email>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid.Col>
          <Grid.Col mr={50}>
            <SectionTitle>Kelionės</SectionTitle>
            <NavigationLink to="/specialus-pasiulymai">TOP Pasiūlymai</NavigationLink>
            <NavigationLink to="/paskutines-minutes-pasiulymai">Paskutinė minutė</NavigationLink>
            <NavigationLink to="/pasiulymai">Kelionės</NavigationLink>
            <NavigationLink to="/salys">Šalys</NavigationLink>
            <NavigationLink to="/">Pigios kelionės</NavigationLink>
            <NavigationLink to="/">Skrydžiai</NavigationLink>
            <NavigationLink to="/">Poilsinės kelionės</NavigationLink>
          </Grid.Col>
          <Grid.Col mr={50}>
            <SectionTitle>Baltic Tours</SectionTitle>
            <NavigationLink to="/duk">DUK</NavigationLink>
            <NavigationLink to="/apie-mus">Apie mus</NavigationLink>
            <NavigationLink to="/kontaktai">Kontaktai</NavigationLink>
            <NavigationLink to="/slapukai">Slapukai</NavigationLink>
            <NavigationLink to="/dovanu-kuponas">Dovanų kuponas</NavigationLink>
            <NavigationLink to="/karjera">Karjera</NavigationLink>
            <NavigationLink to="/kurybine-praktika">Kūrybinė praktika</NavigationLink>
          </Grid.Col>
          <Grid.Col mr={50}>
            <SectionTitle>Keliautojams</SectionTitle>
            <NavigationLink to="/">Kaip pirkti internetu</NavigationLink>
            <NavigationLink to="/">Kelionių draudimas</NavigationLink>
            <NavigationLink to="/">Kelionių grąžinimas</NavigationLink>
          </Grid.Col>
          <Grid.Col>
            <SectionTitle>Sekite mus</SectionTitle>
            <NavigationLink to="https://www.facebook.com/baltictoursgroup/" target="_blank">
              <NavigationIcon src={Images.FACEBOOK_GREY} />
              Facebook
            </NavigationLink>
            <NavigationLink to="https://www.instagram.com/baltictours.lt/" target="_blank">
              <NavigationIcon src={Images.INSTAGRAM_GREY} />
              Instagram
            </NavigationLink>
            <NavigationLink to="https://www.linkedin.com/company/baltic-tours" target="_blank">
              <NavigationIcon src={Images.LINKEDIN_GREY} />
              LinkedIn
            </NavigationLink>
          </Grid.Col>
        </Grid.Row>
      </Grid.Desktop>
      <Grid.Mobile>
        <Grid.Row justifyContent="space-between" alignItems="flex-start">
          <Grid.Col mr={50}>
            <Grid.Row mb={40}>
              <Logo src={Images.LOGO} />
            </Grid.Row>
            <MobilePhoneRow mb={25} className="no-break" fullWidth>
              <Grid.Col mr={15} className="no-break">
                <Icon src={Images.PHONE} />
              </Grid.Col>
              <Grid.StaticCol>
                <Phone href="tel:+37052661616">+370 5 266 1616</Phone>
                <Help>Darbo dienomis 8:00 - 18:00</Help>
              </Grid.StaticCol>
            </MobilePhoneRow>
            <Grid.Row className="no-break">
              <Grid.Col mr={15} className="no-break">
                <Icon src={Images.ENVELOPE} />
              </Grid.Col>
              <Grid.Col>
                <Email href="mailto:info@baltictours.lt">info@baltictours.lt</Email>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="no-break">
          <Grid.Col mr={50}>
            <SectionTitle>Kelionės</SectionTitle>
            <NavigationLink to="/specialus-pasiulymai">TOP Pasiūlymai</NavigationLink>
            <NavigationLink to="/paskutines-minutes-pasiulymai">Paskutinė minutė</NavigationLink>
            <NavigationLink to="/pasiulymai">Kelionės</NavigationLink>
            <NavigationLink to="/salys">Šalys</NavigationLink>
            <NavigationLink to="/">Pigios kelionės</NavigationLink>
            <NavigationLink to="/">Skrydžiai</NavigationLink>
            <NavigationLink to="/">Poilsinės kelionės</NavigationLink>
          </Grid.Col>
          <Grid.Col mr={50}>
            <SectionTitle>Baltic Tours</SectionTitle>
            <NavigationLink to="/duk">DUK</NavigationLink>
            <NavigationLink to="/apie-mus">Apie mus</NavigationLink>
            <NavigationLink to="/kontaktai">Kontaktai</NavigationLink>
            <NavigationLink to="/slapukai">Slapukai</NavigationLink>
            <NavigationLink to="/dovanu-kuponas">Dovanų kuponas</NavigationLink>
            <NavigationLink to="/karjera">Karjera</NavigationLink>
            <NavigationLink to="/kurybine-praktika">Kūrybinė praktika</NavigationLink>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="no-break">
          <Grid.Col mr={50}>
            <SectionTitle>Keliautojams</SectionTitle>
            <NavigationLink to="/">Kaip pirkti internetu</NavigationLink>
            <NavigationLink to="/">Kelionių draudimas</NavigationLink>
            <NavigationLink to="/">Kelionių grąžinimas</NavigationLink>
          </Grid.Col>
          <Grid.Col mr={50}>
            <SectionTitle>Sekite mus</SectionTitle>
            <NavigationLink to="https://www.facebook.com/baltictoursgroup/" target="_blank">
              <NavigationIcon src={Images.FACEBOOK_GREY} />
              Facebook
            </NavigationLink>
            <NavigationLink to="https://www.instagram.com/baltictours.lt/" target="_blank">
              <NavigationIcon src={Images.INSTAGRAM_GREY} />
              Instagram
            </NavigationLink>
            <NavigationLink to="https://www.linkedin.com/company/baltic-tours" target="_blank">
              <NavigationIcon src={Images.LINKEDIN_GREY} />
              LinkedIn
            </NavigationLink>
          </Grid.Col>
        </Grid.Row>
      </Grid.Mobile>
    </Container>
  );
}

export default Navigation;
