import React from 'react';
import {Container, Description, DetailsContainer, DetailsDescription, DetailsIcon, DetailsTitle, DetailsTextContainer, Image} from "./styles";
import {Grid, Images, Typography} from 'styles';

function Activities() {
  return (
    <Container>
      <Grid.Container>
        <Grid.Row mb={10}>
          <Typography.PageTitle center>Baltic Tours iniciatyvos</Typography.PageTitle>
        </Grid.Row>
        <Grid.Row justifyContent="center" mb={70}>
          <Description>
            Baltic Tours komanda yra entuziastingi irklavimo, triatlono ir parasparnių sporto rėmėjai
          </Description>
        </Grid.Row>
        <Grid.Row mb={30}>
          <Grid.Col ml={15} flex={1} mr={30}>
            <DetailsContainer>
              <DetailsIcon src={Images.BOAT}/>
              <DetailsTextContainer>
                <Grid.Row mb={10}>
                  <DetailsTitle>Irklavimas</DetailsTitle>
                </Grid.Row>
                <Grid.Row>
                  <DetailsDescription>
                    Baltic Tours Group remia Lietuvos irklavimą, apdovanoja prizininkus taip
                    pat prisideda prie tarptautinės irklavimo meistrų regatos Rowing Masters
                    Sprint organizavimo.
                  </DetailsDescription>
                </Grid.Row>
              </DetailsTextContainer>
            </DetailsContainer>
          </Grid.Col>
          <Grid.Col alignItems="center" mr={10}>
            <Image src={Images.TEAM_4}/>
          </Grid.Col>
          <Grid.Col alignItems="center" mr={10}>
            <Image src={Images.TEAM_5}/>
          </Grid.Col>
          <Grid.Col alignItems="center" mr={10}>
            <Image src={Images.TEAM_6}/>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row mb={30}>
          <Grid.Col alignItems="center" mr={10}>
            <Image src={Images.TEAM_7}/>
          </Grid.Col>
          <Grid.Col alignItems="center" mr={10}>
            <Image src={Images.TEAM_8}/>
          </Grid.Col>
          <Grid.Col alignItems="center" mr={30}>
            <Image src={Images.TEAM_9}/>
          </Grid.Col>
          <Grid.Col mr={15} flex={1}>
            <DetailsContainer>
              <DetailsIcon src={Images.BIKE}/>
              <DetailsTextContainer>
                <Grid.Row mb={10}>
                  <DetailsTitle>Triatlonas</DetailsTitle>
                </Grid.Row>
                <Grid.Row>
                  <DetailsDescription>
                    Baltic Tours Group yra ilgametis perspektyvaus triatlonininko Alberto
                    Casillas Garcia, varžybose atstovaujančio Lietuvą, rėmėjas ir kelionės
                    į sėkmę partneris.
                  </DetailsDescription>
                </Grid.Row>
              </DetailsTextContainer>
            </DetailsContainer>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col ml={15} mr={30} flex={1}>
            <DetailsContainer>
              <DetailsIcon src={Images.PARACHUTE}/>
              <DetailsTextContainer>
                <Grid.Row mb={10}>
                  <DetailsTitle>Parasparniai</DetailsTitle>
                </Grid.Row>
                <Grid.Row>
                  <DetailsDescription>
                    Baltic Tours Group komanda yra aistringi parasparnių sporto
                    entuziastai! Apsidairykite, galbūt kitą kartą virš lietuviškų
                    pievų išvysite ir Skrendu.lt sparną.
                  </DetailsDescription>
                </Grid.Row>
              </DetailsTextContainer>
            </DetailsContainer>
          </Grid.Col>
          <Grid.Col alignItems="center" mr={10}>
            <Image src={Images.TEAM_1}/>
          </Grid.Col>
          <Grid.Col alignItems="center" mr={10}>
            <Image src={Images.TEAM_2}/>
          </Grid.Col>
          <Grid.Col alignItems="center">
            <Image src={Images.TEAM_3}/>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Container>
  );
}

export default Activities;
