export const random = (min, max) => {
  return Math.floor(Math.random() * (max - min) ) + min;
};

export const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

export const tryParseToNumber = (value) => {
  return isNumeric(value) ? Number(value) : value;
};

export const tryParseArrayToNumbers = (values) => {
  return values.map(value => tryParseToNumber(value));
};

export const parseToArray = (value) => {
  if (!value) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return [value];
};

export const getDistance = (value) => {
  return `${value} km`;
};
