import apiAxiosInstance from "../apiAxiosInstance";
import {store} from "store";

class DestinationsService {
  static async getDestinations() {
    const paging = {limit: 30, offset: 0};
    const params = {...paging};
    return await apiAxiosInstance.get(`/destinations`, {params});
  }

  static getDestinationById(id) {
    const destinations = store.getState().destinations.destinations;
    return destinations.find(destination => destination.id === id);
  }

  static getCities(parentId) {
    const cities = store.getState().destinations.cities;
    return cities.filter(city => city.parentId === parentId);
  }
}

export default DestinationsService;
