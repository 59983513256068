import React from 'react';
import PropTypes from 'prop-types';
import {Container, CloseIcon, CloseIconContainer} from './styles';
import {default as ReactModal} from 'react-modal';
import hexToRgba from "hex-to-rgba";
import {Colors, Images} from "styles";

const modalStyle = {
  overlay: {
    zIndex: 999,
    backgroundColor: hexToRgba(Colors.BLACK, 0.5)
  },
  content: {
    height: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none'
  }
};

function Modal(props) {
  const {style, children, visible, onClose, onShow, large, transparent, className, closeWhite} = props;

  return (
    <ReactModal
      className={className}
      ariaHideApp={false}
      onRequestClose={onClose}
      onAfterOpen={onShow}
      isOpen={visible}
      style={modalStyle}>
      <Container transparent={transparent} large={large} style={style}>
        <CloseIconContainer closeWhite={closeWhite} transparent={transparent} onClick={onClose}>
          <CloseIcon closeWhite={closeWhite} src={closeWhite ? Images.CLOSE : Images.EXIT}/>
        </CloseIconContainer>
        {children}
      </Container>
    </ReactModal>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onShow: PropTypes.func,
  visible: PropTypes.bool,
  large: PropTypes.bool,
  transparent: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  closeWhite: PropTypes.bool,
};

Modal.defaultProps = {
  style: {},
  visible: false,
  transparent: false,
  large: false,
  closeWhite: false,
  onShow: () => {},
};

export default Modal;
