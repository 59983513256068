import React from 'react';
import PropTypes from "prop-types";
import {Container, Title, Description, Icon, Price} from './styles';
import {Grid} from 'styles';
import Checkbox from "../Checkbox";

function CheckoutItem(props) {
  const {style, title, onChange, description, checked, name, icon, price} = props;

  const handleChange = () => {
    onChange({name, value: !checked});
  };

  return (
    <Container onClick={handleChange} style={style}>
      <Grid.Row alignItems="flex-start">
        <Grid.Col mr={20}>
          <Checkbox checked={checked} disabled name={name}/>
        </Grid.Col>
        <Grid.Col mr={35} flex={1}>
          <Grid.Row mb={10}>
            <Title>{title}</Title>
          </Grid.Row>
          <Grid.Row>
            <Description>{description}</Description>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col alignItems="center">
          <Grid.Row mb={15}>
            <Icon src={icon}/>
          </Grid.Row>
          <Grid.Row>
            <Price>{`Nuo € ${price.toFixed(2)}`}</Price>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
}

CheckoutItem.propTypes = {
  style: PropTypes.object,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconHelp: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

CheckoutItem.defaultProps = {
  style: {},
  iconHelp: null,
  checked: false,
};

export default CheckoutItem;
