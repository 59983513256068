import React from "react";
import PropTypes from "prop-types";
import {DecimalPart, Container} from "./styles";
import {Colors} from "styles";

function Price(props) {
  const {value, color, fontSize, fontWeight, style} = props;
  const formattedValue = Number(value).toFixed(2).toString().split('.');

  return (
    <Container style={style} fontWeight={fontWeight} fontSize={fontSize} color={color}>
      {`€${formattedValue[0]}`}
      <DecimalPart>
        {formattedValue[1]}
      </DecimalPart>
    </Container>
  )
}

Price.propTypes = {
  style: PropTypes.object,
  value: PropTypes.number,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  fontSize: PropTypes.number
};

Price.defaultProps = {
  style: {},
  color: Colors.LIGHT_PRIMARY,
  fontSize: 21,
  value: 0,
  fontWeight: 700,
};


export default Price;
