import apiAxiosInstance from "../apiAxiosInstance";

class AuthenticationService {
  static async register(request) {
    const response = await apiAxiosInstance.post(`/auth/register`, {
      email: request.email,
      password: request.password,
      first_name: request.name,
      last_name: request.surname,
      phone: request.phone
    });

    const {data, success} = response;

    if (success === 'false') {
      const exception = new Error('Naudotojas su tokiu el. paštu jau egzsituoja');
      exception.code = 400;
      throw exception;
    }

    return {token: data.access_token};
  }

  static async login(request) {
    const response = await apiAxiosInstance.post(`/auth/login`, request);
    const {data, success} = response;

    if (success === 'false') {
      const exception = new Error('Neteisingi prisijungimo duomenys');
      exception.code = 401;
      throw exception;
    }

    return {token: data.access_token};
  }

  static async remindPassword(request) {
    return apiAxiosInstance.post(`/auth/remind-password`, request);
  }

  static async getLoggedUser(token = null) {
    const config = {};

    if (token) {
      config['headers'] = {Authorization: `Bearer ${token}`};
    }

    const response = await apiAxiosInstance.post(`/auth/me`, null, config);
    const {data} = response;

    return {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone || '',
      photo: data.profilePhoto,
    };
  }

  static async providerLogin(request) {
    const response = await apiAxiosInstance.get(`/auth/login/${request.provider}/${request.token}`);
    const {data, success} = response;

    if (success === 'false') {
      const exception = new Error('Neteisingi prisijungimo duomenys');
      exception.code = 401;
      throw exception;
    }

    return {token: data.access_token};
  }

  static async logout() {
    return apiAxiosInstance.post(`/auth/logout`);
  }

  static async refreshToken() {
    return apiAxiosInstance.post(`/auth/refresh`);
  }
}

export default AuthenticationService;
