import styled from 'styled-components/macro';
import { Colors, Media } from 'styles';

export const Container = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    background: url(${(props) => props.backgroundImage}) no-repeat center/cover;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(-57deg, rgba(15, 90, 141, 0) 0%, rgba(15, 90, 141, 0.86) 63%, #0f5a8d 100%);
  }
`;

export const Content = styled.div`
  display: flex;
  min-height: 220px;
  padding: 35px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Title = styled.h2`
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  letter-spacing: -0.68px;
  color: ${Colors.WHITE};

  @media only screen and (max-width: ${Media.MOBILE}px) {
    font-size: ${(props) => props.fontSize - 10}px;
  }

  @media only screen and (max-width: ${Media.SMALL_MOBILE}px) {
    font-size: 30px;
  }
`;
