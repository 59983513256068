import React from 'react';
import PropTypes from "prop-types";
import {Container} from './styles';
import {FacebookMessengerShareButton as ShareButton} from "react-share";
import {FACEBOOK_TOKEN} from "../../config";

function MessengerShareButton(props) {
  const {children} = props;

  return (
    <Container>
      <ShareButton appId={FACEBOOK_TOKEN} url={window.location.href}>
        {children}
      </ShareButton>
    </Container>
  );
}

MessengerShareButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MessengerShareButton;
