import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  border: 1px solid ${Colors.BORDER};
  border-radius: 6px;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: 10px 30px 15px 30px;
`;
