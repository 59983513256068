import React from 'react';
import {Container} from "./styles";
import {Grid} from "styles";

import Categories from "./Categories";
import Search from "./Search";
import LastMinuteOffers from "./LastMinuteOffers";
import Reviews from "./Reviews";
import Countries from "./Countries";
import Blog from "./Blog";

function HomePage() {
  return (
    <Container>
      <Search/>
      <Grid.Row mb={60}>
        <Categories/>
      </Grid.Row>
      <Grid.Row mb={95}>
        <Countries/>
      </Grid.Row>
      <Grid.Row mb={60}>
        <LastMinuteOffers/>
      </Grid.Row>
      <Grid.Row mb={90}>
        <Reviews/>
      </Grid.Row>
      <Grid.Row mb={60}>
        <Blog/>
      </Grid.Row>
    </Container>
  );
}

export default HomePage;
