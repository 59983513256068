import update from 'immutability-helper';
import {DestinationsTypes} from 'types';

const initialState = {
  destinations: [],
  cities: [],
  countries: [],
  count: 0,
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case DestinationsTypes.GET_DESTINATIONS_START: {
      return update(state, {
        isLoading: {$set: true},
        isLoaded: {$set: false},
      });
    }

    case DestinationsTypes.GET_DESTINATIONS: {
      return update(state, {
        destinations: {$set: payload.destinations},
        cities: {$set: payload.cities},
        countries: {$set: payload.countries},
        count: {$set: payload.count},
        isLoading: {$set: false},
        isLoaded: {$set: true},
      });
    }

    case DestinationsTypes.GET_DESTINATIONS_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
