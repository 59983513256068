import React from "react";
import PropTypes from 'prop-types';
import {ClearFilterButton, ClearFilterButtonLabel, ClearFiltersIcon, Container} from './styles';
import {Grid, Images} from 'styles';
import {Loadable, MultiSelect} from "components";
import {connect} from "react-redux";
import {NumberUtils, SharedTypes, UrlUtils} from "utils";
import {useHistory} from "react-router-dom";
import {useFilter} from "hooks";

function Filter(props) {
  const {style, filters, isLoading} = props;
  const history = useHistory();
  const filter = useFilter();

  const updateFilter = (event) => {
    const {name, value} = event;
    const queryString = UrlUtils.setQueryArrayParam(name, value);
    history.push({search: queryString})
  };

  const resetFilter = () => {
    const filtersKeys = filters.map(filter => filter.key);
    const queryString = UrlUtils.resetQueryParams(filtersKeys);
    history.push({search: queryString})
  };

  const mapFilterOptions = (options) => {
    return options.map(option => {
      return {value: option.id, label: option.title};
    })
  };

  const renderOfferFilter = (offerFilter) => (
    <MultiSelect
      values={NumberUtils.parseToArray(filter[offerFilter.key])}
      options={mapFilterOptions(offerFilter.options)}
      onChange={updateFilter}
      name={offerFilter.key}
      label={offerFilter.title}
    />
  );

  return (
    <Container style={style}>
      <Grid.Container>
        <Grid.Row>
          <Loadable
            inline
            items={filters}
            loading={isLoading}
            renderItem={(filter) => renderOfferFilter(filter)}
            renderSkeleton={() => <Grid.Skeleton borderRadius={4} width={120} height={36}/>}
          />
          {!isLoading && (
            <Grid.Col>
              <ClearFilterButton onClick={resetFilter}>
                <ClearFiltersIcon src={Images.CLEAR}/>
                <ClearFilterButtonLabel>Išvalyti filtrus</ClearFilterButtonLabel>
              </ClearFilterButton>
            </Grid.Col>
          )}
        </Grid.Row>
      </Grid.Container>
    </Container>
  );
}

Filter.propTypes = {
  style: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(SharedTypes.FilterType).isRequired,
};

Filter.defaultProps = {
  style: {},
};

function mapStateToProps(state) {
  const {filters} = state;
  const {isLoading} = filters;
  return {filters: filters.filters, isLoading};
}

export default connect(mapStateToProps)(Filter);
