import React from 'react';
import {Container} from "./styles";
import {Grid} from "styles";
import Trips from "./Trips";
import Messages from "./Messages";

function ProfilePage() {
  return (
    <Container>
      <Grid.Row mb={50}>
        <Trips/>
      </Grid.Row>
      <Grid.Row mb={50}>
        <Messages/>
      </Grid.Row>
    </Container>
  );
}

export default ProfilePage;
