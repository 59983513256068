import React from 'react';
import {Images, Grid, Form} from "styles";
import PropTypes from "prop-types";
import {Container, Help, Icon, Title, EmptyContainer, Content, ImageContainer, Image, CloseIcon, FileInput, LoadMoreContainer} from "./styles";

function ImagesUpload(props) {
  const {dirty, label, error, onChange, name, value} = props;

  const handleImageChange = (event) => {
    const {target} = event;
    const {files} = target;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => onChange({name, value: [...value, fileReader.result]});
  };

  const handleImageRemove = (index) => {
    const newImages = value;
    newImages.splice(index, 1);
    onChange({name, value: newImages});
  };

  const renderEmpty = () => (
    <EmptyContainer error={dirty && error} justifyContent="center" alignItems="center" fullWidth>
      <Grid.Col mr={15}>
        <Icon src={Images.ADD}/>
      </Grid.Col>
      <Grid.Col>
        <Grid.Row>
          <Title>Nutempkite čia .jpg arba .png nuotraukas arba pasirinkite į kompiuterio</Title>
        </Grid.Row>
        <Grid.Row>
          <Help>Iki 20 nuotraukų, maksimaliai 2 MB per vieną.</Help>
        </Grid.Row>
      </Grid.Col>
      <FileInput type="file" onChange={handleImageChange}/>
    </EmptyContainer>
  );

  const renderAddMore = () => (
    <LoadMoreContainer justifyContent="center" alignItems="center">
      <Grid.Row mb={10}>
        <Icon src={Images.ADD}/>
      </Grid.Row>
      <Grid.Row>
        <Title>Įkelti daugiau</Title>
      </Grid.Row>
      <FileInput type="file" onChange={handleImageChange}/>
    </LoadMoreContainer>
  );

  const renderImage = (image, index) => (
    <ImageContainer key={index}>
      <Image src={image}/>
      <CloseIcon onClick={() => handleImageRemove(index)} src={Images.REMOVE}/>
    </ImageContainer>
  );

  return (
    <Container>
      {label && <Form.Label>{label}</Form.Label>}
      <Content>
        {value.length === 0 && renderEmpty()}
        {value.length > 0 && (
          <Grid.Grid rowGap={10} columnGap={10}>
            {value.map((image, index) => renderImage(image, index))}
            {renderAddMore()}
          </Grid.Grid>
        )}
      </Content>
      {dirty && error && <Form.Error>{error}</Form.Error>}
    </Container>
  );
}

ImagesUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  dirty: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
};

ImagesUpload.defaultProps = {
  error: null,
  value: [],
  dirty: false,
  label: null,
};

export default ImagesUpload;
