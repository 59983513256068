import React from "react";
import autoBind from "auto-bind";
import PropTypes from 'prop-types';
import {Validations} from "utils";
import {Grid} from 'styles';
import {Container} from './styles';
import Form from "../Form";
import {AuthenticationButton, Input} from "components";

class LoginForm extends Form {
  constructor(props) {
    super(props);

    this.validations = {
      email: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
    };

    this.state = {
      fields: {
        email: {value: '', error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  submit() {
    const {fields} = this.state;
    const {onSubmit} = this.props;

    if (this.validateForm()) {
      onSubmit({
        email: fields.email.value,
      });
    }
  }

  render() {
    const {fields} = this.state;

    return (
      <Container onSubmit={(event) => event.preventDefault()}>
        <Grid.Row mb={20}>
          <Input
            label="El. paštas"
            placeholder="Įveskite el. paštą"
            onChange={this.handleChange}
            value={fields.email.value}
            dirty={fields.email.dirty}
            error={fields.email.error}
            name="email"
            onBlur={this.handleBlur}
          />
        </Grid.Row>
        <Grid.Row>
          <AuthenticationButton
            type="submit"
            onClick={this.submit}
            label="Priminti"
          />
        </Grid.Row>
      </Container>
    );
  }
}

LoginForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
