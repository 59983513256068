import axios from 'axios';
import {store} from "store";
import {ApiUtils} from "utils";

const apiAxiosInstance = axios.create({
  baseURL: 'https://bt.baltictours.lt/api',
});

apiAxiosInstance.interceptors.request.use(
  async request => {
    const token = store.getState().authentication.token;
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
);

apiAxiosInstance.interceptors.response.use(async response => {
  await ApiUtils.sleep();
  return response.data;
});

export default apiAxiosInstance;
