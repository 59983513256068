import styled from 'styled-components/macro';
import { Colors, Media } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
  width: 100%;
  max-width: 768px;
  position: relative;
  border: solid 1px ${Colors.NEWSLETTER_BACKGROUND};
  box-shadow: 0 1px 5px 0 ${hexToRgba(Colors.BLACK, 0.1)};
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  display: flex;
  height: 266px;
  align-items: stretch;
  overflow: hidden;

  @media only screen and (max-width: ${Media.TABLET}px) {
    flex-direction: column;
    align-items: center;
    height: 500px;
  }
`;

export const ImagesContainer = styled.div`
  width: 477px;
  height: 100%;
  background-color: ${Colors.NEWSLETTER_BACKGROUND};

  @media only screen and (max-width: ${Media.TABLET}px) {
    height: 250px;
  }

  @media only screen and (max-width: ${Media.MOBILE}px) {
    width: 370px;
  }

  @media only screen and (max-width: ${Media.SMALL_MOBILE}px) {
    width: 330px;
  }
`;

export const Details = styled.div`
  padding: 25px;
  width: 284px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;
