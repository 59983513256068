import React from 'react';
import PropTypes from "prop-types";
import Collapse, {Panel} from "rc-collapse";
import {Container} from './styles';

function Collapsible(props) {
  const {children, renderHeader, width} = props;

  return (
    <Container width={width}>
      <Collapse className="plus-content">
        <Panel header={renderHeader()}>
          {children}
        </Panel>
      </Collapse>
    </Container>
  );
}

Collapsible.propTypes = {
  width: PropTypes.number,
  children: PropTypes.node.isRequired,
  renderHeader: PropTypes.func.isRequired,
};

Collapsible.defaultProps = {
  width: null,
};

export default Collapsible;
