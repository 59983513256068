import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

export const Link = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.LIGHT_PRIMARY};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Help = styled.span`
  font-size: 14px;
  color: ${Colors.DROPDOWN_ITEM};
`;
