import styled from 'styled-components/macro';
import { Colors, Media } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';

const getFontSize = ({ large, small }) => {
  if (large) {
    return 26;
  }

  if (small) {
    return 18;
  }

  return 24;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.reversed ? 'column-reverse' : 'column')};

  @media only screen and (max-width: ${Media.TABLET}px) {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 100%;
  min-height: 210px;
  border-radius: 8px;
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.reversed ? 0 : 20)}px;
  margin-top: ${(props) => (props.reversed ? 20 : 0)}px;

  @media only screen and (max-width: ${Media.TABLET}px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

export const Details = styled.div``;

export const TitleLink = styled(Link)`
  text-decoration: none;
`;

export const Title = styled.span`
  letter-spacing: -0.39px;
  margin-bottom: 10px;
  color: ${Colors.TITLE};
  font-size: ${(props) => getFontSize(props)}px;
  font-weight: 700;
  line-height: 26px;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: ${Media.TABLET}px) {
    font-size: 24px;
  }
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 15px;
  background-color: ${Colors.RATING};
  height: 30px;
  border-radius: 15px;
  padding: 0 20px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Badge = styled.span`
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${Colors.TITLE};
`;

export const Description = styled(LinesEllipsis)`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  line-height: 20px;
`;

export const Date = styled.span`
  color: ${Colors.TITLE};
  font-size: 12px;
`;
