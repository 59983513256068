import {MessagesTypes} from "types";
import {MessagesService} from "services";

export function getMessages(request) {
  return async dispatch => {
    try {
      dispatch({type: MessagesTypes.GET_MESSAGES_START});
      const {data, count} = await MessagesService.getMessages(request);
      dispatch({type: MessagesTypes.GET_MESSAGES, payload: {messages: data, count}});
    } catch (exception) {
      dispatch({type: MessagesTypes.GET_MESSAGES_ERROR});
    }
  };
}
