import styled from 'styled-components/macro';
import { Colors } from 'styles';

const getBackgroundColor = ({ outline, disabled }) => {
  if (disabled) {
    return Colors.FORM_BORDER;
  }

  if (outline) {
    return Colors.WHITE;
  }

  return Colors.PRIMARY;
};

const getBorderColor = ({ outline, disabled }) => {
  if (disabled) {
    return Colors.FORM_BORDER;
  }

  if (outline) {
    return Colors.PRIMARY;
  }

  return Colors.PRIMARY;
};

const getTextColor = ({ outline, disabled }) => {
  if (disabled) {
    return Colors.WHITE;
  }

  if (outline) {
    return Colors.PRIMARY;
  }

  return Colors.WHITE;
};

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Description = styled.span`
  font-size: 12px;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Button = styled.button`
  border-radius: 18px;
  background-color: ${(props) => getBackgroundColor(props)};
  border: 1px solid ${(props) => getBorderColor(props)};
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => getTextColor(props)};
`;

export const Value = styled.span`
  font-size: 14px;
  width: 55px;
  text-align: center;
  color: ${Colors.BLACK};
`;
