import styled from 'styled-components/macro';
import { Colors } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 15px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${Colors.BORDER};
  width: 100%;
`;

export const Label = styled.span`
  font-size: 14px;
  color: ${Colors.INCLUSIVE_TITLE};
`;

export const Value = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${Colors.INCLUSIVE_TITLE};
`;

export const Total = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${Colors.BLACK};
`;

export const HotelImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 6px;
  object-fit: cover;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${Colors.BLACK};
`;

export const Address = styled(LinesEllipsis)`
  font-size: 14px;
  color: ${Colors.INCLUSIVE_TITLE};
`;

export const Description = styled.span`
  font-size: 14px;
  color: ${Colors.INCLUSIVE_TITLE};
`;
