import React from 'react'
import PropTypes from "prop-types";

import {Container, TextInput} from "./styles";
import {Form} from "styles";

function Textarea(props) {
  const {style, placeholder, error, name, value, onBlur, onChange, label, dirty} = props;

  const handleOnChange = event => {
    const {target} = event;
    const {value} = target;
    onChange({name, value});
  };

  const handleOnBlur = () => {
    onBlur({name});
  };

  return (
    <Container style={style}>
      {label && <Form.Label>{label}</Form.Label>}
      <TextInput
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        error={error}/>
      {dirty && error && <Form.Error>{error}</Form.Error>}
    </Container>
  );
}

Textarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.string,
  dirty: PropTypes.bool,
  label: PropTypes.string,
};

Textarea.defaultProps = {
  style: {},
  error: null,
  value: '',
  placeholder: null,
  dirty: false,
  label: false,
};

export default Textarea;
