import styled from 'styled-components/macro';
import { Colors, Media } from 'styles';

export const Container = styled.form`
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  background-color: ${Colors.MENU_BACKGROUND};

  @media only screen and (max-width: ${Media.MOBILE}px) {
    padding: 30px 15px;
  }
`;

export const Title = styled.h4`
  color: ${Colors.TITLE};
  font-size: 24px;
  font-weight: 700;
`;

export const Help = styled.span`
  color: ${Colors.HELP};
  font-size: 12px;
`;

export const Link = styled.a`
  color: ${Colors.LIGHT_PRIMARY};
  font-size: 12px;
  margin-left: 5px;
`;
