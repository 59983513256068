import {useEffect, useState} from "react";
import {UrlUtils} from "utils";
import {useLocation} from 'react-router-dom';

const initialFilter = {
  adultsCount: 2,
  childrenCount: 0,
  infantsCount: 0,
  durationFrom: 2,
  durationTo: 7,
  departureId: 1,
  dateFrom: null,
  dateTo: null,
};

function useFilter() {
  const location = useLocation();
  const [filter, setFilter] = useState({...initialFilter, ...UrlUtils.queryParamsToObject(location.search)});

  useEffect(() => {
    setFilter({...initialFilter, ...UrlUtils.queryParamsToObject(location.search)});
  }, [location]);

  return filter;
}

export default useFilter;
