import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  padding: 55px 20px;
  border-radius: 8px;
  width: 100%;
  background-color: ${Colors.MENU_BACKGROUND};
`;

export const Icon = styled.img``;

export const Title = styled.h4`
  font-size: 24px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Description = styled.p`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
`;
