import update from 'immutability-helper';
import {FaqsTypes} from 'types';

const initialState = {
  faqs: [],
  categories: [],
  isLoading: false,
  isLoaded: false,
  selectedCategory: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case FaqsTypes.GET_FAQS_START: {
      return update(state, {
        isLoading: {$set: true},
        isLoaded: {$set: false},
      });
    }

    case FaqsTypes.GET_FAQS: {
      return update(state, {
        faqs: {$set: payload.faqs},
        categories: {$set: payload.categories},
        isLoading: {$set: false},
        isLoaded: {$set: true},
      });
    }

    case FaqsTypes.SET_SELECTED_CATEGORY: {
      return update(state, {
        selectedCategory: {$set: payload.selectedCategory},
      });
    }

    case FaqsTypes.GET_FAQS_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
