import styled from 'styled-components/macro';
import hexToRgba from 'hex-to-rgba';
import { Colors, Media, Grid } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';

export const Container = styled.div`
  width: 100%;
  max-width: 768px;
  position: relative;
  box-shadow: 0 1px 5px 0 ${hexToRgba(Colors.BLACK, 0.1)};
  border: solid 1px ${Colors.FORM_BORDER};
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  display: flex;
  height: 266px;
  align-items: stretch;
  overflow: hidden;
  transition: 0.3s;

  &:hover,
  &:active,
  &:focus {
    box-shadow: rgb(198, 207, 224) 0px 0px 5px;
  }

  @media only screen and (max-width: ${Media.TABLET}px) {
    flex-direction: column;
    max-width: 477px;
    height: 470px;
  }

  @media only screen and (max-width: ${Media.MOBILE}px) {
    max-width: 370px;
  }

  @media only screen and (max-width: ${Media.SMALL_MOBILE}px) {
    max-width: 330px;
  }
`;

export const Details = styled.div`
  padding: 15px 25px;
  display: flex;
  width: 284px;
  flex-shrink: 0;
  flex-direction: column;

  @media only screen and (max-width: ${Media.TABLET}px) {
    height: 220px;
    width: 100%;
  }
`;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.61;
  color: ${Colors.TITLE};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: ${Media.TABLET}px) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const Description = styled(LinesEllipsis)`
  font-size: 12px;
  color: ${Colors.TITLE};
`;

export const ImagesContainer = styled.div`
  width: 477px;
  height: 100%;

  @media only screen and (max-width: ${Media.TABLET}px) {
    width: unset;
    height: 250px;
  }
`;

export const CityContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: ${Colors.RATING};
  height: 30px;
  border-radius: 15px;
  padding: 0 20px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Country = styled.span`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.57px;
  color: ${Colors.TITLE};
`;

export const Footer = styled.div`
  border-top: 1px solid ${Colors.FORM_BORDER};
  padding-top: 12px;
`;

export const NightsDateCol = styled(Grid.Col)`
  @media only screen and (max-width: ${Media.TABLET}px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const DateText = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Nights = styled.span`
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: 700;
  color: ${Colors.TITLE};

  @media only screen and (max-width: ${Media.TABLET}px) {
    margin: 0 10px 0 0;
  }
`;

export const PriceRow = styled(Grid.Row)`
  @media only screen and (max-width: ${Media.TABLET}px) {
    && {
      flex-direction: row;
      align-items: center;

      div {
        margin: 0;
      }
      div:not(:first-child) {
        padding-left: 5px;
      }
    }
  }
`;

export const From = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const IconContainer = styled.div`
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img``;
