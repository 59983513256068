import React from "react";
import autoBind from "auto-bind";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {SubscribersActions} from "actions";
import {Validations} from "utils";
import {Button, Input} from "components";
import {Grid} from "styles";
import Form from "../Form";
import {Container, HelpLink, HelpText, Description, Title} from "./styles";

class NewsletterForm extends Form {
  constructor(props) {
    super(props);

    this.validations = {
      email: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
    };

    this.state = {
      fields: {
        email: {value: '', error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  submit() {
    const {fields} = this.state;
    const {addSubscriber} = this.props;

    if (this.validateForm()) {
      addSubscriber({
        email: fields.email.value,
      });
    }
  }

  render() {
    const {fields} = this.state;
    const {isLoading, side} = this.props;
    const className = side ? 'force-break' : null;

    return (
      <Container side={side} onSubmit={(event) => event.preventDefault()}>
        <Grid.Row className={className} alignItems="flex-start">
          <Grid.Col className={className} mr={20}>
            <Title>Kelionių idėjų ir pasiūlymų prenumerata</Title>
            {!side && (
              <Description>Nepraleisk geriausių kelionių pasiūlymų, naujienų ir patarimų</Description>
            )}
          </Grid.Col>
          <Grid.Col className={className} ml={50} flex={1}>
            <Grid.Row alignItems="flext-start" fullWidth className={className} mb={12}>
              <Grid.Col className={className} mr={20} flex={1}>
                <Input
                  placeholder="Įveskite el. pašto adresą"
                  onChange={this.handleChange}
                  name="email"
                  onBlur={this.handleBlur}
                  error={fields.email.error}
                  dirty={fields.email.dirty}
                  value={fields.email.value}
                />
              </Grid.Col>
              <Grid.Col className={className}>
                <Button
                  type="submit"
                  fullWidth
                  loading={isLoading}
                  onClick={this.submit}
                  label="Gauti pasiūlymus"
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <HelpText>
                Prenumeruodami naujienlaiškį sutinkate su Baltic Tours
                <HelpLink>Privatumo politika</HelpLink>
              </HelpText>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Container>
    );
  }
}

NewsletterForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  addSubscriber: PropTypes.func.isRequired,
  side: PropTypes.bool,
};

NewsletterForm.defaultProps = {
  side: false,
};

function mapStateToProps(state) {
  const {subscribers} = state;
  const {isLoading} = subscribers;
  return {isLoading};
}

const mapDispatchToProps = {
  addSubscriber: SubscribersActions.addSubscriber,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsletterForm);
