import tvsAxiosInstance from "../tvsAxiosInstance";

class ReviewsService {
  static async getReviews() {
    return await tvsAxiosInstance.get(`/reviews`);
  }

  static async addReview(request) {
    return await tvsAxiosInstance.post(`/review`, request);
  }
}

export default ReviewsService;
