import {Container, Content, HeaderItem} from "./styles";
import React from "react";

function Header() {
  return (
    <Container>
      <Content>
        <HeaderItem offset={-90} to="details" activeClass="active" spy={true} smooth={true}>Viešbutis</HeaderItem>
        <HeaderItem offset={-90} to="perks" activeClass="active" spy={true} smooth={true}>Privalumai</HeaderItem>
        <HeaderItem offset={-90} to="rooms" activeClass="active" spy={true} smooth={true}>Kambario tipas</HeaderItem>
        <HeaderItem offset={-90} to="travels" activeClass="active" spy={true} smooth={true}>Kelionės informacija</HeaderItem>
        <HeaderItem offset={-90} to="location" activeClass="active" spy={true} smooth={true}>Vieta</HeaderItem>
        <HeaderItem offset={-90} to="description" activeClass="active" spy={true} smooth={true}>Apie kurortą</HeaderItem>
      </Content>
    </Container>
  );
}

export default Header;
