import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {Container} from "./styles";

import MultiSelectContainer from "./MultiSelectContainer";
import MultiSelectOptions from "./MultiSelectOptions";
import {SharedTypes} from "utils";
import Dropdown from "../Dropdown";
import _ from "lodash";

function MultiSelect(props) {
  const {label, style, values, options, onChange, name} = props;
  const [localValues, setLocalValues] = useState(values);

  useEffect(() => {
    setLocalValues(values);
  }, [values]);

  const handleOnChange = (values) => {
    setLocalValues(values);
  };

  const handleOnBlur = () => {
    if (!_.isEqual(values, localValues)) {
      onChange({name, value: localValues});
    }
  };

  const renderButton = () => (
    <MultiSelectContainer
      values={localValues}
      label={label}
    />
  );

  const renderDropdown = () => (
    <MultiSelectOptions
      values={localValues}
      onChange={handleOnChange}
      options={options}
    />
  );

  return (
    <Container style={style}>
      <Dropdown
        onClose={handleOnBlur}
        fullWidth={false}
        renderButton={renderButton}
        renderDropdown={renderDropdown}
      />
    </Container>
  )
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
  style: {},
  disabled: false,
  values: [],
  label: null,
};

export default MultiSelect;
