import React from "react";
import autoBind from "auto-bind";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ContactsActions} from "actions";
import {Validations} from "utils";
import Form from "../Form";
import {Grid} from "styles";
import {Container, Title, Help, Link} from './styles';
import {Button, Input, Select, Textarea} from "components";
import {SUBJECTS} from "constant";

class ContactsForm extends Form {
  constructor(props) {
    super(props);

    this.validations = {
      name: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      email: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      subject: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      message: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
    };

    this.state = {
      fields: {
        name: {value: '', error: null, dirty: false},
        email: {value: '', error: null, dirty: false},
        subject: {value: '', error: null, dirty: false},
        message: {value: '', error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  submit() {
    const {fields} = this.state;
    const {sendMessage} = this.props;

    if (this.validateForm()) {
      sendMessage({
        name: fields.name.value,
        email: fields.email.value,
        subject: fields.subject.value,
        message: fields.message.value,
      });
    }
  }

  handleSelect(event, name) {
    const {value} = event;
    this.handleChange({value, name});
  }

  render() {
    const {fields} = this.state;
    const {isLoading} = this.props;

    return (
      <Container onSubmit={(event) => event.preventDefault()}>
        <Grid.Row mb={25}>
          <Title>Klausimai, pasiūlymai, nusiskundimai, nuomonės</Title>
        </Grid.Row>
        <Grid.Row alignItems="flex-start" mb={15}>
          <Grid.Col flex={1} mr={20}>
            <Input
              large
              error={fields.name.error}
              dirty={fields.name.dirty}
              value={fields.name.value}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              name="name"
              label="Vardas"
              placeholder="Įveskite vardą"
            />
          </Grid.Col>
          <Grid.Col flex={1}>
            <Input
              large
              error={fields.email.error}
              dirty={fields.email.dirty}
              value={fields.email.value}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              name="email"
              label="El. paštas"
              placeholder="Įveskite el. paštą"
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row mb={15}>
          <Select
            large
            options={SUBJECTS}
            error={fields.subject.error}
            dirty={fields.subject.dirty}
            value={fields.subject.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            name="subject"
            label="Tema"
            placeholder="Pasirinkite temą"
          />
        </Grid.Row>
        <Grid.Row mb={15}>
          <Textarea
            error={fields.message.error}
            dirty={fields.message.dirty}
            value={fields.message.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            name="message"
            label="Jūsų žinutė"
            placeholder="Žinutė…"
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Col mr={25}>
            <Button
              type="submit"
              loading={isLoading}
              label="Siųsti"
              onClick={this.submit}
            />
          </Grid.Col>
          <Grid.Col>
            <Help>
              Užpildydami užklausos formą sutinkate su Baltic Tours
              <Link>Privatumo politika</Link>
            </Help>
          </Grid.Col>
        </Grid.Row>
      </Container>
    );
  }
}

ContactsForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {contacts} = state;
  const {isLoading} = contacts;
  return {isLoading};
}

const mapDispatchToProps = {
  sendMessage: ContactsActions.sendMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactsForm);
