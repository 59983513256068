import update from 'immutability-helper';
import {ReviewTypes} from 'types';

const initialState = {
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ReviewTypes.ADD_REVIEW_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case ReviewTypes.ADD_REVIEW: {
      return update(state, {
        isLoading: {$set: false},
      });
    }

    case ReviewTypes.ADD_REVIEW_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
