import styled from 'styled-components/macro';
import * as Colors from './colors';

export const PageTitle = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  width: 100%;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  color: ${Colors.TITLE};
`;

export const AsideTitle = styled.h3`
  font-size: 28px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.DROPDOWN_ITEM};
  width: 100%;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const SecondaryDescription = styled.p`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  line-height: 17px;
`;
