import React, {useEffect} from 'react';
import {Container, Logo, LogoLink} from './styles';
import {useHistory} from 'react-router-dom';
import {Grid, Images} from "styles";
import {BackButton, SuccessMessage} from "components";
import {CheckoutActions} from "actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

function CheckoutSuccessPage(props) {
  const {reset} = props;
  const history = useHistory();

  useEffect(() => {
    reset();
  }, []);

  const navigate = () => {
    history.push(`/`);
  };

  return (
    <Container>
      <Grid.Container>
        <Grid.Row mb={30}>
          <LogoLink to="/">
            <Logo src={Images.LOGO}/>
          </LogoLink>
        </Grid.Row>
        <Grid.Row mb={25}>
          <SuccessMessage
            description="Apie tolimesnius žingsnius pranešime el. paštu ir Jūsų vartotojo paskyroje. Sekite informaciją"
            title="Mokėjimas atliktas sėkmingai"
          />
        </Grid.Row>
        <Grid.Row>
          <BackButton label="Atgal į pagrindinį" onClick={navigate}/>
        </Grid.Row>
      </Grid.Container>
    </Container>
  );
}

CheckoutSuccessPage.propTypes = {
  reset: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  reset: CheckoutActions.reset,
};

export default connect(null, mapDispatchToProps)(CheckoutSuccessPage);
