import {ReviewTypes} from "types";
import {ReviewsService} from "services";

export function addReview(request, callback = null) {
  return async dispatch => {
    try {
      dispatch({type: ReviewTypes.ADD_REVIEW_START});
      await ReviewsService.addReview(request);
      dispatch({type: ReviewTypes.ADD_REVIEW});
      if (callback) {
        callback();
      }
    } catch (exception) {
      dispatch({type: ReviewTypes.ADD_REVIEW_ERROR});
    }
  };
}
