import update from 'immutability-helper';
import {SubscribersTypes} from 'types';

const initialState = {
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SubscribersTypes.ADD_SUBSCRIBER_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case SubscribersTypes.ADD_SUBSCRIBER_ERROR: {
      return update(state, {
        isLoading: {$set: false},
      });
    }

    case SubscribersTypes.ADD_SUBSCRIBER: {
      return update(state, {
        isLoading: {$set: false},
      });
    }

    default:
      return state
  }
}
