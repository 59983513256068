import React from 'react';
import PropTypes from "prop-types";
import {Container, LinkContainer, LinkIcon, LinkLabel} from './styles';
import {Images} from "styles";
import {AuthenticationActions} from "actions";
import {connect} from "react-redux";

const LINKS = [
  {to: '/profilis', icon: Images.PROFILE, label: 'Mano profilis'},
  {to: '/profilis/duomenys', icon: Images.SETTINGS, label: 'Mano duomenys'},
  {to: '/profilis/keliones', icon: Images.TRIPS, label: 'Kelionės'},
  {to: '/profilis/zinutes', icon: Images.MESSAGES, label: 'Žinutės'}
];

function ProfileNavigation(props) {
  const {style, dropdown, logout} = props;

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  const renderLink = (link) => (
    <LinkContainer
      key={link.to}
      to={link.to}
      exact
      activeClassName="active"
      className={dropdown ? "dropdown-item" : null}
    >
      {!dropdown && <LinkIcon src={link.icon}/>}
      <LinkLabel dropdown={dropdown}>{link.label}</LinkLabel>
    </LinkContainer>
  );

  const renderLogout = () => (
    <LinkContainer
      to="/logout"
      onClick={handleLogout}
      className={dropdown ? "dropdown-item" : null}
    >
      <LinkLabel dropdown={dropdown}>Atsijungti</LinkLabel>
    </LinkContainer>
  );

  return (
    <Container dropdown={dropdown} style={style}>
      {LINKS.map(link => renderLink(link))}
      {dropdown && renderLogout()}
    </Container>
  );
}

ProfileNavigation.propTypes = {
  style: PropTypes.object,
  dropdown: PropTypes.bool,
  logout: PropTypes.func.isRequired,
};

ProfileNavigation.defaultProps = {
  style: {},
  dropdown: false,
};

const mapDispatchToProps = {
  logout: AuthenticationActions.logout,
};

export default connect(null, mapDispatchToProps)(ProfileNavigation);
