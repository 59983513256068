import apiAxiosInstance from "../apiAxiosInstance";
import {INCLUSIVE, LOCATION, PRICES, TRAVEL_DETAILS} from "./mocks";
import moment from "moment";

class OffersService {
  static async getOffers(request) {
    const params = {...request.paging, ...request.filter};

    if (!params.dateFrom || !params.dateTo) {
      params.dateFrom = moment().format("YYYY-MM-DD");
      params.dateTo = moment().add(6, 'months').format("YYYY-MM-DD");
    }

    return await apiAxiosInstance.get(`/offers`, {params});
  }

  static async getOffer(id) {
    const response = await apiAxiosInstance.get(`/offer/${id}`);
    return {
      data: {
        ...response.data,
        location: LOCATION,
        travels: TRAVEL_DETAILS,
        adultsCount: 2,
        childrenCount: 2,
        infantsCount: 0,
        nightsCount: 7,
        pricing: PRICES,
        inclusive: INCLUSIVE,
      }
    }
  }
}

export default OffersService;
