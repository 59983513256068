import React from 'react';
import PropTypes from "prop-types";
import {Container} from './styles';
import {SharedTypes} from "utils";
import {Grid} from "styles";
import TripsListItem from "../TripsListItem";

function TripsList(props) {
  const {style, trips} = props;

  return (
    <Container style={style}>
      {trips.map((trip, index) => (
        <Grid.Row mb={trips.length - 1 === index ? 0 : 20} key={trip.id}>
          <TripsListItem onClick={() => {}} trip={trip}/>
        </Grid.Row>
      ))}
    </Container>
  );
}

TripsList.propTypes = {
  style: PropTypes.object,
  trips: PropTypes.arrayOf(SharedTypes.TripListItemType).isRequired,
  loading: PropTypes.bool,
};

TripsList.defaultProps = {
  style: {},
  loading: false,
};

export default TripsList;
