import {Container, Label, Value} from "./styles";
import React from "react";
import {Grid} from "styles";
import {SharedTypes} from "utils";
import {connect} from "react-redux";

function Additional(props) {
  const {additional} = props;

  const renderAdditional = (title, priceForm) => (
    <Grid.Row mb={15}>
      <Grid.Col flex={1}>
        <Label>{title}</Label>
      </Grid.Col>
      <Grid.Col>
        <Value>{`Nuo € ${priceForm.toFixed(2)}`}</Value>
      </Grid.Col>
    </Grid.Row>
  );

  return (
    <Container>
      {additional.insurance && renderAdditional('Kelionių draudimas', 15.99)}
      {additional.seatSelection && renderAdditional('Vietos lėktuve pasirinkimas', 16.99)}
      {additional.transportation && renderAdditional('Pervežimas iš oro uosto', 17.99)}
      {additional.carParkSpot && renderAdditional('Parkavimas oro uoste', 18.99)}
    </Container>
  );
}

Additional.propTypes = {
  additional: SharedTypes.CheckoutAdditionalType.isRequired
};

const mapStateToProps = (state) => {
  const {checkout} = state;
  const {additional} = checkout;
  return {additional};
};

export default connect(mapStateToProps)(Additional);
