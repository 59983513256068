import styled from 'styled-components/macro';
import { Colors, Images } from 'styles';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 70px;
  background: url(${Images.WAVE}) no-repeat center 30%;
`;

export const Item = styled.div``;

export const Icon = styled.img``;

export const Title = styled.h4`
  font-size: 32px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Description = styled.p`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
`;
