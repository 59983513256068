import React from 'react';
import { Container } from './styles';
import { Badge, Button, Loadable, OffersListItem, OffersListItemSkeleton } from 'components';
import { SharedTypes } from 'utils';
import { Grid } from 'styles';
import { useOffers } from 'hooks';

function Offers(props) {
  const { filter } = props;
  const { canLoadMore, loadMore, offers, isLoading, count, isLoadingMore } = useOffers({ filter });

  const renderButtonBadge = () => <Badge selected disabled title={`${offers.length}/${count}`} />;

  return (
    <Container>
      <Loadable
        items={offers}
        loading={isLoading}
        renderItem={(offer) => <OffersListItem offer={offer} />}
        renderSkeleton={() => <OffersListItemSkeleton />}
      />
      {canLoadMore && (
        <Grid.Row mt={50} justifyContent="center">
          <Button
            loading={isLoadingMore}
            renderBadge={renderButtonBadge}
            large
            label="Daugiau pasiūlymų"
            onClick={loadMore}
          />
        </Grid.Row>
      )}
    </Container>
  );
}

Offers.propTypes = {
  filter: SharedTypes.OffersFilterType,
};

Offers.defaultProps = {
  filter: null,
};

export default Offers;
