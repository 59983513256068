import {EmployeesTypes} from "types";
import {EmployeesService} from "services";

export function getEmployees() {
  return async dispatch => {
    try {
      dispatch({type: EmployeesTypes.GET_EMPLOYEES_START});
      const employees = await EmployeesService.getEmployees();
      dispatch({type: EmployeesTypes.GET_EMPLOYEES, payload: {employees}});
    } catch (exception) {
      dispatch({type: EmployeesTypes.GET_EMPLOYEES_ERROR});
    }
  };
}
