import React from 'react';
import PropTypes from 'prop-types';
import { DateUtils, ImageUtils, Pluralisation, SharedTypes } from 'utils';
import { Grid, Images } from 'styles';
import PhotosHorizontalSlider from '../PhotosHorizontalSlider';
import RatingInput from '../RatingInput';
import Price from '../Price';
import {
  Container,
  Details,
  Title,
  Description,
  ImagesContainer,
  CityContainer,
  Country,
  Footer,
  DateText,
  From,
  Icon,
  Nights,
  IconContainer,
  NightsDateCol,
  PriceRow,
} from './styles';
import { useDestination } from 'hooks';

function OffersListItem(props) {
  const { style, offer } = props;
  const { destination } = useDestination(offer.destinationId);

  const handleClick = () => {
    window.open(`/pasiulymai/${offer.id}`);
  };

  return (
    <Container style={style}>
      {destination && (
        <CityContainer>
          <Country>{destination.title}</Country>
        </CityContainer>
      )}
      <ImagesContainer>
        <PhotosHorizontalSlider images={ImageUtils.getOfferImages(offer.images)} />
      </ImagesContainer>
      <Details>
        <Grid.Row mb={15}>
          <RatingInput readonly value={offer.hotelClass} />
        </Grid.Row>
        <Grid.Row mb={5}>
          <Title onClick={handleClick}>{offer.title}</Title>
        </Grid.Row>
        <Grid.Row alignItems="flex-start" flex={1}>
          <Description maxLine={2} text={offer.summary || ''} />
        </Grid.Row>
        <Footer>
          <Grid.Row alignItems="flex-end">
            <NightsDateCol flex={1}>
              <Nights>{Pluralisation.nightsText(offer.nightsCount)}</Nights>
              <DateText>{DateUtils.formatDate(offer.departureDatetime)}</DateText>
            </NightsDateCol>
            <Grid.Col>
              <PriceRow alignItems="flex-end">
                <Grid.Col mr={5}>
                  <From>Nuo</From>
                </Grid.Col>
                <Grid.Col mr={10}>
                  <Price value={offer.price} />
                </Grid.Col>
                <Grid.Col>
                  <IconContainer>
                    <Icon src={Images.ARROW_RIGHT} />
                  </IconContainer>
                </Grid.Col>
              </PriceRow>
            </Grid.Col>
          </Grid.Row>
        </Footer>
      </Details>
    </Container>
  );
}

OffersListItem.propTypes = {
  style: PropTypes.object,
  offer: SharedTypes.OffersListItemType.isRequired,
};

OffersListItem.defaultProps = {
  style: {},
};

export default OffersListItem;
