import React, {useRef, useState} from 'react';
import {Container, Title} from './styles';
import {Grid} from 'styles';
import {BackButton, Button, GridContainer, WarningMessage} from "components";
import PropTypes from "prop-types";
import {SharedTypes} from "utils";
import {CheckoutActions} from "actions";
import {connect} from "react-redux";
import {CheckoutPassengerForm} from "containers";
import {useHistory, useParams} from 'react-router-dom';

function CheckoutPassengersPage(props) {
  const {passengers, setPassengers} = props;
  const [localPassengers, setLocalPassengers] = useState(passengers);
  const formsRef = useRef([]);
  const history = useHistory();
  const {id, roomId} = useParams();

  const onSubmit = () => {
    if (validate()) {
      setPassengers({passengers: localPassengers});
      history.push(`/atsiskaitymas/${id}/${roomId}/apmokejimas`);
    }
  };

  const validate = () => {
    let valid = true;
    for (let ref of formsRef.current) {
      const isValid = ref.validateForm();
      if (!isValid) {
        valid = false;
      }
    }

    return valid;
  };

  const handleChange = (index, formData) => {
    const newLocalPassengers = localPassengers;
    newLocalPassengers[index] = {...localPassengers[index], ...formData};
    setLocalPassengers(newLocalPassengers);
  };

  return (
    <Container>
      <Grid.Row mb={25}>
        <Title>Keleiviai</Title>
      </Grid.Row>
      <Grid.Row mb={20}>
        <WarningMessage
          title="Dėmesio! Tiksliai užpildykite keliautojų duomenis."
          description="Vardus ir pavardes rašykite lotyniškomis raidėmis, be lietuvybių."
        />
      </Grid.Row>
      <Grid.Row mb={30}>
        <GridContainer itemsCount={1} rowGap={15}>
          {localPassengers.map((passenger, index) => (
            <CheckoutPassengerForm
              ref={ref => formsRef.current[index] = ref}
              key={index}
              onChange={handleChange}
              index={index}
              formData={passenger}
            />
          ))}
        </GridContainer>
      </Grid.Row>
      <Grid.Row justifyContent="space-between">
        <BackButton onClick={history.goBack}/>
        <Button large label="Tęsti" onClick={onSubmit}/>
      </Grid.Row>
    </Container>
  );
}

CheckoutPassengersPage.propTypes = {
  setPassengers: PropTypes.func.isRequired,
  passengers: PropTypes.arrayOf(SharedTypes.CheckoutPassengerType).isRequired
};

const mapStateToProps = (state) => {
  const {checkout} = state;
  const {passengers} = checkout;
  return {passengers};
};

const mapDispatchToProps = {
  setPassengers: CheckoutActions.setPassengers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPassengersPage);
