import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import AppNavigation from "./AppNavigation";
import {ScrollToTop} from "components";
import {store} from "store";
import {
  ArticlesActions,
  CategoriesActions,
  DestinationsActions, EmployeesActions,
  FaqsActions,
  FiltersActions,
  ReviewsActions
} from "actions";

function App() {

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    store.dispatch(CategoriesActions.getCategories());
    store.dispatch(DestinationsActions.getDestinations());
    store.dispatch(ArticlesActions.getArticles());
    store.dispatch(ReviewsActions.getReviews());
    store.dispatch(FiltersActions.getFilters());
    store.dispatch(FaqsActions.getFaqs());
    store.dispatch(EmployeesActions.getEmployees());
  };

  return (
    <BrowserRouter>
      <ScrollToTop>
        <AppNavigation/>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
