import {Container} from "./styles";
import {Grid} from "styles";
import PropTypes from "prop-types";
import {SectionTitle} from "components";
import React from "react";

function SectionLayout(props) {
  const {children, loadingText, loading, noResultsText, title, description, hasResults, center} = props;

  return (
    <Container>
      <Grid.Row mb={50}>
        <SectionTitle
          center={center}
          title={loading ? loadingText : hasResults ? title : noResultsText}
          description={description}
        />
      </Grid.Row>
      {children}
    </Container>
  );
}

SectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  center: PropTypes.bool,
  hasResults: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  loadingText: PropTypes.string,
  noResultsText: PropTypes.string,
};

SectionLayout.defaultProps = {
  description: null,
  title: null,
  hasResults: true,
  loading: false,
  center: false,
  loadingText: null,
  noResultsText: null,
};

export default SectionLayout;
