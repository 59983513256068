import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  padding: 70px 0;
  background-color: ${Colors.MENU_BACKGROUND};
`;

export const Description = styled.div`
  max-width: 860px;
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  letter-spacing: -0.26px;
  line-height: 24px;
  text-align: center;
`;

export const Image = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImageContainer = styled.div`
  width: calc(20% - 70px);
  height: 62px;
  margin: 0 35px 80px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  padding-top: 70px;
  flex-wrap: wrap;
  display: flex;
`;
