import styled from 'styled-components/macro';
import { Colors, Images, Media } from 'styles';

export const Container = styled.div`
  padding-bottom: 70px;
`;

export const VideoContainer = styled.div`
  height: 520px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @media only screen and (max-width: ${Media.MOBILE}px) {
    height: 200px;
  }

  &:hover {
    cursor: pointer;

    > span {
      text-decoration: underline;
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: url(${Images.TEAM}) no-repeat center/cover;
    left: 0;
    top: 0;
    z-index: -2;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: ${Colors.LIGHT_PRIMARY};
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0.75;
    z-index: -1;
  }
`;

export const Image = styled.img`
  margin-bottom: 20px;

  @media only screen and (max-width: ${Media.MOBILE}px) {
    height: 55px;
    width: 55px;
    margin-bottom: 10px;
  }
`;

export const Label = styled.span`
  color: ${Colors.WHITE};
  font-size: 13px;
  font-weight: 700;
`;

export const Details = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Frame = styled.iframe``;
