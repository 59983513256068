const EMPLOYEES = [
  {
    id: 1,
    fullName: 'Sigitas',
    image: 'https://baltic.webstrum.com/uploads/5dfb89f3509f4.png',
    description: 'Kai reikalai būna rimti',
    email: 'sigitas@baltictours.lt'
  },
  {
    id: 2,
    fullName: 'Andrius Paulionis',
    image: 'https://baltic.webstrum.com/uploads/5dfa130f1bcf9.png',
    description: 'Kelionių ekspertas, poilsinių kelionių pardavimo vadovas',
    email: 'andrius@baltictours.lt'
  },
  {
    id: 3,
    fullName: 'Ramunė Krivkaitė',
    image: 'https://baltic.webstrum.com/uploads/5dfa2734d99bd.png',
    description: 'Kai norisi net ir egzotiško poilsio',
    email: 'ramune@baltictours.lt'
  },
  {
    id: 4,
    fullName: 'Ilona',
    image: 'https://baltic.webstrum.com/uploads/5dfb1b022d0e7.jpg',
    description: 'Kai reikia pagalbos su reguliariais skrydžiais',
    email: 'lona@baltictours.lt'
  },
  {
    id: 5,
    fullName: 'Aleksandra',
    image: 'https://baltic.webstrum.com/uploads/5dfb1beeea658.jpg',
    description: 'Kai reikia pagalbos su reguliariais skrydžiais',
    email: 'aleksandra@baltictours.lt'
  },
  {
    id: 6,
    fullName: 'Neiminta',
    image: 'https://baltic.webstrum.com/uploads/5dfb1d1765e29.jpg',
    description: 'Kai reikia pagalbos su reguliariais skrydžiais',
    email: 'neiminta@baltictours.lt'
  },
  {
    id: 7,
    fullName: 'Mariuš',
    image: 'https://baltic.webstrum.com/uploads/5dfb1e03909d5.jpg',
    description: 'Kai reikia pagalbos su reguliariais skrydžiais',
    email: 'marius@baltictours.lt'
  },
  {
    id: 8,
    fullName: 'Rasa',
    image: 'https://baltic.webstrum.com/uploads/5dfb252b870fc.jpg',
    description: 'Kai reikia pagalbos su reguliariais skrydžiais',
    email: 'rasa.cibulskaite@baltictours.lt'
  },
  {
    id: 9,
    fullName: 'Sandra',
    image: 'https://baltic.webstrum.com/uploads/5dfb25a673717.png',
    description: 'Kai ieškai pigių skrydžių',
    email: 'sandra@baltictours.lt'
  },
  {
    id: 10,
    fullName: 'Tomas',
    image: 'https://baltic.webstrum.com/uploads/5dfb2e118be91.png',
    description: 'Kai ieškai pigių skrydžių\n',
    email: 'tomas@baltictours.lt'
  },
  {
    id: 11,
    fullName: 'Sigita',
    image: 'https://baltic.webstrum.com/uploads/5dfb2fe98b941.jpg',
    description: 'Kai ieškai pigių skrydžių',
    email: 'sigita@baltictours.lt'
  },
  {
    id: 12,
    fullName: 'Kristina',
    image: 'https://baltic.webstrum.com/uploads/5dfb314b58657.png',
    description: 'Kai ieškai pigių skrydžių',
    email: 'kristina@baltictours.lt'
  },
  {
    id: 13,
    fullName: 'Danielė',
    image: 'https://baltic.webstrum.com/uploads/5dfb319f9a734.jpg',
    description: 'Kai norisi pasikalbėti apie administracinius reikalus',
    email: 'daniele@baltictours.lt'
  },
  {
    id: 14,
    fullName: 'Greta',
    image: 'https://baltic.webstrum.com/uploads/5dfb336b32299.png',
    description: 'Kai turi pasiūlymų dėl IT',
    email: 'greta@baltictours.lt'
  },
  {
    id: 15,
    fullName: 'Tautvydas',
    image: 'https://baltic.webstrum.com/uploads/5dfb844d025d8.png',
    description: 'Kai norisi rimtos diskusijos apie IT',
    email: 'tautvydas@baltictours.lt'
  },
  {
    id: 16,
    fullName: 'Norbertas',
    image: 'https://baltic.webstrum.com/uploads/5dfb84f3e912c.jpg',
    description: 'Kai reikia pasikalbėti apie kūrybą ir marketingą',
    email: 'norbertas@baltictours.lt'
  },
  {
    id: 17,
    fullName: 'Iveta',
    image: 'https://baltic.webstrum.com/uploads/5dfb86092e796.jpg',
    description: 'Kai domina atvykstamasis turizmas ir sporto renginiai',
    email: 'iveta@baltictours.lt'
  },
  {
    id: 18,
    fullName: 'Ilona',
    image: 'https://baltic.webstrum.com/uploads/5dfb8672366c8.jpg',
    description: 'Kai domiesi soc. tinklų subtilybėmis',
    email: 'ilona.morozaite@baltictours.lt'
  },
  {
    id: 19,
    fullName: 'Rugilė',
    image: 'https://baltic.webstrum.com/uploads/5dfb878c61afe.jpg',
    description: 'Kai norisi pasikalbėti apie skaitmeninę komunikaciją',
    email: 'rugile@baltictours.lt'
  },
  {
    id: 20,
    fullName: 'Danuta',
    image: 'https://baltic.webstrum.com/uploads/5dfb87f465886.png',
    description: 'Kai reikia buhalterinio atsakymo',
    email: 'danuta@baltictours.lt'
  },
  {
    id: 21,
    fullName: 'Ala',
    image: 'https://baltic.webstrum.com/uploads/5dfb8852d6422.jpg',
    description: 'Kai reikia buhalterinio atsakymo',
    email: 'ala@baltictours.lt'
  },
  {
    id: 22,
    fullName: 'Rūta',
    image: 'https://baltic.webstrum.com/uploads/5dfb889e18f6f.png',
    description: 'Kai reikia buhalterinio atsakymo',
    email: 'ruta@baltictours.lt'
  },
  {
    id: 23,
    fullName: 'Justina',
    image: 'https://baltic.webstrum.com/uploads/5dfb88e35bb0f.png',
    description: 'Kai domina finansiniai klausimai',
    email: 'justina.zenovaite@baltictours.lt'
  },
  {
    id: 24,
    fullName: 'Rasa',
    image: 'https://baltic.webstrum.com/uploads/5dfb8a2b0b9c5.jpg',
    description: 'Kai norisi pasikalbėti apie rimtus projektus',
    email: 'rasa@baltictours.lt'
  },
  {
    id: 25,
    fullName: 'Stefanija',
    image: 'https://baltic.webstrum.com/uploads/5dfb8a9f4b024.png',
    description: 'Kai norisi išgirsti Baltic Tours istoriją',
    email: 'stefanija@baltictours.lt'
  },
];

export default EMPLOYEES;
