import {OffersTypes} from "types";
import {OffersService} from "services";

export function getOffers(request) {
  return async dispatch => {
    try {
      dispatch({type: OffersTypes.GET_OFFERS_START});
      const {data, count} = await OffersService.getOffers(request);
      dispatch({type: OffersTypes.GET_OFFERS, payload: {offers: data, count}});
    } catch (exception) {
      dispatch({type: OffersTypes.GET_OFFERS_ERROR});
    }
  };
}

export function getMoreOffers(request) {
  return async dispatch => {
    try {
      dispatch({type: OffersTypes.GET_MORE_OFFERS_START});
      const {data, count} = await OffersService.getOffers(request);
      dispatch({type: OffersTypes.GET_MORE_OFFERS, payload: {offers: data, count}});
    } catch (exception) {
      dispatch({type: OffersTypes.GET_MORE_OFFERS_ERROR});
    }
  };
}
