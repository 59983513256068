import update from 'immutability-helper';
import {DatesTypes} from 'types';

const initialState = {
  dates: [],
  isLoaded: false,
  isLoading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case DatesTypes.GET_DATES_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case DatesTypes.GET_DATES: {
      return update(state, {
        dates: {$set: payload.dates},
        isLoaded: {$set: true},
        isLoading: {$set: false},
      });
    }

    case DatesTypes.GET_DATES_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
