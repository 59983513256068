import update from 'immutability-helper';
import {CategoriesTypes} from 'types';

const initialState = {
  categories: [],
  count: 0,
  isLoaded: false,
  isLoading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case CategoriesTypes.GET_CATEGORIES_START: {
      return update(state, {
        isLoading: {$set: true},
        isLoaded: {$set: false},
      });
    }

    case CategoriesTypes.GET_CATEGORIES: {
      return update(state, {
        categories: {$set: payload.categories},
        count: {$set: payload.count},
        isLoaded: {$set: true},
        isLoading: {$set: false},
      });
    }

    case CategoriesTypes.GET_CATEGORIES_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
