import styled from 'styled-components/macro';
import * as Colors from './colors';
import * as Grid from './grid';
import hexToRgba from 'hex-to-rgba';

export const Container = styled(Grid.Row)`
  height: ${(props) => (props.large ? 54 : 42)}px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => (props.error ? Colors.DANGER : Colors.FORM_BORDER)};
  background-color: ${Colors.WHITE};
  padding: 0 20px;
  position: relative;
  transition: 0.3s;

  &:focus,
  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 6px ${hexToRgba(Colors.LIGHT_PRIMARY, 0.7)};
    border: 1px solid ${Colors.LIGHT_PRIMARY};
  }

  @media only screen and (max-width: 1110px) {
    height: 42px;
  }
`;

export const Value = styled.span`
  font-size: ${(props) => (props.large ? 14 : 13)}px;
  color: ${Colors.BLACK};
`;

export const Placeholder = styled.span`
  font-size: ${(props) => (props.large ? 14 : 13)}px;
  color: ${Colors.PLACEHOLDER};
`;

export const ValueContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
`;

export const Label = styled.label`
  font-size: 12px;
  display: block;
  font-weight: 600;
  color: ${Colors.DROPDOWN_ITEM};
  margin-bottom: 7px;
  padding-left: 2px;
  text-transform: uppercase;
`;

export const Error = styled.span`
  font-size: 13px;
  line-height: 1.33;
  display: block;
  color: ${Colors.DANGER};
  margin-top: 5px;
`;

export const Icon = styled.img``;
