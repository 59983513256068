import {FiltersTypes} from "types";
import {FiltersService} from "services";

export function getFilters() {
  return async dispatch => {
    try {
      dispatch({type: FiltersTypes.GET_FILTERS_START});
      const filters = await FiltersService.getFilters();
      dispatch({type: FiltersTypes.GET_FILTERS, payload: {filters}});
    } catch (exception) {
      dispatch({type: FiltersTypes.GET_FILTERS_ERROR});
    }
  };
}
