import styled from 'styled-components';

const Container = styled.span`
    display: inline-block;
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
`;

const DecimalPart = styled.sup`
    vertical-align: super;
    font-size: 65%;
`;

export {Container, DecimalPart};
