import React from 'react';
import {Route, Switch, useHistory, withRouter} from 'react-router-dom';
import {AuthenticationRoute, Filter, Footer, Header, ProtectedRoute, SearchForm} from "containers";
import {NavigationUtils} from "utils";
import ProfileNavigation from "./ProfileNavigation";
import CheckoutNavigation from "./CheckoutNavigation";
import {CookiesPopup} from "components";
import {
  AboutUsPage,
  BlogInnerPage,
  BlogPage,
  CategoryPage,
  CheckoutSuccessPage,
  ContactsPage,
  CountriesPage,
  DestinationPage,
  FaqsPage,
  ForgotPasswordPage,
  HomePage,
  LastMinuteOffersPage,
  LoginPage,
  OfferPage,
  RegistrationPage,
  ReviewsPage,
  SpecialOffersPage,
  TvsPage
} from "pages";

function AppNavigation() {
  let history = useHistory();
  const {location} = history;
  const {pathname} = location;

  const showHeader = NavigationUtils.isHeaderVisible(pathname);
  const showFooter = NavigationUtils.isFooterVisible(pathname);
  const showFilter = NavigationUtils.isFilterVisible(pathname);
  const showSearch = NavigationUtils.isSearchVisible(pathname);
  const isTransparentHeader = pathname === '/';

  return (
    <React.Fragment>
      {showHeader && <Header transparent={isTransparentHeader}/>}
      {showSearch && <SearchForm/>}
      {showFilter && <Filter/>}
      <Switch>
        <Route exact path='/' component={HomePage}/>

        <AuthenticationRoute exact path='/prisijungimas' component={LoginPage}/>
        <AuthenticationRoute exact path='/registracija' component={RegistrationPage}/>
        <AuthenticationRoute exact path='/slaptazodzio-priminimas' component={ForgotPasswordPage}/>

        <Route exact path='/kategorijos' component={CategoryPage}/>
        <Route exact path='/kategorijos/:id' component={CategoryPage}/>
        <Route exact path='/salys' component={CountriesPage}/>
        <Route exact path='/pasiulymai' component={DestinationPage}/>
        <Route exact path='/pasiulymai/:id' component={OfferPage}/>
        <Route exact path='/specialus-pasiulymai' component={SpecialOffersPage}/>
        <Route exact path='/paskutines-minutes-pasiulymai' component={LastMinuteOffersPage}/>

        <Route exact path='/duk' component={FaqsPage}/>
        <Route exact path='/atsiliepimai' component={ReviewsPage}/>
        <Route exact path='/apie-mus' component={AboutUsPage}/>
        <Route exact path='/kontaktai' component={ContactsPage}/>

        <Route exact path='/kelioniu-blogas' component={BlogPage}/>
        <Route exact path='/kelioniu-blogas/:categoryName?/:url' component={BlogInnerPage}/>

        <ProtectedRoute path='/profilis' component={ProfileNavigation}/>
        <ProtectedRoute path='/atsiskaitymas/:id/:roomId' component={CheckoutNavigation}/>
        <ProtectedRoute exact path='/atsiskaitymas/apmokejimas-sekmingas' component={CheckoutSuccessPage}/>

        <Route exact path='/:slug' component={TvsPage}/>
      </Switch>
      <CookiesPopup/>
      {showFooter && <Footer/>}
    </React.Fragment>
  );
}

export default withRouter(AppNavigation);
