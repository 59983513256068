import React from 'react';
import PropTypes from "prop-types";
import {Container, Icon, MonthButton, Title} from './styles';
import {DateUtils, SharedTypes} from "utils";
import {Grid} from "styles";

function Season(props) {
  const {style, name, icon, months, onChange} = props;

  const handleOnChange = (month) => {
    onChange(DateUtils.getMonthRange(month));
  };

  return (
    <Container style={style}>
      <Icon src={icon}/>
      <Title>{name}</Title>
      <Grid.Row className='no-break'>
        {months.map((month, index) => (
          <Grid.Col className='no-break'
            key={month.value}
            mr={index === months.length - 1 ? 0 : 10}
          >
            <MonthButton onClick={() => handleOnChange(month.value)}>
              {month.label}
            </MonthButton>
          </Grid.Col>
        ))}
      </Grid.Row>
    </Container>
  );
}

Season.propTypes = {
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: SharedTypes.DateValueType,
  months: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
};

Season.defaultProps = {
  style: {},
  value: null,
};

export default Season;
