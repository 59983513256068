import React from 'react';
import PropTypes from "prop-types";
import {Container} from './styles';
import {FacebookShareButton as ShareButton} from "react-share";

function FacebookShareButton(props) {
  const {children} = props;

  return (
    <Container>
      <ShareButton url={window.location.href}>
        {children}
      </ShareButton>
    </Container>
  );
}

FacebookShareButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FacebookShareButton;
