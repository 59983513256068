import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Details,
  Image,
  Nights,
  Title,
  Date,
  From,
  Icon,
  Description,
  TitleLink,
  NightsDateCol,
  PriceRow,
} from './styles';
import { DateUtils, ImageUtils, Pluralisation, SharedTypes } from 'utils';
import { Grid, Images } from 'styles';
import RatingInput from '../RatingInput';
import Price from '../Price';

function OfferCard(props) {
  const { style, offer, onClick } = props;

  return (
    <Container className="card" onClick={onClick} style={style}>
      <Image src={ImageUtils.getOfferFirstImage(offer.images)} />
      <Details>
        <Grid.Row mb={10}>
          <RatingInput readonly value={offer.hotelClass} />
        </Grid.Row>
        <Grid.Row mb={10}>
          <TitleLink target="_blank" to={`/pasiulymai/${offer.id}`}>
            <Title maxLine={2} text={offer.title} />
          </TitleLink>
        </Grid.Row>
        <Grid.Row mb={20}>
          <Description maxLine={2} text={offer.summary || ''} />
        </Grid.Row>
        <Grid.Row alignItems="flex-end">
          <NightsDateCol flex={1}>
            <Nights>{Pluralisation.nightsText(offer.nightsCount)}</Nights>
            <Date>{DateUtils.formatDate(offer.departureDatetime)}</Date>
          </NightsDateCol>
          <Grid.Col>
            <PriceRow>
              <Grid.Col mr={5}>
                <From>Nuo</From>
              </Grid.Col>
              <Grid.Col mr={10}>
                <Price value={offer.price} />
              </Grid.Col>
              <Grid.Col>
                <Icon src={Images.ARROW_RIGHT} />
              </Grid.Col>
            </PriceRow>
          </Grid.Col>
        </Grid.Row>
      </Details>
    </Container>
  );
}

OfferCard.propTypes = {
  style: PropTypes.object,
  offer: SharedTypes.OffersListItemType.isRequired,
  onClick: PropTypes.func,
};

OfferCard.defaultProps = {
  style: {},
  onClick: () => {},
};

export default OfferCard;
