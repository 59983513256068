import styled, {css} from "styled-components";
import {Colors} from "styles";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
`;

export const Icon = styled.img`
  margin-bottom: 40px;
  filter: ${props => props.active ? 'invert(51%) sepia(51%) saturate(794%) hue-rotate(162deg) brightness(87%) contrast(93%)' : 'none'};
`;

export const Tab = styled.div`
  flex: 1;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.active ? Colors.PRIMARY : Colors.FORM_BORDER};
  
  ${props => !props.disabled && `
    &:hover {
    border-bottom: 1px solid ${Colors.PRIMARY};
    cursor: pointer;
    
    > span {
      color: ${Colors.PRIMARY};
    }
    
    > img {
      filter: invert(51%) sepia(51%) saturate(794%) hue-rotate(162deg) brightness(87%) contrast(93%);
    }
  }
  `}
  
  @media only screen and (max-width: 1110px){
    ${props => props.blueTop && css`
      border-bottom: ${props => props.active ? '3px solid ' + Colors.WHITE : '1px solid ' + Colors.WHITE} !important;
    `}
  }
`;

export const Title = styled.span`
  color: ${props => props.active ? Colors.PRIMARY : Colors.DROPDOWN_ITEM};
  font-size: 14px;
  font-weight: ${props => props.active ? '600!important' : 600};
  text-transform: uppercase;
`;
