import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  padding: 40px 0;
`;

export const Info = styled.p`
  font-size: 11px;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: justify;
  color: ${(props) => (props.active ? Colors.DROPDOWN_ITEM : Colors.HELP)};
`;

export const QualityInfo = styled.p`
  font-size: 13px;
  line-height: 1.46;
  text-align: justify;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const QualityImage = styled.img`
  height: 75px;
  width: auto;
`;
