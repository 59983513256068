import {FaqsTypes} from "types";
import {FaqsService} from "services";

export function getFaqs() {
  return async dispatch => {
    try {
      dispatch({type: FaqsTypes.GET_FAQS_START});
      const response = await FaqsService.getFaqs();
      dispatch({type: FaqsTypes.GET_FAQS, payload: response});
    } catch (exception) {
      dispatch({type: FaqsTypes.GET_FAQS_ERROR});
    }
  };
}

export function setSelectedCategory(request) {
  return {
    type: FaqsTypes.SET_SELECTED_CATEGORY,
    payload: request
  }
}
