import styled from 'styled-components/macro';
import { Colors } from 'styles';
import { Link } from 'react-router-dom';

export const Divider = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Colors.CHECKBOX_BORDER};
`;

export const DividerLabel = styled.span`
  font-size: 16px;
  top: 9px;
  position: relative;
  background-color: ${Colors.WHITE};
  padding: 0 20px;
  font-weight: 600;
  color: ${Colors.SECTION_DESCRIPTION};
`;

export const BottomLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.6px;
  text-decoration: none;
  color: ${Colors.LIGHT_PRIMARY};
`;
