import styled from 'styled-components/macro';
import { Colors } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
  width: 100%;
  padding: 15px 0;
  background-color: ${Colors.WHITE};
  border-bottom: 1px solid ${Colors.FORM_BORDER};
`;

export const ClearFilterButton = styled.button`
  display: flex;
  height: 38px;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: 0.3s;
  border: 1px solid ${Colors.WHITE};

  &:hover {
    box-shadow: 0 1px 6px ${hexToRgba(Colors.LIGHT_PRIMARY, 0.7)};
    border: 1px solid ${Colors.LIGHT_PRIMARY};
  }
`;

export const ClearFilterButtonLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const ClearFiltersIcon = styled.img`
  margin-right: 10px;
`;
