import apiAxiosInstance from "../apiAxiosInstance";
import {store} from "store";

class FiltersService {
  static async getFilters() {
    return await apiAxiosInstance.get(`/filters`);
  }

  static async getFilterOptionsByIds(key, ids) {
    const filters = store.getState().filters.filters;
    const filter = filters.find(filter => filter.key === key);
    return filter.options.filter(option => ids.includes(option.id));
  }
}

export default FiltersService;
