import React from "react";
import {SharedTypes} from "utils";
import {Container, Header, Image, Details, Name, Date, Description} from './styles';

function Response(props) {
  const {response} = props;

  return (
    <Container>
      <Header>
        <Image src={response.image}/>
        <Details>
          <Name>{response.name}</Name>
          <Date>{response.date}</Date>
        </Details>
      </Header>
      <Description>{response.description}</Description>
    </Container>
  )
}

Response.propTypes = {
  response: SharedTypes.ReviewResponseType.isRequired,
};

export default Response;
