import {useSelector} from "react-redux";
import {CategoriesService} from "services";
import {useEffect, useState} from "react";

function useCategory(id) {
  const {isLoaded} = useSelector(state => state.categories);
  const [category, setCategory] = useState(CategoriesService.getCategoryById(Number(id)));

  useEffect(() => {
    setCategory(CategoriesService.getCategoryById(Number(id)));
  }, [id]);

  return {category, isLoaded};
}

export default useCategory;
