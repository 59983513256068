import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled.span`
  font-size: 14px;
  color: ${Colors.INCLUSIVE_TITLE};
`;

export const Value = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${Colors.INCLUSIVE_TITLE};
`;
