import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {CheckoutActions} from "actions";
import {Container} from "./styles";
import {CheckoutAdditionalForm} from "containers";
import {SharedTypes} from "utils";
import {useHistory, useParams} from 'react-router-dom';

function CheckoutAdditionalPage(props) {
  const {formData, setAdditional} = props;
  const history = useHistory();
  const {id, roomId} = useParams();

  const onSubmit = (formData) => {
    setAdditional(formData);
    history.push(`/atsiskaitymas/${id}/${roomId}/keleiviai`);
  };

  return (
    <Container>
      <CheckoutAdditionalForm
        onBack={history.goBack}
        formData={formData}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

CheckoutAdditionalPage.propTypes = {
  setAdditional: PropTypes.func.isRequired,
  formData: SharedTypes.CheckoutAdditionalFormDataType.isRequired,
};

const mapStateToProps = (state) => {
  const {checkout, authentication} = state;
  const {profile} = authentication;
  const {additional, email, phone, message, passengers} = checkout;
  const phoneAutofill = phone || profile.phone;
  const emailAutofill = email || profile.email;
  return {formData: {phone: phoneAutofill, email: emailAutofill, message, ...additional, passengers}};
};

const mapDispatchToProps = {
  setAdditional: CheckoutActions.setAdditional,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAdditionalPage);
