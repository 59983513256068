import {matchPath} from 'react-router-dom'

export const ROUTES_WITHOUT_HEADER = [
  '/prisijungimas',
  '/registracija',
  '/slaptazodzio-priminimas',
  '/atsiskaitymas/:id/:roomId/papildomos-paslaugos',
  '/atsiskaitymas/:id/:roomId/keleiviai',
  '/atsiskaitymas/:id/:roomId/apmokejimas',
  '/atsiskaitymas/:id/:roomId/papildomos-paslaugos',
  '/atsiskaitymas/apmokejimas-sekmingas'
];

export const ROUTES_WITHOUT_FOOTER = [
  '/prisijungimas',
  '/registracija',
  '/slaptazodzio-priminimas',
  '/atsiskaitymas/:id/:roomId/papildomos-paslaugos',
  '/atsiskaitymas/:id/:roomId/keleiviai',
  '/atsiskaitymas/:id/:roomId/apmokejimas',
  '/atsiskaitymas/:id/:roomId/papildomos-paslaugos',
  '/atsiskaitymas/apmokejimas-sekmingas'
];

export const ROUTES_WITH_FILTER = [
  '/pasiulymai',
];

export const ROUTES_WITH_SEARCH = [
  '/pasiulymai',
  '/specialus-pasiulymai',
  '/paskutines-minutes-pasiulymai',
  '/kategorijos/:id'
];

export function isHeaderVisible(pathname) {
  for (let path of ROUTES_WITHOUT_HEADER) {
    const match = matchPath(pathname, {path, exact: true});
    if (match) return false;
  }
  return true;
}

export function isFooterVisible(pathname) {
  for (let path of ROUTES_WITHOUT_FOOTER) {
    const match = matchPath(pathname, {path, exact: true});
    if (match) return false;
  }
  return true;
}

export function isSearchVisible(pathname) {
  for (let path of ROUTES_WITH_SEARCH) {
    const match = matchPath(pathname, {path, exact: true});
    if (match) return true;
  }
  return false;
}

export function isFilterVisible(pathname) {
  for (let path of ROUTES_WITH_FILTER) {
    const match = matchPath(pathname, {path, exact: true});
    if (match) return true;
  }
  return false;
}

export function getCheckoutTab(pathname) {
  if (matchPath(pathname, {path: '/atsiskaitymas/:id/:roomId/papildomos-paslaugos', exact: true})) {
    return 'services';
  }

  if (matchPath(pathname, {path: '/atsiskaitymas/:id/:roomId/keleiviai', exact: true})) {
    return 'passengers';
  }

  if (matchPath(pathname, {path: '/atsiskaitymas/:id/:roomId/apmokejimas', exact: true})) {
    return 'payment';
  }
}
