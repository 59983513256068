import {Container, Label, Icon} from "./styles";
import React from "react";
import PropTypes from "prop-types";
import {Images} from "styles";

function BackButton(props) {
  const {label, onClick, style} = props;

  return (
    <Container onClick={onClick} style={style}>
      <Icon src={Images.ARROW_BACK}/>
      <Label>{label}</Label>
    </Container>
  );
}

BackButton.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

BackButton.defaultProps = {
  style: {},
  label: 'Atgal',
};

export default BackButton;
