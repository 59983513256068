import React from 'react';
import {Grid} from 'styles';
import {Container, BadgeContainer, ImageContainer, Details} from './styles';
import PropTypes from "prop-types";

function ArticleCardSkeleton(props) {
  const {reversed, height} = props;

  return (
    <Container reversed={reversed} className="card">
      <ImageContainer height={height} reversed={reversed}>
        <BadgeContainer>
          <Grid.Skeleton
            width={100}
            borderRadius={15}
            animationDelay={0.25}
            height={30}
          />
        </BadgeContainer>
      </ImageContainer>
      <Details>
        <Grid.Row mb={10}>
          <Grid.Skeleton
            width={200}
            animationDelay={0.2}
            height={25}
          />
        </Grid.Row>
        <Grid.Row mb={5}>
          <Grid.Skeleton
            animationDelay={0.5}
            height={15}
          />
        </Grid.Row>
        <Grid.Row mb={10}>
          <Grid.Skeleton
            width={220}
            animationDelay={0.5}
            height={15}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Skeleton
            width={90}
            animationDelay={0.5}
            height={10}
          />
        </Grid.Row>
      </Details>
    </Container>
  );
}

ArticleCardSkeleton.propTypes = {
  style: PropTypes.object,
  height: PropTypes.number,
  reversed: PropTypes.bool,
};

ArticleCardSkeleton.defaultProps = {
  style: {},
  height: 220,
  reversed: false,
};

export default ArticleCardSkeleton;
