import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Lightbox from 'react-image-lightbox';

function LightBox(props) {
  const {images, visible, initialIndex, onClose} = props;
  const [index, setIndex] = useState(initialIndex);

  useEffect(() => {
    setIndex(initialIndex);
  }, [initialIndex]);

  if (!visible) {
    return <React.Fragment/>;
  }

  return (
    <Lightbox
      mainSrc={images[index]}
      nextSrc={images[(index + 1) % images.length]}
      prevSrc={images[(index + images.length - 1) % images.length]}
      onCloseRequest={onClose}
      onMovePrevRequest={() => setIndex((index + images.length - 1) % images.length)}
      onMoveNextRequest={() => setIndex((index + 1) % images.length)}
    />
  );
}

LightBox.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  initialIndex: PropTypes.number,
  visible: PropTypes.bool,
};

LightBox.defaultProps = {
  visible: false,
  activeIndex: 0,
};

export default LightBox;
