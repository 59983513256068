import React from 'react';
import {Container} from "./styles";
import {Banner} from "components";
import {Grid} from "styles";
import {OffersLayout} from "layouts";
import {useFilter} from "hooks";

function SpecialOffersPage() {
  const filter = useFilter();

  return (
    <Container>
      <Grid.Row mb={40}>
        <Banner title="TOP pasiūlymai"/>
      </Grid.Row>
      <Grid.Container>
        <OffersLayout filter={{...filter, isTopOffer: true}}/>
      </Grid.Container>
    </Container>
  );
}

export default SpecialOffersPage;
