import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const LoaderContainer = styled.div`
  top: 0;
  bottom: 0;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
