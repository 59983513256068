import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const CheckContainer = styled.div`
  width: 20px;
  border-radius: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => (props.checked ? Colors.LIGHT_PRIMARY : Colors.CHECKBOX_BORDER)};
`;

export const Check = styled.div`
  background-color: ${Colors.LIGHT_PRIMARY};
  width: 12px;
  border-radius: 6px;
  height: 12px;
`;

export const Label = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
`;
