import styled from 'styled-components/macro';
import { Colors, Media } from 'styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.6px;
  color: ${Colors.TITLE};
  margin-bottom: 5px;

  @media only screen and (max-width: ${Media.MOBILE}px) {
    text-align: center;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 1.43;
  color: ${Colors.DROPDOWN_ITEM};

  @media only screen and (max-width: ${Media.MOBILE}px) {
    text-align: center;
  }
`;
