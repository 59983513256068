import React from 'react';
import {Container} from "./styles";
import {Banner} from "components";
import {useParams} from "react-router-dom";
import {useCategory, useFilter} from "hooks";
import {ImageUtils} from "utils";
import {Grid} from "styles";
import {OffersLayout} from "layouts";

function CategoryPage() {
  const {id} = useParams();
  const {category, isLoaded} = useCategory(id);
  const filter = useFilter();

  return (
    <Container>
      <Grid.Row mb={40}>
        <Banner
          backgroundImage={isLoaded ? ImageUtils.getTvsImage(category.image) : undefined}
          title={isLoaded ? category.title : "Ieškoma kategorija..."}
        />
      </Grid.Row>
      <Grid.Container>
        <OffersLayout filter={{...filter, categoryId: id}}/>
      </Grid.Container>
    </Container>
  );
}

export default CategoryPage;
