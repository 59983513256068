import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import TagManager from 'react-gtm-module';
import App from './App';
import {store, persistor} from "store";
import {registerLocale} from 'react-datepicker';

import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/swiper-bundle.min.css';

import './assets/css/index.css';
import './assets/css/cookies.css';
import './assets/css/animations.css';
import './assets/scss/swiper.scss';
import './assets/css/loader.css';
import './assets/css/masonry.css';

import './assets/scss/select.scss';
import './assets/scss/static-page.scss';
import './assets/scss/blog-page.scss';
import './assets/scss/date-picker.scss';
import './assets/scss/collapsible.scss';
import './assets/scss/offer-description.scss';

import { lt } from 'date-fns/locale';
registerLocale('lt', lt);

SwiperCore.use([Navigation, Pagination]);

const tagManagerArgs = {gtmId: 'GTM-MD3C6N'};
TagManager.initialize(tagManagerArgs);

ReactDOM.render((
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App/>
    </PersistGate>
  </Provider>
), document.getElementById('root'));
