import {ReviewsTypes} from "types";
import {ReviewsService} from "services";

export function getReviews() {
  return async dispatch => {
    try {
      dispatch({type: ReviewsTypes.GET_REVIEWS_START});
      const {data, count} = await ReviewsService.getReviews();
      dispatch({type: ReviewsTypes.GET_REVIEWS, payload: {reviews: data, count}});
    } catch (exception) {
      dispatch({type: ReviewsTypes.GET_REVIEWS_ERROR});
    }
  };
}
