import React from 'react';
import PropTypes from "prop-types";
import {Container, Details, OffersCount, Title, BadgeContainer, Badge, Image} from './styles';
import {Pluralisation} from "utils";

function Card(props) {
  const {style, offersCount, onClick, title, image, width, height, priceFrom} = props;

  return (
    <Container className="card" width={width} onClick={onClick} style={style}>
      {!!priceFrom && (
        <BadgeContainer>
          <Badge>Nuo {priceFrom} €</Badge>
        </BadgeContainer>
      )}
      <Image largeHeight={height} src={image}/>
      <Details>
        <Title>{title}</Title>
        <OffersCount>{Pluralisation.offersText(offersCount)}</OffersCount>
      </Details>
    </Container>
  );
}

Card.propTypes = {
  style: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  priceFrom: PropTypes.number,
  offersCount: PropTypes.number.isRequired,
};

Card.defaultProps = {
  style: {},
  height: 150,
  width: null,
  priceFrom: null,
};

export default Card;
