import React from 'react';
import {Container} from './styles';
import {Grid} from "styles";
import RatingSkeleton from "../RatingSkeleton";

function ReviewCardSkeleton() {
  return (
    <Container>
      <Grid.Row mb={5}>
        <Grid.Skeleton
          width={200}
          animationDelay={0.2}
          height={22}
        />
      </Grid.Row>
      <Grid.Row mb={15}>
        <Grid.Skeleton
          width={100}
          animationDelay={0.3}
          height={16}
        />
      </Grid.Row>
      <Grid.Row mb={15}>
        <RatingSkeleton size={12}/>
      </Grid.Row>
      <Grid.Row>
        <Grid.Skeleton
          animationDelay={0.4}
          height={80}
        />
      </Grid.Row>
    </Container>
  );
}

export default ReviewCardSkeleton;
