import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div``;

export const Content = styled.div``;

export const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width: calc(100% + 16px);
  left: -8px;
  right: -8px;
  margin-top: 20px;
`;

export const CategoryContainer = styled.div`
  margin: 5px 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid ${Colors.FORM_BORDER};
`;

export const Name = styled.span`
  color: ${Colors.TITLE};
  font-size: 14px;
  font-weight: 700;
`;

export const Help = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 13px;
`;

export const Icon = styled.img``;

export const SimilarArticlesContainer = styled.div`
  padding: 70px 0;
  background-color: ${Colors.MENU_BACKGROUND};
  width: 100%;
`;

export const SocialContainer = styled.div`
  height: ${(props) => (props.size ? props.size : 36)}px;
  width: ${(props) => (props.size ? props.size : 36)}px;
  transition: 0.3s;
  border-radius: ${(props) => (props.size ? props.size / 2 : 18)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.CHECKBOX_BORDER};

  &:hover {
    box-shadow: rgb(198, 207, 224) 0px 0px 5px;
  }
`;

export const Footer = styled.div`
  padding-top: 40px;
  width: 100%;
  padding-bottom: 70px;
  border-top: 1px solid ${Colors.FORM_BORDER};
`;

export const FooterTitle = styled.h3`
  color: ${Colors.TITLE};
  font-size: 32px;
  font-weight: 700;
`;
