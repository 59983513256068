import update from 'immutability-helper';
import {AuthenticationTypes} from 'types';

const initialState = {
    profile: null,
    token: null,
    error: null,
    isLoading: false,
    isLogged: false,
    facebookId: null,
    googleId: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case AuthenticationTypes.REFRESH_TOKEN_START:
        case AuthenticationTypes.GET_LOGGED_USER_START:
        case AuthenticationTypes.REMIND_PASSWORD_START:
        case AuthenticationTypes.PROVIDER_LOGIN_START:
        case AuthenticationTypes.REGISTER_START:
        case AuthenticationTypes.LOGIN_START: {
            return update(state, {
                isLoading: { $set: true },
            });
        }

        case AuthenticationTypes.REGISTER:
        case AuthenticationTypes.LOGIN: {
            return update(state, {
                isLoading: { $set: false },
                error: { $set: null },
                isLogged: { $set: true },
                profile: { $set: payload.profile },
                token: { $set: payload.token },
            });
        }

        case AuthenticationTypes.RESET_ERROR: {
            return update(state, {
                error: { $set: null },
            });
        }

        case AuthenticationTypes.REMIND_PASSWORD: {
            return update(state, {
                isLoading: { $set: false },
            });
        }

        case AuthenticationTypes.REGISTER_ERROR:
        case AuthenticationTypes.LOGIN_ERROR: {
            return update(state, {
                isLoading: { $set: false },
                error: { $set: payload.error },
            });
        }

        case AuthenticationTypes.LOGOUT:
        case AuthenticationTypes.REMIND_PASSWORD_ERROR:
        case AuthenticationTypes.GET_LOGGED_USER_ERROR:
        case AuthenticationTypes.PROVIDER_LOGIN_ERROR:
        case AuthenticationTypes.REFRESH_TOKEN_ERROR: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}
