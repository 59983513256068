import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import {Grid, Images} from 'styles';
import Collapse, {Panel} from "rc-collapse";

import {AuthenticationActions} from "actions";
import {connect} from "react-redux";
import {MobileMenuContainer, MobileButton, Bar, List, ExternalLink, MenuItem, Social} from './styles';
import {Logo, LogoLink,} from '../styles';
import {SharedTypes} from "utils";

function MobileNavigation(props) {
  const {transparent, renderNavigationItem, isLogged} = props;
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if(menuOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [menuOpen]);

  const toggleClass = ()=>{
    setMenuOpen(!menuOpen)
  };

  return (
      <Grid.Mobile>
        <Grid.Container>
          <Grid.Row className='no-break' justifyContent='space-between'>
            <Grid.Col mb={0}>
              <LogoLink to="/">
                <Logo src={transparent ? Images.LOGO_WHITE : Images.LOGO}/>
              </LogoLink>
            </Grid.Col>
            <Grid.Col alignItems='flex-end'>
              <MobileButton onClick={()=>setMenuOpen(!menuOpen)} menuOpen={menuOpen}>
                <Bar menuOpen={menuOpen} transparent={transparent}/>
                <Bar menuOpen={menuOpen} transparent={transparent}/>
                <Bar menuOpen={menuOpen} transparent={transparent}/>
              </MobileButton>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <MobileMenuContainer menuOpen={menuOpen} id='mobileMenu'>
              <Grid.Container>
                <Grid.Row mb={15}>
                  <Grid.Col>
                    <LogoLink to="/">
                      <Logo src={Images.LOGO_WHITE}/>
                    </LogoLink>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Container>

              <Grid.Row>
                {!isLogged && renderNavigationItem('/prisijungimas', 'Prisijungti', true)}

                {isLogged && (
                    <List borderless>
                      <Collapse>
                        <Panel header='a.ilginyte@gmail.com'>
                          <MenuItem to="/paskutines-minutes-pasiulymai" onClick={toggleClass}>Paskutinė minutė</MenuItem>
                          <MenuItem to="/blogas" onClick={toggleClass}>Kelionių istorijos</MenuItem>
                        </Panel>
                      </Collapse>
                    </List>
                )}
              </Grid.Row>

              <List>
                <MenuItem to="/specialus-pasiulymai" onClick={toggleClass}>Top pasiūlymai</MenuItem>
                <Collapse>
                 <Panel header="Kelionės">
                   <MenuItem to="/kategorijos/2" onClick={toggleClass}>Poilsinės</MenuItem>
                   <MenuItem to="/kategorijos/3" onClick={toggleClass}>Pažintinės</MenuItem>
                   <MenuItem to="/kategorijos/4" onClick={toggleClass}>Egzotinės</MenuItem>
                   <MenuItem to="/kategorijos/5" onClick={toggleClass}>Trumpos</MenuItem>
                   <MenuItem to="/kategorijos/6" onClick={toggleClass}>Slidinėjimo</MenuItem>
                   <MenuItem to="/kategorijos/9" onClick={toggleClass}>Autobusu</MenuItem>
                   <MenuItem to="/kategorijos/7" onClick={toggleClass}>Kelionės iš Varšuvos</MenuItem>
                 </Panel>
                </Collapse>
                <MenuItem to="/paskutines-minutes-pasiulymai" onClick={toggleClass}>Paskutinė minutė</MenuItem>
                <MenuItem to="/blogas" onClick={toggleClass}>Kelionių istorijos</MenuItem>
              </List>

              <List>
                <MenuItem to="/apie-mus" onClick={toggleClass}>Apie mus</MenuItem>
                <MenuItem to="/kontaktai" onClick={toggleClass}>Kontaktai</MenuItem>
                <MenuItem to="/duk" onClick={toggleClass}>D.U.K.</MenuItem>
                <MenuItem to="/" onClick={toggleClass}>Salygos ir taisyklės</MenuItem>
                <MenuItem to="/karjera" onClick={toggleClass}>Karjera</MenuItem>
              </List>

              <List noPx>
                <ExternalLink href=''>
                  <Social src={Images.FB} alt=""/>
                </ExternalLink>
                <ExternalLink href=''>
                  <Social src={Images.INSTAGRAM} alt=""/>
                </ExternalLink>
                <ExternalLink href=''>
                  <Social src={Images.LINKEDIN} alt=""/>
                </ExternalLink>
              </List>

            </MobileMenuContainer>
          </Grid.Row>
        </Grid.Container>
      </Grid.Mobile>
  );
}

MobileNavigation.propTypes = {
  style: PropTypes.object,
  transparent: PropTypes.bool,
  isLogged: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  renderNavigationItem: PropTypes.func.isRequired,
  profile: SharedTypes.ProfileType,
  history: PropTypes.object
};

MobileNavigation.defaultProps = {
  style: {},
  profile: null,
  transparent: false,
};

function mapStateToProps(state) {
  const {authentication} = state;
  const {isLogged, profile} = authentication;
  return {isLogged, profile};
}

const mapDispatchToProps = {
  logout: AuthenticationActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
