import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 35px 0;
  justify-content: space-between;
  border-bottom: solid 1px ${Colors.MENU_SHADOW};

  @media only screen and (max-width: 1110px) {
    // flex-direction: column;
    // justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 5px;
  }
`;

export const Partner = styled.img`
  height: ${(props) => props.height}px;
  width: auto;

  @media only screen and (max-width: 1110px) {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const PartnerWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1110px) {
    width: 50%;
    margin-bottom: 30px;
  }
`;
