import React from 'react';
import {Container} from "./styles";
import {Grid} from "styles";
import {CountriesLayout} from "layouts";

function Countries() {
  return (
    <Container>
      <Grid.Container>
        <CountriesLayout center limit={8}/>
      </Grid.Container>
    </Container>
  );
}

export default Countries;
