import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

export const Content = styled.div`
  border-radius: 8px;
  width: 100%;
  background-color: ${Colors.NEWSLETTER_BACKGROUND};
`;

export const Title = styled.span`
  font-size: 21px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Description = styled.span`
  font-size: 14px;
  color: ${Colors.TITLE};
`;

export const Icon = styled.img``;

export const Time = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${Colors.TITLE};
`;

export const City = styled.span`
  font-size: 12px;
  color: ${Colors.TITLE};
`;

export const AirlineIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 2px;
`;

export const Airline = styled.span`
  font-size: 12px;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Flight = styled.div`
  margin-bottom: 35px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Delay = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const PerkTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const PerkDescription = styled.span`
  font-size: 12px;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Perk = styled.div`
  display: flex;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const Footer = styled.div`
  padding: 45px 30px 30px 30px;
  border-top: 1px solid ${Colors.FORM_BORDER};
`;

export const Header = styled.div`
  padding: 30px;
`;
