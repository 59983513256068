import {Container} from "./styles";
import React from "react";
import PropTypes from "prop-types";
import {Grid} from "styles";
import {Broker} from "components";
import Inclusive from "../Inclusive";

function Side(props) {
  const {price, advance, roomId, inclusive} = props;

  return (
    <Container>
      <Grid.Row mb={10}>
        <Broker/>
      </Grid.Row>
      <Grid.Row>
        <Inclusive
          inclusive={inclusive}
          price={price}
          advance={advance}
          roomId={roomId}
        />
      </Grid.Row>
    </Container>
  );
}

Side.propTypes = {
  price: PropTypes.number.isRequired,
  advance: PropTypes.number.isRequired,
  inclusive: PropTypes.arrayOf(PropTypes.string).isRequired,
  roomId: PropTypes.number,
};

Side.defaultProps = {
  roomId: null,
};

export default Side;
