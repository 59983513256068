import React from 'react';
import PropTypes from "prop-types";
import {Buttons} from "styles";
import {Container, Label} from './styles';
import Loader from "../Loader";
import {Grid} from "styles";

function Button(props) {
  const {style, label, onClick, disabled, type, loading, small, large, fullWidth, theme, renderBadge} = props;

  return (
    <Container
      fullWidth={fullWidth}
      large={large}
      small={small}
      type={type}
      theme={theme}
      disabled={disabled || loading}
      onClick={onClick}
      style={style}
    >
      {loading && <Grid.Col mr={15}><Loader/></Grid.Col>}
      <Grid.Col className="no-break" alignItems="center">
        <Label large={large} small={small} theme={theme}>{label}</Label>
      </Grid.Col>
      {renderBadge && <Grid.Col className="no-break" ml={15}>{renderBadge()}</Grid.Col>}
    </Container>
  );
}

Button.propTypes = {
  style: PropTypes.object,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string,
  theme: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  renderBadge: PropTypes.func,
};

Button.defaultProps = {
  style: {},
  disabled: false,
  fullWidth: false,
  renderBadge: null,
  small: false,
  large: false,
  loading: false,
  type: 'button',
  theme: Buttons.PRIMARY,
};

export default Button;
