import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  border: 3px solid ${Colors.LIGHT_GREY};
  border-radius: 50%;
  border-top: 3px solid ${Colors.PRIMARY};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: ${(props) => (props.isLoading ? 'block' : 'none')};
  animation: spin 2s linear infinite;
`;
