import React from "react";
import autoBind from "auto-bind";
import {Buttons, Grid} from 'styles';
import {Container, SubmitButtonContainer} from './styles';
import Form from "../Form";
import {Button, DatePicker, PassengersCountSelect, Select, DestinationSelect} from "components";
import {DURATIONS} from "constant";
import {UrlUtils, Validations} from "utils";
import {withRouter} from "react-router-dom";
import SearchFormMobile from "../SearchFormMobile";

class HomeSearchForm extends Form {
  constructor(props) {
    super(props);

    const durationFrom = 2;
    const durationTo = 7;

    const adultsCount = 2;
    const childrenCount = 0;
    const infantsCount = 0;

    this.validations = {
      destinationId: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      date: value => Validations.dateNotEmpty(value, 'Laukas yra privalomas'),
    };

    this.state = {
      fields: {
        destinationId: {value: null, error: null, dirty: false},
        date: {value: {dateFrom: null, dateTo: null}, error: null, dirty: false},
        duration: {value: {durationFrom, durationTo}, error: null, dirty: false},
        passengers: {value: {adultsCount, childrenCount, infantsCount}, error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  componentDidUpdate() {
    const {destinationOpen, dateOpen, durationOpen, passengersOpen} = this.state;
    if(destinationOpen || dateOpen || durationOpen || passengersOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }

  submit() {
    const {fields} = this.state;
    const {history} = this.props;

    if (this.validateForm()) {
      const newQueryString = UrlUtils.setQueryParams({
        destinationId: fields.destinationId.value,
        dateFrom: fields.date.value.dateFrom,
        dateTo: fields.date.value.dateTo,
        durationFrom: fields.duration.value.durationFrom,
        durationTo: fields.duration.value.durationTo,
        adultsCount: fields.passengers.value.adultsCount,
        childrenCount: fields.passengers.value.childrenCount,
        infantsCount: fields.passengers.value.infantsCount,
      });
      history.push({ pathname: '/pasiulymai', search: newQueryString });
    }
  }


  render() {
    const {fields} = this.state;

    return (
      <Container onSubmit={(event) => event.preventDefault()}>
        <Grid.Desktop>
          <Grid.Row alignItems="flex-start">
            <Grid.Col flex={3} mr={10}>
              <DestinationSelect
                  large
                  label="Kryptis"
                  value={fields.destinationId.value}
                  dirty={fields.destinationId.dirty}
                  error={fields.destinationId.error}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  name="destinationId"
                  placeholder="Rinkitės šalį/kurortą"
              />
            </Grid.Col>
            <Grid.Col flex={3} mr={10}>
              <DatePicker
                  large
                  label="Data"
                  destinationId={fields.destinationId.value}
                  departureId={1}
                  value={fields.date.value}
                  dirty={fields.date.dirty}
                  error={fields.date.error}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  name="date"
                  placeholder="Rinkitės datą"
              />
            </Grid.Col>
            <Grid.Col flex={2} mr={10}>
              <Select
                  large
                  label="Trukmė"
                  options={DURATIONS}
                  value={fields.duration.value}
                  dirty={fields.duration.dirty}
                  error={fields.duration.error}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  name="duration"
                  placeholder="Rinkitės trukmę"
              />
            </Grid.Col>
            <Grid.Col flex={2} mr={10}>
              <PassengersCountSelect
                  large
                  label="Asm. sk."
                  value={fields.passengers.value}
                  error={fields.passengers.error}
                  dirty={fields.passengers.dirty}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  name="passengers"
                  placeholder="Keleiviai"
              />
            </Grid.Col>
          </Grid.Row>
        </Grid.Desktop>
        <SearchFormMobile handleBlur={this.handleBlur} handleChange={this.handleChange} fields={fields}/>
        <SubmitButtonContainer>
          <Button
              large
              type="submit"
              theme={Buttons.TERTIARY}
              label="Ieškoti"
              onClick={this.submit}
          />
        </SubmitButtonContainer>
      </Container>
    );
  }
}

export default withRouter(HomeSearchForm);
