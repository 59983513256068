import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {AuthenticationActions} from "actions";
import {ForgotPasswordForm} from "containers";
import {AuthenticationWrapper} from "components";

function ForgotPasswordPage(props) {
  const {remindPassword, isLoading} = props;

  return (
    <AuthenticationWrapper
      description="Patvirtinkite savo el. pašto adresą, atsiųsime nuorodą slaptažodžiui pakeisti."
      title="Pamiršote slaptažodį?"
    >
      <ForgotPasswordForm isLoading={isLoading} onSubmit={remindPassword}/>
    </AuthenticationWrapper>
  );
}

ForgotPasswordPage.propTypes = {
  remindPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {authentication} = state;
  const {isLoading} = authentication;
  return {isLoading};
};

const mapDispatchToProps = {
  remindPassword: AuthenticationActions.remindPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
