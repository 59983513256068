import { Grid, Images } from 'styles';
import { Container, Name, Description, Link, Icon, LinkText } from './styles';
import React from 'react';
import { Avatar } from 'components';

function Broker() {
  return (
    <Container>
      <Grid.Row alignItems="flex-start">
        <Grid.Col mr={20}>
          <Avatar source={Images.KRISTUPAS} active size={74} />
        </Grid.Col>
        <Grid.Col>
          <Name>Kristupas</Name>
          <Description>Jūsų kelionės ekspertas</Description>
          <Link href="mailto:kristupas@baltictours.lt">
            <Icon src={Images.CHAT} />
            <LinkText>Susisiekti</LinkText>
          </Link>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
}

export default Broker;
