import styled from 'styled-components/macro';
import { Colors, Grid } from 'styles';

export const Container = styled.div`
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  padding: 30px;
  background-color: ${Colors.DROPDOWN_ITEM};
`;

export const HeaderRow = styled(Grid.Row)`
  && {
    align-items: center;
  }
`;

export const HeaderLabel = styled.span`
  color: ${Colors.WHITE};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Advance = styled.span`
  color: ${Colors.HELP};
  font-size: 12px;
`;

export const Content = styled.div`
  padding: 30px;
  background-color: ${Colors.NEWSLETTER_BACKGROUND};
`;

export const Title = styled.h4`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Item = styled.span`
  font-size: 14px;
  line-height: 2.57;
  color: ${Colors.INCLUSIVE_TITLE};
`;

export const Icon = styled.img`
  margin-right: 15px;
`;

export const ButtonContainer = styled.div`
  width: 190px;
`;
