import update from 'immutability-helper';
import {CheckoutTypes} from 'types';

const initialState = {
  offer: null,
  isLoading: false,
  isLoaded: false,
  email: '',
  phone: '',
  message: '',
  isAdditionalFilled: false,
  isPassengersFilled: false,
  additional: {
    insurance: false,
    additionalLuggage: false,
    seatSelection: false,
    transportation: false,
    carParkSpot: false,
    luggage: []
  },
  passengers: [],
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case CheckoutTypes.GET_CHECKOUT_OFFER_START: {
      return update(state, {
        isLoading: {$set: true},
        isLoaded: {$set: false},
      });
    }

    case CheckoutTypes.GET_CHECKOUT_OFFER: {
      return update(state, {
        offer: {$set: payload.offer},
        isLoading: {$set: false},
        isLoaded: {$set: true},
      });
    }

    case CheckoutTypes.RESET_CHECKOUT:
    case CheckoutTypes.GET_CHECKOUT_OFFER_ERROR: {
      return initialState;
    }

    case CheckoutTypes.SET_ADDITIONAL: {
      return update(state, {
        additional: {$set: payload.additional},
        email: {$set: payload.email},
        phone: {$set: payload.phone},
        message: {$set: payload.message},
        passengers: {$set: payload.passengers},
        isAdditionalFilled: {$set: true},
      });
    }

    case CheckoutTypes.SET_PASSENGERS: {
      return update(state, {
        passengers: {$set: payload.passengers},
        isPassengersFilled: {$set: true},
      });
    }

    default: {
      return state;
    }
  }
}
