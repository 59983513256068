import styled from 'styled-components/macro';
import { Colors, Media } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';

export const Container = styled.div`
  width: 424px;
  height: 238px;
  background-color: ${Colors.WHITE};
  transition: 0.3s;
  padding: 30px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 11px 0 rgba(13, 115, 185, 0.37);
  }

  @media only screen and (max-width: ${Media.MOBILE}px) {
    width: 370px;
  }

  @media only screen and (max-width: ${Media.SMALL_MOBILE}px) {
    width: 330px;
  }
`;

export const Name = styled.span`
  font-size: 18px;
  color: ${Colors.BLACK};
`;

export const Date = styled.span`
  color: ${Colors.HELP};
  font-size: 13px;
  font-weight: 400;
`;

export const Message = styled(LinesEllipsis)`
  font-size: 16px;
  line-height: 1.69;
  letter-spacing: -0.18px;
  color: ${Colors.BLACK};
`;
