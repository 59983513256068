import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.45px;
  color: ${Colors.TITLE};
`;

export const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Section = styled.div`
  padding: 30px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${Colors.BORDER};
`;

export const Value = styled.span`
  font-size: 14px;
  color: ${Colors.INCLUSIVE_TITLE};
`;
