import {Images} from "styles";

export const LOCATION = {
  description: 'Apie 15 km iki oro uosto, apie 3 km iki Mandraki uosto, apie 500 m iki Nikosija miesto. Apie 50 m iki autobusų stotelės. Vakaro pramogos: pasiekiamos pėsčiomis',
  address: '70 Tasou Mitsopoulou (Former Piale Piasa), Larnaca 6028, Kipras',
  closestObjects: [
    {name: 'Povandeninis jūrų muziejus', distance: 0.3},
    {name: 'Eilato promenada', distance: 1.3},
    {name: 'Aqaba Port', distance: 2.3},
    {name: 'Royal Yacht Club', distance: 3.3},
    {name: 'Aqaba Fort', distance: 4.3},
    {name: 'Akabos pietinis paplūdimys', distance: 5.3},
    {name: 'Tala įlanka, Akaba', distance: 6.3},
  ],
  closestAirports: [
    {name: 'King Hussein tarptautinis oro uostas', distance: 15.3},
    {name: 'Eilat Ramon Airport', distance: 22.3},
  ],
  closestMarketplaces: [
    {name: 'Aroma Coffee Bar', distance: 2.3},
    {name: '24 Hours Super-Market', distance: 3.3},
    {name: 'At The Diving Centre', distance: 4.3},
  ],
  closestNatureObjects: [
    {name: 'Eilat Mountains Hiking Trail', distance: 2.3},
  ],
  closestBeaches: [
    {name: 'Paplūdimiai kaimynystėje', distance: 1.3},
  ],
  longitude: -0.255483,
  latitude: 38.497375,
};

export const TRAVEL_DETAILS = {
  perks: [
    {
      id: 1,
      title: 'Rankinis bagažas',
      description: '40 x 30 x 20 cm',
      disabled: false,
      icon: Images.LUGGAGE
    },
    {
      id: 2,
      title: 'Papildomas rankinis bagažas',
      description: '40 x 30 x 20 cm',
      disabled: false,
      icon: Images.LUGGAGE
    },
    {
      id: 3,
      title: 'Registruotas bagažas',
      description: 'Užsakyti pirkimo metu',
      disabled: true,
      icon: Images.LUGGAGE_REGISTERED
    },
    {
      id: 4,
      title: 'Pervežimo paslaugos',
      description: 'Nėra',
      disabled: true,
      icon: Images.CAR
    },
    {
      id: 5,
      title: 'Gido paslaugos',
      description: 'Užsakyta',
      disabled: false,
      icon: Images.MAP
    }
  ],
  departure: {
    date: 'Ket, Sau, 12',
    duration: '12h 20m',
    flights: [
      {
        departureTime: '14:40',
        departureCity: 'Vilnius',
        arrivalTime: '15:30',
        arrivalCity: 'Oslas',
        number: 'AB 1234',
        airline: 'WIZZAIR',
        airlineIcon: Images.AIRLINE,
      }
    ],
  },
  arrival: {
    date: 'Ket, Sau, 12',
    duration: '12h 20m',
    flights: [
      {
        departureTime: '14:40',
        departureCity: 'Vilnius',
        arrivalTime: '15:30',
        arrivalCity: 'Oslas',
        number: 'AB 1234',
        airline: 'WIZZAIR',
        airlineIcon: Images.AIRLINE,
      }
    ]
  }
};

export const PRICES = {
  priceForAdult: 333,
  priceForChild: 240,
  priceForInfant: 120,
  totalPrice: 1234.50
};

export const INCLUSIVE = [
  'Skrydis į abi puses',
  'Viešbutis (standart kambarys)',
  'Maitinimas Pusryčiai ir vakarienė (FB)',
  'Registruotas bagažas (2x20 kg)',
  'Rankinis bagažas (2)',
  'Aptarnavimo mokestis',
  'Kelionių eksperto pagalba',
];











