import {OfferTypes} from "types";
import {OffersService} from "services";

export function getOffer(id) {
  return async dispatch => {
    try {
      dispatch({type: OfferTypes.GET_OFFER_START});
      const {data} = await OffersService.getOffer(id);
      dispatch({type: OfferTypes.GET_OFFER, payload: {offer: data}});
    } catch (exception) {
      dispatch({type: OfferTypes.GET_OFFER_ERROR});
    }
  };
}
