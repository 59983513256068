import React, {useEffect, useState} from 'react';
import {Container, Title} from "./styles";
import {Grid} from "styles";
import {MessagesList, NoData} from "components";
import {MessagesActions} from "actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {SharedTypes} from "utils";
import Tabs from "components/Tabs";

const TABS = [
  {key: 'allMessages', title: 'Visos žinutės'},
  {key: 'agentsMessages', title: 'Agentų pasiūlymai'},
  {key: 'systemMessages', title: 'Sistemos pranešimai'},
];

function ProfileMessagesPage(props) {
  const {getMessages, messages} = props;
  const [activeTab, setActiveTab] = useState('allMessages');

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <Container>
      <Grid.Row mb={35}>
        <Title>Žinutės</Title>
      </Grid.Row>
      {messages.length === 0 && <NoData text="Žinučių nėra"/>}
      {messages.length > 0 && (
        <React.Fragment>
          <Grid.Row mb={20}>
            <Tabs
              tabs={TABS}
              onSelect={(tab) => setActiveTab(tab)}
              active={activeTab}
            />
          </Grid.Row>
          <Grid.Row mb={20}>
            <MessagesList messages={messages}/>
          </Grid.Row>
        </React.Fragment>
      )}
    </Container>
  );
}

ProfileMessagesPage.propTypes = {
  getMessages: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(SharedTypes.MessagesListItemType).isRequired,
};

const mapStateToProps = (state) => {
  const {messages} = state;
  const {isLoading} = messages;
  return {isLoading, messages: messages.messages};
};

const mapDispatchToProps = {
  getMessages: MessagesActions.getMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMessagesPage);
