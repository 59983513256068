import {AgeGroups} from "constant";

const PASSENGER = {reference: '', firstName: '', lastName: '', birthDate: '', additionalLuggage: 0};

export const getPassengers = (adultsCount, childrenCount, infantsCount) => {
  const passengers = [];

  for (let i = 0; i < adultsCount; i++) {
    passengers.push({...PASSENGER, ageGroup: AgeGroups.ADULT});
  }

  for (let i = 0; i < childrenCount; i++) {
    passengers.push({...PASSENGER, ageGroup: AgeGroups.CHILD});
  }

  for (let i = 0; i < infantsCount; i++) {
    passengers.push({...PASSENGER, ageGroup: AgeGroups.INFANT});
  }

  return passengers;
};
