import React, {useEffect} from 'react';
import {Container, CategoriesContainer, CategoryContainer, Content, Header, Name, Help, Icon, SimilarArticlesContainer, SocialContainer, Footer, FooterTitle} from './styles';
import {ArticleCard, Avatar, Badge, Banner, FacebookShareButton, GridContainer, MessengerShareButton} from "components";
import {useParams} from 'react-router-dom';
import PropTypes from "prop-types";
import {SharedTypes} from "utils";
import {ArticleActions} from "actions";
import {connect} from "react-redux";
import {TVS_URL} from "../../config";
import ReactHtmlParser from "react-html-parser";
import {Grid, Images} from "styles";
import _ from "lodash";

function BlogInnerPage(props) {
  const {getArticle, isLoading, article} = props;
  let { url } = useParams();

  const loading = !article || isLoading;

  useEffect(() => {
    getArticle(url);
  }, [url]);

  const renderContent = () => {
    const content = ReactHtmlParser(article.text);

    return (
      <Grid.Container>
        <Grid.Row>
          <Grid.Col flex={1} mr={40}>
            <Content className="static-page blog-page">
              {content}
            </Content>
            {renderFooter()}
          </Grid.Col>
          <Grid.Col>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    );
  };

  const renderFooter = () => (
    <Footer>
      <Grid.Row className="no-break" mb={15}>
        <FooterTitle>Dalinkis</FooterTitle>
      </Grid.Row>
      <Grid.Row className="no-break">
        <Grid.Col className="no-break" mr={15}>
          <FacebookShareButton>
            <SocialContainer size={48}>
              <Icon src={Images.FACEBOOK_BLUE}/>
            </SocialContainer>
          </FacebookShareButton>
        </Grid.Col>
        <Grid.Col className="no-break">
          <MessengerShareButton>
            <SocialContainer size={48}>
              <Icon src={Images.MESSENGER_BLUE}/>
            </SocialContainer>
          </MessengerShareButton>
        </Grid.Col>
      </Grid.Row>
    </Footer>
  );

  const renderBottomContent = () => (
    <CategoriesContainer>
      {article.categories.map(category => (
        <CategoryContainer key={category.name}>
          <Badge
            disabled
            selected
            title={category.name}
          />
        </CategoryContainer>
      ))}
    </CategoriesContainer>
  );

  const renderSimilarArticles = () => (
    <SimilarArticlesContainer>
      <Grid.Container>
        <GridContainer
          tabletItemsCount={2}
          columnGap={40}
          rowGap={40}
          itemsCount={3}
        >
          {_.take(article.similar_posts, 12).map(article => (
            <ArticleCard small key={article.title} article={article}/>
          ))}
        </GridContainer>
      </Grid.Container>
    </SimilarArticlesContainer>
  );

  const renderHeader = () => (
    <Header>
      <Grid.Container>
        <Grid.Row className="no-break">
          <Grid.Col className="no-break" flex={1}>
            <Grid.Row className="no-break">
              <Grid.Col className="no-break" mr={20}>
                <Avatar source={`${TVS_URL}/${article.author.photo}`} size={50}/>
              </Grid.Col>
              <Grid.Col className="no-break">
                <Grid.Row mb={5}>
                  <Name>{article.author.name}</Name>
                </Grid.Row>
                <Grid.Row className="no-break">
                  <Grid.Col className="no-break" mr={15}>
                    <Help>{article.date}</Help>
                  </Grid.Col>
                  <Grid.Col className="no-break" mr={10}>
                    <Icon src={Images.CLOCK}/>
                  </Grid.Col>
                  <Grid.Col className="no-break" mr={15}>
                    <Help>{`Skaitymo trukmė: ${article.reading_time} min.`}</Help>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col className="hide-mobile">
            <Grid.Row>
              <Grid.Col mr={15}>
                <Name>Dalinkis</Name>
              </Grid.Col>
              <Grid.Col mr={15}>
                <FacebookShareButton>
                  <SocialContainer>
                    <Icon src={Images.FACEBOOK_BLUE}/>
                  </SocialContainer>
                </FacebookShareButton>
              </Grid.Col>
              <Grid.Col>
                <MessengerShareButton>
                  <SocialContainer>
                    <Icon src={Images.MESSENGER_BLUE}/>
                  </SocialContainer>
                </MessengerShareButton>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Header>
  );

  return (
    <Container>
      <Banner
        fontSize={48}
        renderBottomContent={loading ? undefined : renderBottomContent}
        backgroundImage={!loading ? `${TVS_URL}/${article.photo}` : undefined}
        title={loading ? 'Ieškomas straipsnis...' : article.title}
      />
      {!loading && renderHeader()}
      {!loading && renderContent()}
      {!loading && renderSimilarArticles()}
    </Container>
  );
}

BlogInnerPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  article: SharedTypes.ArticleType.isRequired,
  getArticle: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {article} = state;
  const {isLoading} = article;
  return {isLoading, article: article.article};
}

const mapDispatchToProps = {
  getArticle: ArticleActions.getArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogInnerPage);
