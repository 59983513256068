import styled from 'styled-components/macro';
import hexToRgba from 'hex-to-rgba';
import { Buttons, Colors } from 'styles';

const getBackgroundColor = ({ theme, disabled }) => {
  const alpha = disabled ? 0.5 : 1;
  let color;

  switch (theme) {
    case Buttons.FACEBOOK: {
      color = Colors.FACEBOOK;
      break;
    }

    case Buttons.GOOGLE: {
      color = Colors.WHITE;
      break;
    }

    default: {
      color = Colors.LIGHT_PRIMARY;
      break;
    }
  }

  return hexToRgba(color, alpha);
};

const getTextColor = ({ theme }) => {
  switch (theme) {
    case Buttons.FACEBOOK: {
      return Colors.WHITE;
    }

    case Buttons.GOOGLE: {
      return Colors.DROPDOWN_ITEM;
    }

    default: {
      return Colors.WHITE;
    }
  }
};

const getBorderColor = ({ theme }) => {
  switch (theme) {
    case Buttons.FACEBOOK: {
      return Colors.FACEBOOK;
    }

    case Buttons.GOOGLE: {
      return Colors.CHECKBOX_BORDER;
    }

    default: {
      return Colors.LIGHT_PRIMARY;
    }
  }
};

export const Container = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 10px;
  box-shadow: 0 1px 5px 0 ${hexToRgba(Colors.BLACK, 0.1)};
  border: 1px solid ${(props) => getBorderColor(props)};
  background-color: ${(props) => getBackgroundColor(props)};
`;

export const Label = styled.span`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.34px;
  color: ${(props) => getTextColor(props)};
`;

export const Icon = styled.img`
  margin-right: 15px;
  height: 17px;
`;
