import update from 'immutability-helper';
import {ReviewsTypes} from 'types';

const initialState = {
  reviews: [],
  count: 0,
  isLoaded: false,
  isLoading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ReviewsTypes.GET_REVIEWS_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case ReviewsTypes.GET_REVIEWS: {
      return update(state, {
        reviews: {$set: payload.reviews},
        count: {$set: payload.count},
        isLoading: {$set: false},
        isLoaded: {$set: true},
      });
    }

    case ReviewsTypes.GET_REVIEWS_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
