import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  padding: 70px 0;
`;

export const Description = styled.div`
  max-width: 860px;
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  letter-spacing: -0.26px;
  line-height: 24px;
  text-align: center;
`;

export const DetailsContainer = styled.div`
  display: flex;
`;

export const DetailsIcon = styled.img`
  margin-right: 25px;
  height: 75px;
`;

export const DetailsTitle = styled.span`
  color: ${Colors.TITLE};
  font-size: 24px;
  font-weight: 700;
`;

export const DetailsDescription = styled.p`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  line-height: 21px;
`;

export const DetailsTextContainer = styled.div``;

export const Image = styled.img`
  border-radius: 6px;
  height: 175px;
  object-fit: cover;
  width: 240px;
`;
