import styled from 'styled-components/macro';

export const Container = styled.div`
  border-radius: 8px;
  margin-top: 20px;
  background-color: #e0e5ee;
  padding: 25px 20px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }

  &:before {
    z-index: 2;
    left: 33px;
    border: 11px solid transparent;
    border-bottom-color: #e0e5ee;
  }

  &:after {
    left: 10px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
  }
`;

export const Description = styled.p`
  color: #404b69;
  font-size: 14px;
  line-height: 25px;
  text-align: justify;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const Name = styled.h4`
  color: #212529;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;

export const Date = styled.span`
  color: #404b69;
  font-size: 13px;
  line-height: 18px;
`;

export const Image = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  object-fit: cover;
  margin-right: 20px;
`;

export const Details = styled.div``;
