import {useSelector} from "react-redux";
import {useState} from "react";
import {Paging} from "constant";
import { useDispatch } from 'react-redux';
import {OffersActions} from "actions";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";

function useOffers(props) {
  const {filter} = props;
  const dispatch = useDispatch();
  const {offers, isLoaded, count, isLoading, isLoadingMore} = useSelector(state => state.offers);
  const [paging, setPaging] = useState(Paging.INITIAL_PAGING);

  useDeepCompareEffect(() => {
    dispatch(OffersActions.getOffers({filter, paging}));
  }, [filter]);

  useDeepCompareEffect(() => {
    if (!_.isEqual(Paging.INITIAL_PAGING, paging)) {
      dispatch(OffersActions.getMoreOffers({filter, paging}));
    }
  }, [paging]);

  const loadMore = () => {
    setPaging({...paging, offset: paging.offset + Paging.LIMIT})
  };

  return {
    offers,
    isLoaded,
    count,
    isLoading,
    loadMore,
    isLoadingMore,
    canLoadMore: !isLoading && offers.length < count,
  };
}

export default useOffers;
