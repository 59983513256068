import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'styles';
import {
  Container,
  Image,
  Title,
  BadgeContainer,
  Badge,
  Description,
  Date,
  ImageContainer,
  Details,
  TitleLink,
} from './styles';
import { ImageUtils, SharedTypes, TextUtils } from 'utils';

function ArticleCard(props) {
  const { style, article, reversed, large, small } = props;

  return (
    <Container reversed={reversed} className="card" style={style}>
      <ImageContainer reversed={reversed}>
        {article.label && (
          <BadgeContainer>
            <Badge>{article.label}</Badge>
          </BadgeContainer>
        )}
        <Image src={ImageUtils.getTvsImage(article.photo)} />
      </ImageContainer>
      <Details>
        <Grid.Row mb={10}>
          <TitleLink to={article.link}>
            <Title small={small} large={large}>
              {article.title}
            </Title>
          </TitleLink>
        </Grid.Row>
        <Grid.Row mb={10}>
          <Description maxLine={large ? 4 : 2} text={TextUtils.decode(article.text)} />
        </Grid.Row>
        <Grid.Row>
          <Date>{article.time}</Date>
        </Grid.Row>
      </Details>
    </Container>
  );
}

ArticleCard.propTypes = {
  style: PropTypes.object,
  large: PropTypes.bool,
  small: PropTypes.bool,
  reversed: PropTypes.bool,
  article: SharedTypes.ArticlesListItemType.isRequired,
};

ArticleCard.defaultProps = {
  style: {},
  reversed: false,
  large: false,
  small: false,
};

export default ArticleCard;
