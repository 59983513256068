import React from 'react';
import PropTypes from "prop-types";
import {Container, Description, Title} from './styles';

function SectionTitle(props) {
  const {style, title, description, center} = props;

  return (
    <Container center={center} style={style}>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </Container>
  );
}

SectionTitle.propTypes = {
  style: PropTypes.object,
  center: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

SectionTitle.defaultProps = {
  style: {},
  center: false,
  description: null,
};

export default SectionTitle;
