import {CheckoutTypes} from "types";
import {OffersService} from "services";

export function getCheckoutOffer(id) {
  return async dispatch => {
    try {
      dispatch({type: CheckoutTypes.GET_CHECKOUT_OFFER_START});
      const {data} = await OffersService.getOffer(id);
      dispatch({type: CheckoutTypes.GET_CHECKOUT_OFFER, payload: {offer: data}});
    } catch (exception) {
      dispatch({type: CheckoutTypes.GET_CHECKOUT_OFFER_ERROR});
    }
  };
}

export function setAdditional(request) {
  return {
    type: CheckoutTypes.SET_ADDITIONAL,
    payload: request,
  }
}

export function reset() {
  return {
    type: CheckoutTypes.RESET_CHECKOUT,
  }
}

export function setPassengers(request) {
  return {
    type: CheckoutTypes.SET_PASSENGERS,
    payload: request,
  }
}
