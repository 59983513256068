import {
  Container,
  Content,
  Title,
  City,
  Help,
  TitleContainer,
  TitleWrapper,
  ButtonRow,
  ExplanationRow,
  PriceColumn,
  PriceRow,
  PriceSection,
} from './styles';
import React from 'react';
import { Colors, Grid } from 'styles';
import PropTypes from 'prop-types';
import { Button, Price, RatingInput } from 'components';
import { useHistory, useParams } from 'react-router-dom';

function Footer(props) {
  const { title, rating, price, roomId } = props;
  const history = useHistory();
  const { id } = useParams();

  const navigate = () => {
    history.push(`/atsiskaitymas/${id}/${roomId}`);
  };

  return (
    <Container>
      <Content>
        <TitleContainer>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          <Grid.StaticRow>
            <Grid.StaticCol mr={30}>
              <City>Talinas, Ryga, Helsinkis</City>
            </Grid.StaticCol>
            <Grid.StaticCol mr={30}>
              <RatingInput value={rating} size={10} readonly />
            </Grid.StaticCol>
          </Grid.StaticRow>
        </TitleContainer>
        <PriceSection>
          <ButtonRow>
            <PriceColumn mr={20}>
              <PriceRow mb={5} justifyContent="flex-end">
                <Price fontSize={20} fontWeight={700} color={Colors.TITLE} value={price} />
              </PriceRow>
              <ExplanationRow>
                <Help>Kaina asmeniui, keliaujant dviese</Help>
              </ExplanationRow>
            </PriceColumn>
            <Grid.StaticCol>
              <Button small label="Rinktis" onClick={navigate} disabled={!roomId} />
            </Grid.StaticCol>
          </ButtonRow>
        </PriceSection>
      </Content>
    </Container>
  );
}

Footer.propTypes = {
  title: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  roomId: PropTypes.number,
};

Footer.defaultProps = {
  roomId: null,
};

export default Footer;
