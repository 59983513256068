import React, {useState} from 'react';
import {Badge, Employee, EmployeeSkeleton, GridContainer, Loadable} from "components";
import {Container, ShowMoreLine, ButtonContainer, ShowButtonContainer} from "./styles";
import {useEmployees} from "hooks";

function Employees() {
  const [showMore, setShowMore] = useState(true);
  const {employees, isLoading} = useEmployees({limit: showMore ? 10 : null});

  return (
    <Container>
      <GridContainer mobileItemsCount={1} rowGap={50} columnGap={40} itemsCount={5}>
        <Loadable
          skeletonsCount={10}
          loading={isLoading}
          renderItem={(employee) => <Employee key={employee.id} employee={employee}/>}
          items={employees}
          renderSkeleton={() => <EmployeeSkeleton/>}
        />
      </GridContainer>
      {!isLoading && (
        <ButtonContainer>
          <ShowButtonContainer>
            <Badge
              selected
              onClick={() => setShowMore(!showMore)}
              title={showMore ? 'Rodyti daugiau' : 'Rodyti mažiau'}
            />
          </ShowButtonContainer>
          <ShowMoreLine/>
        </ButtonContainer>
      )}
    </Container>
  );
}

export default Employees;
