import {Container} from "./styles";
import React from "react";
import {Grid} from "styles";
import {Accordion, Broker} from "components";
import Summary from "../Summary";
import Inclusive from "../Inclusive";
import Additional from "../Additional";
import {Pluralisation, SharedTypes} from "utils";
import {connect} from "react-redux";


function Side(props) {
  const {offer} = props;

  const getItems = () => {
    const passengersCount = offer.adultsCount + offer.childrenCount + offer.infantsCount;

    return [
      {title: `Kelionė ${Pluralisation.individualsText(passengersCount)} ${offer.nightsCount} n.`, content: <Summary/>},
      {title: 'Į kainą įskaičiuota', content: <Inclusive inclusive={offer.inclusive}/>},
      {title: 'Papildomai domina', content: <Additional/>}
    ]
  };

  return (
    <Container>
      <Grid.Row mb={10}>
        <Broker/>
      </Grid.Row>
      <Grid.Row>
        <Accordion items={getItems()}/>
      </Grid.Row>
    </Container>
  );
}

Side.propTypes = {
  offer: SharedTypes.OfferType.isRequired,
};

const mapStateToProps = (state) => {
  const {checkout} = state;
  const {offer} = checkout;
  return {offer};
};

export default connect(mapStateToProps)(Side);
