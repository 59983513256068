import styled from 'styled-components/macro';
import { Colors } from 'styles';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  background-color: ${Colors.WHITE};
`;

export const Logo = styled.img`
  height: 100%;
  width: auto;
`;

export const LogoLink = styled(Link)`
  height: 33px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${Colors.LIGHT_PRIMARY};
`;

export const ButtonIcon = styled.img`
  margin-right: 10px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.6px;
  text-align: center;
  width: 100%;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  text-align: center;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  margin-left: 5px;
  color: ${Colors.LIGHT_PRIMARY};
`;
