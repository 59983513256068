import React from 'react';
import PropTypes from "prop-types";
import {FaqsActions} from "actions";
import {connect} from "react-redux";
import {Container, Category} from './styles';
import {Grid, Typography} from "styles";

function Categories(props) {
  const {categories, selectedCategory, setSelectedCategory} = props;

  const renderCategories = () => {
    return categories.map(category => (
      <Category
        onClick={() => setSelectedCategory({selectedCategory: category})}
        active={selectedCategory === category}
        key={category}
      >
        {category}
      </Category>
    ))
  };

  return (
    <Container>
      <Grid.Row mb={23}>
        <Typography.AsideTitle>Temos</Typography.AsideTitle>
      </Grid.Row>
      <Category
        onClick={() => setSelectedCategory({selectedCategory: null})}
        active={!selectedCategory}>
        Visos
      </Category>
      {renderCategories()}
    </Container>
  );
}

Categories.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  const {faqs} = state;
  const {isLoading, categories, selectedCategory} = faqs;
  return {isLoading, categories, selectedCategory};
};

const mapDispatchToProps = {
  setSelectedCategory: FaqsActions.setSelectedCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
