import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Icon = styled.img``;

export const Phone = styled.a`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    color: ${Colors.LIGHT_PRIMARY};
  }
`;

export const Help = styled.span`
  color: ${Colors.HELP};
  font-size: 11px;
  line-height: 13px;
`;

export const Email = styled.a`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 15px;
  font-weight: 700;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    color: ${Colors.LIGHT_PRIMARY};
  }
`;

export const Location = styled(Email)``;

export const SocialContainer = styled.a`
  height: 44px;
  width: 44px;
  transition: 0.3s;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.CHECKBOX_BORDER};

  &:hover {
    box-shadow: rgb(198, 207, 224) 0px 0px 5px;
  }
`;

export const FinanceText = styled.span`
  white-space: nowrap;
  display: block;
`;
