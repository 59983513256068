import React from 'react';
import PropTypes from "prop-types";
import {Container, Divider, Section, SectionTitle, Title, Description, SectionContent, PassengersWrapper} from "./styles";
import {Grid, Images} from "styles";
import {BackButton, Button, CheckoutItem, GridContainer, Input, Select, Textarea, WarningMessage} from "components";
import Form from "containers/Form";
import autoBind from "auto-bind";
import {SharedTypes, Validations} from "utils";
import update from "immutability-helper";
import _ from "lodash";
import {AgeGroups, LUGGAGE} from "constant";

class CheckoutAdditionalForm extends Form {
  constructor(props) {
    super(props);

    this.validations = {
      phone: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      email: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
    };

    this.state = {
      fields: {
        additionalLuggage: {value: false, error: null, dirty: false},
        insurance: {value: false, error: null, dirty: false},
        seatSelection: {value: false, error: null, dirty: false},
        transportation: {value: false, error: null, dirty: false},
        carParkSpot: {value: false, error: null, dirty: false},
        email: {value: '', error: null, dirty: false},
        phone: {value: '', error: null, dirty: false},
        message: {value: '', error: null, dirty: false},
        passengers: {value: [], error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  componentDidMount() {
    const {formData} = this.props;
    this.setForm(formData);
  }

  componentDidUpdate(prevProps) {
    const {formData} = this.props;

    if (!_.isEqual(formData, prevProps.formData)) {
      this.setForm(formData);
    }
  }

  setForm(formData) {
    this.setState(state => update(state, {
      fields: {
        additionalLuggage: {value: {$set: formData.additionalLuggage}},
        insurance: {value: {$set: formData.insurance}},
        seatSelection: {value: {$set: formData.seatSelection}},
        transportation: {value: {$set: formData.transportation}},
        carParkSpot: {value: {$set: formData.carParkSpot}},
        email: {value: {$set: formData.email}},
        phone: {value: {$set: formData.phone}},
        message: {value: {$set: formData.message}},
        passengers: {value: {$set: formData.passengers}},
      }
    }));
  }

  submit() {
    const {fields} = this.state;
    const {onSubmit} = this.props;

    if (this.validateForm()) {
      onSubmit({
        additional: {
          additionalLuggage: fields.additionalLuggage.value,
          insurance: fields.insurance.value,
          seatSelection: fields.seatSelection.value,
          transportation: fields.transportation.value,
          carParkSpot: fields.carParkSpot.value,
        },
        passengers: fields.passengers.value,
        email: fields.email.value,
        phone: fields.phone.value,
        message: fields.message.value,
      });
    }
  }

  handleAdditionalLuggageChange(event, index) {
    const {value} = event;

    this.setState(state => update(state, {
      fields: {
        passengers: {value: {[index]: {additionalLuggage: {$set: value}}}},
      }
    }));
  }

  render() {
    const {fields} = this.state;
    const {onBack} = this.props;

    return (
      <Container>
        <Grid.Row mb={25}>
          <Title>Užsisakykite papildomas paslaugas</Title>
        </Grid.Row>
        <Grid.Row mb={15}>
          <Section>
            <SectionContent>
              <Grid.Row mb={25}>
                <SectionTitle>Papildomos paslaugos</SectionTitle>
              </Grid.Row>
              <Grid.Row>
                <CheckoutItem
                  price={14.99}
                  onChange={this.handleChange}
                  icon={Images.CASE}
                  checked={fields.additionalLuggage.value}
                  name="additionalLuggage"
                  title="Papildomas bagažas"
                  description="Jei planuojate vežtis daugiau daiktų, nei telpa rankiniame bagaže, užsisakykite registruotą bagažą (10 - 20 kg)"
                />
              </Grid.Row>
              {fields.additionalLuggage.value && (
                <PassengersWrapper>
                  <Grid.Row mb={30}>
                    <Divider/>
                  </Grid.Row>
                  <GridContainer rowGap={15} columnGap={15} itemsCount={2}>
                    {fields.passengers.value.map((passenger, index) => {
                      if (passenger.ageGroup === AgeGroups.ADULT) {
                        return (
                          <Select
                            key={index}
                            large
                            options={LUGGAGE}
                            value={passenger.additionalLuggage}
                            onBlur={this.handleBlur}
                            onChange={(event) => this.handleAdditionalLuggageChange(event, index)}
                            name="passengers"
                            label={`Suaugęs ${index + 1}`}
                            placeholder="Be bagažo"
                          />
                        );
                      }
                    })}
                  </GridContainer>
                </PassengersWrapper>
              )}
            </SectionContent>
          </Section>
        </Grid.Row>
        <Grid.Row mb={40}>
          <Section>
            <SectionContent>
              <Grid.Row>
                <SectionTitle>Agentas siūlo</SectionTitle>
              </Grid.Row>
            </SectionContent>
            <Grid.Row mb={20}>
              <WarningMessage
                borderRadius={0}
                title="SVARBU! Pasirinkus žemiau esančias paslaugas, dabar už jas mokėti nereikia."
                description="Agentas su Jumis susisieks per 24 val. ir pateiks geriausią šiuo metu esantį pasiūlymą."
              />
            </Grid.Row>
            <SectionContent>
              <Grid.Row mb={30}>
                <CheckoutItem
                  price={15.99}
                  onChange={this.handleChange}
                  checked={fields.insurance.value}
                  icon={Images.CHAIR}
                  name="insurance"
                  title="Domina kelionių draudimas?"
                  description='Nerizikuokite keliauti neapsidraudę! Pasinaudokite mūsų partnerių "Gjensidige" draudimo paslaugomis ir būkite ramūs dėl savo kelionės.'
                />
              </Grid.Row>
              <Grid.Row mb={30}>
                <Divider/>
              </Grid.Row>
              <Grid.Row mb={30}>
                <CheckoutItem
                  price={16.99}
                  onChange={this.handleChange}
                  checked={fields.seatSelection.value}
                  icon={Images.CHAIR}
                  name="seatSelection"
                  title="Domina vietos lėktuve pasirinkimas?"
                  description="Gaukite agento pasiūlymą dėl vietų pasirinkimo lėktuve, taip išvengsite nepatogumų kelionės metu ir sedėsite kartu."
                />
              </Grid.Row>
              <Grid.Row mb={30}>
                <Divider/>
              </Grid.Row>
              <Grid.Row mb={30}>
                <CheckoutItem
                  price={17.99}
                  onChange={this.handleChange}
                  icon={Images.BUS}
                  checked={fields.transportation.value}
                  name="transportation"
                  title="Domina pervežimas iš oro uosto?"
                  description="Gaukite agento pasiūlymą dėl vietų pasirinkimo lėktuve, taip išvengsite nepatogumų kelionės metu ir sedėsite kartu."
                />
              </Grid.Row>
              <Grid.Row mb={30}>
                <Divider/>
              </Grid.Row>
              <Grid.Row>
                <CheckoutItem
                  price={18.99}
                  onChange={this.handleChange}
                  icon={Images.PARKING}
                  checked={fields.carParkSpot.value}
                  name="carParkSpot"
                  title="Domina automobilio parkavimas oro uoste"
                  description="Gaukite agento pasiūlymą dėl vietų pasirinkimo lėktuve, taip išvengsite nepatogumų kelionės metu ir sedėsite kartu."
                />
              </Grid.Row>
            </SectionContent>
          </Section>
        </Grid.Row>
        <Grid.Row mb={10}>
          <Title>Jūsų kontaktiniai duomenys</Title>
        </Grid.Row>
        <Grid.Row mb={25}>
          <Description>Patikslinkite kontaktinius duomenis, kuriais gausite informaciją apie užsakymo eigą</Description>
        </Grid.Row>
        <Grid.Row alignItems="flex-start" mb={20}>
          <Grid.Col mr={20} flex={1}>
            <Input
              type="email"
              label="El. paštas"
              placeholder="Įveskite el. paštą"
              onChange={this.handleChange}
              value={fields.email.value}
              dirty={fields.email.dirty}
              error={fields.email.error}
              name="email"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
          <Grid.Col flex={1}>
            <Input
              label="Telefono numeris"
              placeholder="Įveskite telefono numerį"
              onChange={this.handleChange}
              value={fields.phone.value}
              dirty={fields.phone.dirty}
              error={fields.phone.error}
              name="phone"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row mb={40}>
          <Textarea
            error={fields.message.error}
            dirty={fields.message.dirty}
            value={fields.message.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            name="message"
            label="Komentaras kelionių ekspertui"
            placeholder="Kokias ekskursijas galėtumėte parekomeduoti?"
          />
        </Grid.Row>
        <Grid.Row justifyContent="space-between">
          <BackButton onClick={onBack}/>
          <Button large label="Tęsti" onClick={this.submit}/>
        </Grid.Row>
      </Container>
    );
  }
}

CheckoutAdditionalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  formData: SharedTypes.CheckoutAdditionalFormDataType.isRequired,
};

export default CheckoutAdditionalForm;
