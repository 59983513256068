import styled from 'styled-components/macro';
import { Colors } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.form`
  width: 100%;
  box-shadow: 0 0 16px ${hexToRgba(Colors.BLACK, 0.24)};
  border-radius: 6px;
  background-color: ${Colors.WHITE};
  padding: 20px 25px 45px;
  position: relative;
`;

export const SubmitButtonContainer = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: -30px;

  @media only screen and (max-width: 1110px) {
    bottom: -73px;
    z-index: 9;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 20px);
  top: auto;
  bottom: 0;
  left: 0;
  z-index: 9;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.45px;
  color: ${(props) => (props.color ? props.color : Colors.TITLE)};
  margin-bottom: 15px;
`;
