import update from 'immutability-helper';
import {TripsTypes} from 'types';

const initialState = {
  trips: [],
  count: 0,
  isLoading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case TripsTypes.GET_TRIPS_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case TripsTypes.GET_TRIPS: {
      return update(state, {
        trips: {$set: payload.trips},
        count: {$set: payload.count},
        isLoading: {$set: false},
      });
    }

    case TripsTypes.GET_TRIPS_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
