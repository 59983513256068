import {useSelector} from "react-redux";
import _ from 'lodash';

function useEmployees(props) {
  const {limit} = props;
  const {employees, isLoaded, isLoading} = useSelector(state => state.employees);

  return {
    employees: limit ? _.take(employees, limit) : employees,
    isLoaded,
    isLoading
  };
}

export default useEmployees;
