import styled from 'styled-components/macro';
import { Buttons, Colors } from 'styles';

const getBackgroundColor = ({ theme }) => {
  switch (theme) {
    case Buttons.WHITE: {
      return Colors.WHITE;
    }

    case Buttons.TERTIARY: {
      return Colors.RATING;
    }

    case Buttons.SUCCESS: {
      return Colors.GREEN;
    }

    default: {
      return Colors.LIGHT_PRIMARY;
    }
  }
};

const getTextColor = ({ theme }) => {
  switch (theme) {
    case Buttons.WHITE: {
      return Colors.LIGHT_PRIMARY;
    }

    case Buttons.TERTIARY: {
      return Colors.WHITE;
    }

    default: {
      return Colors.WHITE;
    }
  }
};

const getHeight = ({ small, large }) => {
  if (small) {
    return 40;
  }

  if (large) {
    return 60;
  }

  return 54;
};

const getHorizontalPadding = ({ small }) => {
  if (small) {
    return 25;
  }

  return 40;
};

const getFontSize = ({ small, large }) => {
  if (small) {
    return 12;
  }

  if (large) {
    return 16;
  }

  return 13;
};

export const Container = styled.button`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => getHeight(props)}px;
  border-radius: ${(props) => getHeight(props) / 2}px;
  background-color: ${(props) => getBackgroundColor(props)};
  border: none;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: 0 ${(props) => getHorizontalPadding(props)}px;
`;

export const Label = styled.span`
  font-size: ${(props) => getFontSize(props)}px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => getTextColor(props)};
`;
