import React from 'react';
import {Container} from './styles';
import {Grid} from 'styles';

function EmployeeSkeleton() {
  return (
    <Container>
      <Grid.Row mb={15} justifyContent="center">
        <Grid.Skeleton justifyContent="center" animationDelay={0.3} width={120} height={120} borderRadius={60}/>
      </Grid.Row>
      <Grid.Row justifyContent="center" mb={6}>
        <Grid.Skeleton animationDelay={0.2} width={150} height={20}/>
      </Grid.Row>
      <Grid.Row justifyContent="center" mb={10}>
        <Grid.Skeleton animationDelay={0.4} width={80} height={15}/>
      </Grid.Row>
      <Grid.Row justifyContent="center" mb={2}>
        <Grid.Skeleton animationDelay={0.35} height={15}/>
      </Grid.Row>
      <Grid.Row justifyContent="center">
        <Grid.Skeleton animationDelay={0.25} height={15}/>
      </Grid.Row>
    </Container>
  );
}

export default EmployeeSkeleton;
