import React from 'react';
import {Container, Bank, Icon} from './styles';
import PropTypes from "prop-types";
import {GridContainer} from "components";
import {Images} from "styles";

function Banks() {
  return (
    <Container>
      <GridContainer itemsCount={3} columnGap={1} rowGap={1}>
        <Bank>
          <Icon src={Images.SWEDBANK}/>
        </Bank>
        <Bank>
          <Icon src={Images.SEB}/>
        </Bank>
        <Bank>
          <Icon src={Images.LUMINOR}/>
        </Bank>
        <Bank>
          <Icon src={Images.DANSKE}/>
        </Bank>
        <Bank>
          <Icon src={Images.SB}/>
        </Bank>
        <Bank>
          <Icon src={Images.PAYSERA}/>
        </Bank>
      </GridContainer>
    </Container>
  );
}

Banks.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

Banks.defaultProps = {
  value: null,
};

export default Banks;
