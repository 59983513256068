import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {CheckoutActions} from "actions";
import {PassengersUtils} from "utils";

function useCheckoutOffer(props) {
  const {id, roomId} = props;
  const {offer, isLoading, isLoaded, passengers} = useSelector(state => state.checkout);
  const dispatch = useDispatch();
  const [room, setRoom] = useState(null);

  useEffect(() => {
    dispatch(CheckoutActions.getCheckoutOffer(id));
  }, [id]);

  useEffect(() => {
    if (isLoaded) {
      setRoom(offer.hotelRooms.find(room => room.id === roomId));

      if (passengers.length === 0) {
        const passengers = PassengersUtils.getPassengers(offer.adultsCount, offer.childrenCount, offer.infantsCount);
        dispatch(CheckoutActions.setPassengers({passengers}));
      }
    }
  }, [isLoaded]);

  return {
    offer,
    room,
    isLoading,
    isLoaded,
  };
}

export default useCheckoutOffer;
