import React from 'react';
import PropTypes from "prop-types";
import {Container, TooltipContainer, Tooltip, RatingOption, RatingOptionContent, RatingContainer, Content} from './styles';
import {Form} from "styles";

const RATINGS = [
  {value: 1, label: 'Labai blogai'},
  {value: 2, label: 'Blogai'},
  {value: 3, label: 'Vidutiniškai'},
  {value: 4, label: 'Gerai'},
  {value: 5, label: 'Labai gerai'},
];

function RatingInput(props) {
  const {style, readonly, value, onChange, name, size, label, dirty, error} = props;

  const handleOnChange = (value) => {
    onChange({name, value});
  };

  const renderSymbol = (filled) => (
    <RatingOption size={size}>
      {filled && <RatingOptionContent size={size}/>}
    </RatingOption>
  );

  const renderTooltip = () => (
    <TooltipContainer>
      <Tooltip>
        {value ? RATINGS.find(rating => rating.value === value).label : 'Paspauskite, jog pasirinktumėte'}
      </Tooltip>
    </TooltipContainer>
  );

  return (
    <Container style={style}>
      {label && <Form.Label>{label}</Form.Label>}
      <Content>
        <RatingContainer
          initialRating={value}
          onChange={handleOnChange}
          emptySymbol={renderSymbol()}
          fullSymbol={renderSymbol(true)}
          readonly={readonly}
        />
        {!readonly && renderTooltip()}
      </Content>
      {dirty && error && <Form.Error>{error}</Form.Error>}
    </Container>
  );
}

RatingInput.propTypes = {
  style: PropTypes.object,
  value: PropTypes.number,
  dirty: PropTypes.bool,
  error: PropTypes.bool,
  size: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
};

RatingInput.defaultProps = {
  style: {},
  value: null,
  label: null,
  name: null,
  size: 9,
  dirty: false,
  error: false,
  readonly: true,
  onChange: () => {},
};

export default RatingInput;
