import {Container, Content} from "./styles";
import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import {Grid, Typography} from "styles";
import {Expandable} from "components";

function Description(props) {
  const {description} = props;
  const content = ReactHtmlParser(description);

  return (
    <Container>
      <Grid.Row mb={25}>
        <Typography.PageTitle>Apie kurortą</Typography.PageTitle>
      </Grid.Row>
      <Content className="offer-description">
        <Expandable>
          {content}
        </Expandable>
      </Content>
    </Container>
  );
}

Description.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Description;
