import React from 'react';
import PropTypes from "prop-types";
import {Container} from './styles';

function Loader(props) {
  const {style, loading, size} = props;

  return <Container size={size} isLoading={loading} style={style}/>;
}

Loader.propTypes = {
  style: PropTypes.object,
  size: PropTypes.number,
  loading: PropTypes.bool,
};

Loader.defaultProps = {
  style: {},
  size: 25,
  loading: true,
};

export default Loader;
