import React from 'react';
import PropTypes from "prop-types";
import {Container} from "./styles";
import {Swiper, SwiperSlide} from "swiper/react";
import _ from "lodash";

function HorizontalSlider(props) {
  const {renderSlide, slides, spaceBetween, isLoading, renderSkeleton, navigation} = props;
  const initialSlide = Math.floor(slides.length / 2);

  return (
    <Container>
      <Swiper
        className="cards-container"
        centeredSlidesBounds
        initialSlide={initialSlide}
        centeredSlides
        navigation={navigation}
        slidesPerView={'auto'}
        spaceBetween={spaceBetween}
      >
        {!isLoading && slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {renderSlide(slide)}
          </SwiperSlide>
        ))}
        {isLoading && _.range(10).map((slide, index) => (
          <SwiperSlide key={index}>
            {renderSkeleton()}
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}

HorizontalSlider.propTypes = {
  renderSlide: PropTypes.func.isRequired,
  slides: PropTypes.array.isRequired,
  spaceBetween: PropTypes.number,
  navigation: PropTypes.bool,
  renderSkeleton: PropTypes.func,
  isLoading: PropTypes.bool,
};

HorizontalSlider.defaultProps = {
  spaceBetween: 15,
  isLoading: false,
  navigation: true,
  renderSkeleton: () => {},
};

export default HorizontalSlider;
