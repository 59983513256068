import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div``;

export const Content = styled.div`
  overflow: hidden;
  position: relative;
  height: ${(props) => (props.height && props.showMore ? `${props.height}px` : 'auto')};

  &:after {
    content: '';
    display: ${(props) => (props.showMore ? 'block' : 'none')};
    position: absolute;
    backdrop-filter: blur(2px);
    height: 60px;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export const ShowMoreLine = styled.div`
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  top: -15px;
  height: 1px;
  z-index: -1;
  background-color: ${Colors.MENU_SHADOW};
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 99;
  top: ${(props) => (props.showMore ? -15 : 15)}px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
