import styled from 'styled-components/macro';
import { Colors } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Label = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.active ? Colors.WHITE : Colors.DROPDOWN_ITEM)};
`;

export const Container = styled.div`
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => (props.active ? Colors.PRIMARY : Colors.FORM_BORDER)};
  padding: 0 30px;
  transition: 0.3s;
  background-color: ${(props) => (props.active ? Colors.PRIMARY : Colors.WHITE)};

  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 6px ${hexToRgba(Colors.LIGHT_PRIMARY, 0.7)};
    border: 1px solid ${Colors.LIGHT_PRIMARY};
  }
`;
