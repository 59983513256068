import React from "react";
import autoBind from "auto-bind";
import PropTypes from 'prop-types';
import {Validations} from "utils";
import Form from "../Form";
import {Container, Title} from './styles';
import {Button, Checkbox, ImagesUpload, Input, RatingInput, Textarea} from "components";
import {Grid} from 'styles';

class ReviewForm extends Form {

  constructor(props) {
    super(props);

    this.validations = {
      name: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      email: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      message: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      termsAndConditions: value => Validations.isTrue(value, 'Privalote sutikti su taisyklėmis'),
    };

    this.state = {
      fields: {
        name: {value: '', error: null, dirty: false},
        images: {value: [], error: null, dirty: false},
        termsAndConditions: {value: false, error: null, dirty: false},
        email: {value: '', error: null, dirty: false},
        rating: {value: null, error: null, dirty: false},
        message: {value: '', error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  submit() {
    const {fields} = this.state;
    const {onSubmit} = this.props;

    if (this.validateForm()) {
      onSubmit({
        name: fields.name.value,
        email: fields.email.value,
        photo: fields.images.value,
        rating: fields.rating.value,
        content: fields.message.value,
      });
    }
  }

  render() {
    const {fields} = this.state;
    const {isLoading} = this.props;

    return (
      <Container onSubmit={(event) => event.preventDefault()}>
        <Grid.Row mb={25}>
          <Title>Jūsų atsiliepimas</Title>
        </Grid.Row>
        <Grid.Row alignItems="flex-start" mb={30}>
          <Grid.Col flex={1} mr={20}>
            <Input
              large
              error={fields.name.error}
              dirty={fields.name.dirty}
              value={fields.name.value}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              name="name"
              label="Vardas"
              placeholder="Įveskite vardą"
            />
          </Grid.Col>
          <Grid.Col flex={1}>
            <Input
              large
              error={fields.email.error}
              dirty={fields.email.dirty}
              value={fields.email.value}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              name="email"
              label="El. paštas"
              placeholder="Įveskite el. paštą"
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row mb={30}>
          <RatingInput
            size={24}
            readonly={false}
            value={fields.rating.value}
            onChange={this.handleChange}
            name="rating"
            label="Pasirinkite įvertinimą"
          />
        </Grid.Row>
        <Grid.Row mb={30}>
          <Textarea
            error={fields.message.error}
            dirty={fields.message.dirty}
            value={fields.message.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            name="message"
            label="Jūsų atsiliepimas / komentaras"
            placeholder="Žinutė…"
          />
        </Grid.Row>
        <Grid.Row mb={30}>
          <ImagesUpload
            onChange={this.handleChange}
            error={fields.images.error}
            dirty={fields.images.dirty}
            value={fields.images.value}
            name="images"
            label="Ar turite nuotraukų, kuriomis norėtumėte pasidalinti?"
          />
        </Grid.Row>
        <Grid.Row mb={30}>
          <Checkbox
            onBlur={this.handleBlur}
            error={fields.termsAndConditions.error}
            dirty={fields.termsAndConditions.dirty}
            label="Sutinku, jog pateikta informacija bus skelbiama Baltic Tours tinklapyje ir patvirtinu, jog esu nuotraukų savininkas."
            checked={fields.termsAndConditions.value}
            onChange={this.handleChange}
            name="termsAndConditions"
          />
        </Grid.Row>
        <Grid.Row>
          <Button
            type="submit"
            loading={isLoading}
            label="Siųsti atsiliepimą"
            onClick={this.submit}
          />
        </Grid.Row>
      </Container>
    );
  }
}

ReviewForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ReviewForm;
