import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div``;

export const Icon = styled.img`
  margin-right: 10px;
`;

export const Label = styled.span`
  font-size: 13px;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;
