import styled from 'styled-components/macro';
import { Colors, Grid } from 'styles';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  padding: 40px 0;
`;

export const Logo = styled.img`
  height: 43px;
`;

export const MobilePhoneRow = styled(Grid.StaticRow)`
  justify-content: center;
`;

export const Phone = styled.a`
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  color: ${Colors.DROPDOWN_ITEM};
  transition: 0.3s;

  &:hover {
    color: ${Colors.LIGHT_PRIMARY};
  }
`;

export const Help = styled.small`
  font-size: 11px;
  color: ${Colors.HELP};
`;

export const Icon = styled.img``;

export const Email = styled.a`
  font-size: 14px;
  font-weight: 700;
  color: ${Colors.DROPDOWN_ITEM};
  transition: 0.3s;
  text-decoration: none;
  
  &:hover {
    color: ${Colors.LIGHT_PRIMARY};
  }
}`;

export const SectionTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: ${Colors.DROPDOWN_ITEM};
  margin-bottom: 10px;
`;

export const NavigationLink = styled(Link)`
  font-size: 13px;
  display: flex;
  align-items: center;
  line-height: 2.31;
  color: ${Colors.DROPDOWN_ITEM};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const NavigationIcon = styled.img`
  margin-right: 15px;
`;
