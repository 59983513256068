import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  padding: 70px 0;
  background-color: ${Colors.MENU_BACKGROUND};
`;

export const Description = styled.div`
  max-width: 860px;
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  letter-spacing: -0.26px;
  line-height: 24px;
  text-align: center;
`;
