import React from 'react';
import PropTypes from "prop-types";
import {Container} from './styles';
import Season from "./Season";
import {DateUtils, SharedTypes} from "utils";

function MonthSelect(props) {
  const {style, onChange, value} = props;

  return (
    <Container style={style}>
      {DateUtils.SEASONS.map(season => (
        <Season
          key={season.name}
          name={season.name}
          value={value}
          onChange={onChange}
          icon={season.icon}
          months={season.months}
        />
      ))}
    </Container>
  );
}

MonthSelect.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: SharedTypes.OptionType
};

MonthSelect.defaultProps = {
  style: {},
  value: null,
};

export default MonthSelect;
