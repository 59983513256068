import {DatesTypes} from "types";
import {DatesService} from "services";

export function getDates(request) {
  return async dispatch => {
    try {
      dispatch({type: DatesTypes.GET_DATES_START});
      const dates = await DatesService.getDates(request);
      dispatch({type: DatesTypes.GET_DATES, payload: {dates}});
    } catch (exception) {
      dispatch({type: DatesTypes.GET_DATES_ERROR});
    }
  };
}
