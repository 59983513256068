import {Container} from "./styles";
import {Grid} from "styles";
import {SectionTitle} from "components";
import RightSideLayout from "../RightSideLayout";
import {Offers} from "containers";
import React from "react";
import {Pluralisation, SharedTypes} from "utils";
import PropTypes from "prop-types";
import {AuthenticationActions} from "actions";
import {connect} from "react-redux";

const LOADING_TEXT = 'Ieškoma kelionių pasiūlymų...';
const NO_RESULTS_TEXT = 'Kelionių pasiūlymų pagal jūsų pateiktą paiešką nėra';
const RESULTS_TEXT = (count) => Pluralisation.offersLongText(count);
const DESCRIPTION_TEXT = 'Norėdami matyti daugiau pasiūlymų ir tikslias kainas, paieškoje įveskite konkrečią kelionės kryptį, datą ir keleivių skaičių.';

function OffersLayout(props) {
  const {filter, isLoaded, count, isLoading} = props;

  return (
    <Container>
      <Grid.Row mb={50}>
        <SectionTitle
          title={isLoading ? LOADING_TEXT : isLoaded && count === 0 ? NO_RESULTS_TEXT : RESULTS_TEXT(count)}
          description={DESCRIPTION_TEXT}
        />
      </Grid.Row>
      <RightSideLayout>
        <Offers filter={filter}/>
      </RightSideLayout>
    </Container>
  );
}

OffersLayout.propTypes = {
  filter: SharedTypes.OffersFilterType,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};

OffersLayout.defaultProps = {
  filter: null,
};

function mapStateToProps(state) {
  const {offers} = state;
  const {isLoaded, count, isLoading} = offers;
  return {isLoaded, count, isLoading};
}

const mapDispatchToProps = {
  logout: AuthenticationActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersLayout);
