import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Container, Image, ImageContainer, Overlap, OverlapText} from './styles';
import LightBox from "../LightBox";

function Gallery(props) {
  const {images, imagesCount, visible, onClose, height} = props;
  const firstFiveImages = images.slice(0, imagesCount);
  const [lightBoxVisible, setLightBoxVisible] = useState(visible);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    setLightBoxVisible(visible);
  }, [visible]);

  const handleImageClick = (index) => {
    setImageIndex(index);
    setLightBoxVisible(true);
  };

  const handleClose = () => {
    setLightBoxVisible(false);
    onClose();
  };

  const renderOverlap = () => (
    <Overlap>
      <OverlapText>{`+${images.length - imagesCount}`}</OverlapText>
    </Overlap>
  );

  return (
    <Container>
      {firstFiveImages.map((image, index) => (
        <ImageContainer
          height={height}
          imagesCount={imagesCount}
          onClick={() => handleImageClick(index)}
          key={index}
        >
          {index === imagesCount - 1 && images.length > imagesCount && renderOverlap()}
          <Image src={image}/>
        </ImageContainer>
      ))}
      <LightBox
        initialIndex={imageIndex}
        visible={lightBoxVisible}
        onClose={handleClose}
        images={images}
      />
    </Container>
  )
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  imagesCount: PropTypes.number,
  height: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

Gallery.defaultProps = {
  imagesCount: 5,
  height: 90,
  visible: false,
  onClose: () => {},
};

export default Gallery;
