const TRIPS = {
  result: [
    // {
    //   id: 1,
    //   orderNumber: 'J48SS8D',
    //   image: 'https://www.studyandgoabroad.com/wp-content/uploads/2018/10/studyspain.jpg',
    //   status: TRIP_STATUSES.PAID,
    //   title: 'Nefeli Hotel',
    //   description: 'Keliaukite į Graikiją šį rudenį! 7 naktys vaizdingame 4* viešbutyje su pusryčiais ir vakarienėmis Lorem ipsum dolor sit amet, consectetur adipisicing elit. A deleniti, dolor doloribus esse et facere ipsam laboriosam magni maxime obcaecati, quis quo recusandae sapiente ullam voluptatem? A aperiam hic quaerat.',
    //   destination: 'Graikija, Kreta',
    //   dateFrom: 'Ket, Sau 12, 2019',
    //   dateTo: 'Ket, Sau 19, 2019',
    //   nightsCount: 7,
    //   inPast: false,
    // },
    // {
    //   id: 2,
    //   orderNumber: 'J48SS8D',
    //   image: 'https://www.studyandgoabroad.com/wp-content/uploads/2018/10/studyspain.jpg',
    //   status: TRIP_STATUSES.PENDING,
    //   title: 'Nefeli Hotel',
    //   description: 'Keliaukite į Graikiją šį rudenį! 7 naktys vaizdingame 4* viešbutyje su pusryčiais ir vakarienėmis Lorem ipsum dolor sit amet, consectetur adipisicing elit. A deleniti, dolor doloribus esse et facere ipsam laboriosam magni maxime obcaecati, quis quo recusandae sapiente ullam voluptatem? A aperiam hic quaerat.',
    //   destination: 'Graikija, Kreta',
    //   dateFrom: 'Ket, Sau 12, 2019',
    //   dateTo: 'Ket, Sau 19, 2019',
    //   nightsCount: 7,
    //   inPast: false,
    // },
  ],
  count: 0,
};

export default TRIPS;
