import {CategoriesTypes} from "types";
import {CategoriesService} from "services";
import _ from "lodash";

export function getCategories() {
  return async dispatch => {
    try {
      dispatch({type: CategoriesTypes.GET_CATEGORIES_START});
      const {data, count} = await CategoriesService.getCategories();
      const categories = _.orderBy(data, 'offersCount', 'desc');
      dispatch({type: CategoriesTypes.GET_CATEGORIES, payload: {categories, count}});
    } catch (exception) {
      dispatch({type: CategoriesTypes.GET_CATEGORIES_ERROR});
    }
  };
}
