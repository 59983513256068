import React from 'react';
import {Container} from "./styles";
import {useHistory} from 'react-router-dom';
import {Card, CardSkeleton, HorizontalSlider} from "components";
import {TVS_URL} from "../../../config";
import {useCategories} from "hooks";

function Categories() {
  const {categories, isLoading} = useCategories();
  const history = useHistory();

  const renderCategory = (category) => (
    <Card
      width={270}
      priceFrom={category.price}
      title={category.title}
      offersCount={category.offersCount}
      image={`${TVS_URL}/${category.image}`}
      onClick={() => history.push(`/kategorijos/${category.id}`)}
    />
  );

  return (
    <Container>
      <HorizontalSlider
        isLoading={isLoading}
        renderSkeleton={() => <CardSkeleton width={270}/>}
        renderSlide={renderCategory}
        slides={categories}
      />
    </Container>
  );
}

export default Categories;
