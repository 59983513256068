import EMPLOYEES from "./mocks";
import {ApiUtils} from "utils";

class EmployeesService {
  static async getEmployees() {
    await ApiUtils.sleep();
    return Promise.resolve(EMPLOYEES);
  }
}

export default EmployeesService;
