import React from 'react';
import PropTypes from "prop-types";
import {Container, Title} from "./styles";
import {Grid} from "styles";
import {Input} from "components";
import Form from "containers/Form";
import autoBind from "auto-bind";
import {SharedTypes, Validations} from "utils";
import update from "immutability-helper";
import _ from "lodash";

class CheckoutPassengerForm extends Form {
  constructor(props) {
    super(props);

    this.validations = {
      reference: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      firstName: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      lastName: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      birthDate: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
    };

    this.state = {
      fields: {
        reference: {value: '', error: null, dirty: false},
        firstName: {value: '', error: null, dirty: false},
        lastName: {value: '', error: null, dirty: false},
        birthDate: {value: '', error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  componentDidMount() {
    const {formData} = this.props;
    this.setForm(formData);
  }

  componentDidUpdate(prevProps, prevState) {
    const {formData, onChange, index} = this.props;
    const {fields} = this.state;

    if (!_.isEqual(fields, prevState.fields)) {
      onChange(index, {
        reference: fields.reference.value,
        firstName: fields.firstName.value,
        lastName: fields.lastName.value,
        birthDate: fields.birthDate.value,
      });
    }

    if (!_.isEqual(formData, prevProps.formData)) {
      this.setForm(formData);
    }
  }

  setForm(formData) {
    this.setState(state => update(state, {
      fields: {
        reference: {value: {$set: formData.reference}},
        firstName: {value: {$set: formData.firstName}},
        lastName: {value: {$set: formData.lastName}},
        birthDate: {value: {$set: formData.birthDate}},
      }
    }));
  }

  submit() {
    const {fields} = this.state;
    const {onSubmit} = this.props;

    if (this.validateForm()) {
      onSubmit({
        reference: fields.reference.value,
        firstName: fields.firstName.value,
        lastName: fields.lastName.value,
        birthDate: fields.birthDate.value,
      });
    }
  }

  render() {
    const {fields} = this.state;
    const {index} = this.props;

    return (
      <Container>
        <Grid.Row mb={25}>
          <Title>Keleivis {index + 1}</Title>
        </Grid.Row>
        <Grid.Row alignItems="flex-start">
          <Grid.Col mr={10} flex={2}>
            <Input
              label="Kreipinys"
              placeholder="Ponas"
              onChange={this.handleChange}
              value={fields.reference.value}
              dirty={fields.reference.dirty}
              error={fields.reference.error}
              name="reference"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
          <Grid.Col mr={10} flex={3}>
            <Input
              label="Vardas"
              placeholder="Įveskite vardą"
              onChange={this.handleChange}
              value={fields.firstName.value}
              dirty={fields.firstName.dirty}
              error={fields.firstName.error}
              name="firstName"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
          <Grid.Col mr={10} flex={3}>
            <Input
              label="Pavardė"
              placeholder="Įveskite pavardę"
              onChange={this.handleChange}
              value={fields.lastName.value}
              dirty={fields.lastName.dirty}
              error={fields.lastName.error}
              name="lastName"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
          <Grid.Col flex={2}>
            <Input
              label="Gimimo data"
              placeholder="1990-12-13"
              onChange={this.handleChange}
              value={fields.birthDate.value}
              dirty={fields.birthDate.dirty}
              error={fields.birthDate.error}
              name="birthDate"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
        </Grid.Row>
      </Container>
    );
  }
}

CheckoutPassengerForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  formData: SharedTypes.CheckoutPassengerType.isRequired,
};

export default CheckoutPassengerForm;
