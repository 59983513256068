import React from 'react';
import {Container} from './styles';
import {Grid} from 'styles';
import _ from "lodash";
import PropTypes from "prop-types";

function RatingSkeleton(props) {
  const {size} = props;

  const renderRatingSkeleton = (index) => (
    <Grid.Col key={index} mr={index === 4 ? 0 : 5}>
      <Grid.Skeleton
        borderRadius={size / 2}
        width={size}
        height={size}
        animationDelay={0.3}
      />
    </Grid.Col>
  );

  return (
    <Container>
      {_.range(5).map(index => renderRatingSkeleton(index))}
    </Container>
  );
}

RatingSkeleton.propTypes = {
  size: PropTypes.number,
};

RatingSkeleton.defaultProps = {
  size: 9,
};

export default RatingSkeleton;
