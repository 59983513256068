import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import {CheckoutAdditionalPage, CheckoutPassengersPage, CheckoutPaymentPage} from "pages";
import {ProtectedRoute} from "containers";
import {CheckoutLayout} from "layouts";

function CheckoutNavigation() {
  return (
    <CheckoutLayout>
      <Switch>
        <ProtectedRoute exact path='/atsiskaitymas/:id/:roomId/papildomos-paslaugos' component={CheckoutAdditionalPage}/>
        <ProtectedRoute exact path='/atsiskaitymas/:id/:roomId/keleiviai' component={CheckoutPassengersPage}/>
        <ProtectedRoute exact path='/atsiskaitymas/:id/:roomId/apmokejimas' component={CheckoutPaymentPage}/>
        <Redirect to='/atsiskaitymas/:id/:roomId/papildomos-paslaugos'/>
      </Switch>
    </CheckoutLayout>
  );
}

export default CheckoutNavigation;
