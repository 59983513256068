import styled from 'styled-components/macro';
import { Colors, Grid } from 'styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled(Grid.Row)`
  width: 100%;
  height: 100%;
`;

export const DropdownContainer = styled.div`
  width: 719px;
  background-color: ${Colors.WHITE};

  @media only screen and (max-width: 1110px) {
    max-width: 100%;

    > div:first-child > div {
      background: ${Colors.PRIMARY};
      margin-bottom: 10px;

      span {
        color: ${Colors.WHITE};
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
`;

export const Tab = styled.div`
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.active ? 'flex' : 'none')};
`;

export const DatePickerWrapper = styled.div`
  position: relative;
`;

export const LoaderContainer = styled.div`
  top: 0;
  bottom: 0;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlueTop = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  padding: 50px 15px 8px;
  background: ${Colors.PRIMARY};
`;
