import React from 'react';
import PropTypes from "prop-types";
import {Button, Card, CardSkeleton, GridContainer, Loadable} from "components";
import {Grid} from "styles";
import {Container} from "./styles";
import {useCountries} from "hooks";
import {useHistory} from 'react-router-dom';
import {UrlUtils} from "utils";

function Countries(props) {
  const {limit} = props;
  const {countries, isLoading} = useCountries(limit);
  const history = useHistory();

  const navigate = (destinationId) => {
    const queryString = UrlUtils.setQueryParam("destinationId", destinationId);
    history.push({pathname: '/pasiulymai', search: queryString})
  };

  const renderCountry = (country) => (
    <Card
      priceFrom={country.price}
      key={country.id}
      height={245}
      title={country.title}
      offersCount={country.offersCount || 0}
      image={country.image}
      onClick={() => navigate(country.id)}
    />
  );

  return (
    <Container>
      <GridContainer>
        <Loadable
          inline
          skeletonsCount={8}
          loading={isLoading}
          renderItem={(country) => renderCountry(country)}
          items={countries}
          renderSkeleton={() => <CardSkeleton height={245}/>}
        />
      </GridContainer>
      {limit && !isLoading && (
        <Grid.Row mt={45} justifyContent="center">
          <Button
            large
            label="Rodyti visas šalis"
            onClick={() => history.push('/salys')}
          />
        </Grid.Row>
      )}
    </Container>
  );
}

Countries.propTypes = {
  limit: PropTypes.number,
};

Countries.defaultProps = {
  limit: null,
};

export default Countries;
