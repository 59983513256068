import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  max-width: ${(props) => (props.large ? 900 : 700)}px;
  width: 100%;
  padding: ${(props) => (props.transparent ? '60px 0 0 0' : '50px 30px')};
  background-color: ${(props) => (props.transparent ? 'transparent' : Colors.WHITE)};
  border-radius: 8px;
  position: relative;
`;

export const CloseIconContainer = styled.div`
  background-color: ${(props) => (props.closeWhite ? 'transparent' : Colors.WHITE)};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  top: ${(props) => (props.transparent ? -10 : 10)}px;
  right: ${(props) => (props.transparent ? 0 : 10)}px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const CloseIcon = styled.img`
  width: ${(props) => (props.closeWhite ? 15 : 50)}px;
`;
