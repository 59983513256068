import React from 'react';
import PropTypes from 'prop-types';
import { Container, SideContainer } from './styles';
import { Grid } from 'styles';
import { NewsletterForm } from 'containers';

function RightSideLayout(props) {
  const { style, sideWidth, children, renderSide, showNewsletter, topOffset, bottomOffset, gap } = props;

  return (
    <Container style={style}>
      <Grid.Row alignItems="flex-start">
        <Grid.Col mr={gap} flex={1}>
          {children}
        </Grid.Col>
        <SideContainer bottomOffset={bottomOffset} topOffset={topOffset} sideWidth={sideWidth}>
          {showNewsletter && (
            <Grid.Row mb={renderSide ? 20 : 0}>
              <NewsletterForm side />
            </Grid.Row>
          )}
          {renderSide && renderSide()}
        </SideContainer>
      </Grid.Row>
    </Container>
  );
}

RightSideLayout.propTypes = {
  style: PropTypes.object,
  sideWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
  topOffset: PropTypes.number,
  bottomOffset: PropTypes.number,
  renderSide: PropTypes.func,
  gap: PropTypes.number,
  showNewsletter: PropTypes.bool,
};

RightSideLayout.defaultProps = {
  style: {},
  sideWidth: 290,
  gap: 60,
  topOffset: 25,
  bottomOffset: 0,
  renderSide: null,
  showNewsletter: true,
};

export default RightSideLayout;
