import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  border-radius: 8px;
  background-color: ${Colors.MENU_BACKGROUND};
  padding: 30px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Category = styled.span`
  color: ${(props) => (props.active ? Colors.LIGHT_PRIMARY : Colors.DROPDOWN_ITEM)};
  font-size: 14px;
  font-weight: 700;
  line-height: 38px;

  &:hover {
    cursor: pointer;
    color: ${Colors.LIGHT_PRIMARY};
  }
`;
