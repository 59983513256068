import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Paging} from "constant";
import _ from "lodash";

function useArticles() {
  const {articles, isLoaded, isLoading, selectedCategories} = useSelector(state => state.articles);
  const [paging, setPaging] = useState(Paging.MASONRY_INITIAL_PAGING);
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const pagedArticles = _.take(filteredArticles, paging.offset + paging.limit);

  useEffect(() => {
    const filteredArticles = articles.filter(article => selectedCategories.includes(article.label));
    setFilteredArticles(filteredArticles);
    setPaging(Paging.MASONRY_INITIAL_PAGING);
  }, [selectedCategories]);

  const loadMore = () => {
    setPaging({...paging, offset: paging.offset + Paging.LIMIT})
  };

  return {
    articles: pagedArticles,
    isLoaded,
    allArticles: articles,
    count: filteredArticles.length,
    isLoading,
    loadMore,
    canLoadMore: !isLoading && pagedArticles.length < filteredArticles.length,
  };
}

export default useArticles;
