import React, {useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {InformationBox, Modal, Review} from "components";
import {ReviewActions} from "actions";
import {SharedTypes} from "utils";
import {Typography, Grid} from "styles";
import Statistics from "./Statistics";
import {Container, Side} from './styles';
import {ReviewForm} from "containers";
import {RightSideLayout} from "layouts";

function ReviewsPage(props) {
  const {reviews, isLoading, addReview, isReviewLoading} = props;
  const [modalOpen, setModalOpen] = useState(false);

  const handleSubmit = (value) => {
    addReview(value, () => setModalOpen(false));
  };

  const renderSide = () => (
    <Side>
      <Grid.Row mb={20}>
        <InformationBox
          buttonLabel="Rašyti atsiliepimą"
          description="Mums rūpi jūsų nuomonė, pastabos ar likę kelionių įspūdžiai."
          onClick={() => setModalOpen(true)}
          title="Pasidalinkite atsiliepimu"
        />
      </Grid.Row>
      <Grid.Row>
        <Statistics/>
      </Grid.Row>
    </Side>
  );

  return (
    <Container>
      <Grid.Container>
        <Grid.Row mb={7}>
          <Typography.PageTitle>Kelionių atsiliepimai</Typography.PageTitle>
        </Grid.Row>
        <Grid.Row mb={63}>
          <Typography.Description>
            Čia nutūpia istorijos, keliautojų lūpomis kalba prisiminimai ir pasakojimai virsta patarimais.
            Ir tai ne šiaip sau kelionių tinklaraštis. Tai – nuoširdūs interviu prie puodelio kavos ir ilgi
            pašnekesiai gvildenant gražiausius pasaulio kampelius. Baltic Tours kelionių blogas – vieta, kur
            rasite viską: nuo karščiausių turizmo industrijos naujienų iki stereotipus laužančių keliautojų
            pasakojimų.
          </Typography.Description>
        </Grid.Row>
        <RightSideLayout showNewsletter={false} renderSide={renderSide}>
          {reviews.map(review => (
            <Grid.Row mb={60} key={review.id}>
              <Review isLoading={isLoading} review={review}/>
            </Grid.Row>
          ))}
        </RightSideLayout>
      </Grid.Container>
      <Modal visible={modalOpen} onClose={() => setModalOpen(false)}>
        <ReviewForm onSubmit={handleSubmit} isLoading={isReviewLoading}/>
      </Modal>
    </Container>
  );
}

ReviewsPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  addReview: PropTypes.func.isRequired,
  isReviewLoading: PropTypes.bool.isRequired,
  reviews: PropTypes.arrayOf(SharedTypes.ReviewType).isRequired,
};

const mapStateToProps = (state) => {
  const {reviews, review} = state;
  const {isLoading} = reviews;
  return {isLoading, reviews: reviews.reviews, isReviewLoading: review.isLoading};
};

const mapDispatchToProps = {
  addReview: ReviewActions.addReview,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsPage);
