const getText = (zeroText, oneText, multiText, count) => {
  const lastDigit = count % 10;
  if (count > 10 && count < 20) return zeroText;
  if (lastDigit === 1) return oneText;
  else if (lastDigit === 0) return zeroText;
  else if (lastDigit > 1 && lastDigit < 10) return multiText;
  return count;
};

export const nightsText = (count) => {
  return getText(`${count} naktų`, `${count} naktis`, `${count} naktys`, count);
};

export const offersText = (count) => {
  return getText(`${count} pasiūlymų`, `${count} pasiūlymas`, `${count} pasiūlymai`, count);
};

export const passengersText = (count) => {
  return getText(`${count} keleivių`, `${count} keleivis`, `${count} keleiviai`, count);
};

export const individualsText = (count) => {
  return getText(`${count} asmenų`, `${count} asmeniui`, `${count} asmenims`, count);
};

export const countriesText = (count) => {
  return getText(`${count} šalių`, `${count} šalį`, `${count} šalių`, count);
};

export const offersLongText = (count) => {
  const findText = getText('rastų', 'rastas', 'rasti', count);
  const travelsText = getText('kelionių', 'kelionės', 'kelionių', count);
  const offersText = getText(`pasiūlymų`, `pasiūlymas`, `pasiūlymai`, count);
  return `${count} ${findText} ${travelsText} ${offersText}`
};
