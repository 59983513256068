import styled from 'styled-components/macro';
import { Colors, Grid, Media } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 390px;
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  transition: 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 11px 0 rgba(13, 115, 185, 0.37);
  }

  @media only screen and (max-width: ${Media.TABLET}px) {
    max-width: 477px;
  }

  @media only screen and (max-width: ${Media.MOBILE}px) {
    max-width: 370px;
  }

  @media only screen and (max-width: ${Media.SMALL_MOBILE}px) {
    max-width: 330px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 246px;
  display: block;
  object-fit: cover;
  border-radius: 8px;
`;

export const Details = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const TitleLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.TITLE};

  &:hover {
    text-decoration: underline;
  }
`;

export const Title = styled(LinesEllipsis)`
  font-size: 21px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const NightsDateCol = styled(Grid.Col)`
  @media only screen and (max-width: 1110px) {
    flex-direction: row;
    justify-content: center;
  }
  @media only screen and (max-width: ${Media.TABLET}px) {
  }
`;

export const Date = styled.span`
  font-size: 12px;
  color: ${Colors.TITLE};
`;

export const Nights = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${Colors.TITLE};

  @media only screen and (max-width: 1110px) {
    padding-right: 10px;
  }
`;

export const PriceRow = styled(Grid.Row)`
  @media only screen and (max-width: 1110px) {
    && {
      flex-direction: row;
      align-items: center;

      div {
        margin: 0;
      }
      div:not(:first-child) {
        padding-left: 5px;
      }
    }
  }
`;

export const From = styled.span`
  font-size: 12px;
  padding-top: 6px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Icon = styled.img``;

export const Description = styled(LinesEllipsis)`
  font-size: 12px;
  height: 30px;
  color: ${Colors.BLACK};
`;
