import React, {useState} from 'react';
import {Badge} from "components";
import {Container, ShowMoreLine, ButtonContainer, Content} from "./styles";
import PropTypes from "prop-types";

function Expandable(props) {
  const {height, children} = props;
  const [showMore, setShowMore] = useState(true);

  return (
    <Container>
      <Content showMore={showMore} height={height}>
        {children}
      </Content>
      <ButtonContainer showMore={showMore}>
        <Badge
          selected
          onClick={() => setShowMore(!showMore)}
          title={showMore ? 'Rodyti daugiau' : 'Rodyti mažiau'}
        />
        <ShowMoreLine/>
      </ButtonContainer>
    </Container>
  );
}

Expandable.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
};

Expandable.defaultProps = {
  height: 240,
};

export default Expandable;
