import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Icon = styled.img``;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: ${Colors.SEASON};
  margin-bottom: 15px;
`;

export const MonthButton = styled.button`
  height: 26px;
  padding: 0 25px;
  border-radius: 13px;
  background-color: ${(props) => (props.active ? Colors.PRIMARY : Colors.SEASON)};
  font-size: 12px;
  font-weight: 600;
  color: ${Colors.WHITE};

  &:hover {
    background-color: ${Colors.LIGHT_PRIMARY};
  }
`;
