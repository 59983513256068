import React from 'react';
import {Container} from "./styles";
import {HorizontalSlider, OfferCard, OfferCardSkeleton} from "components";
import {SectionLayout} from "layouts";
import {useOffers} from "hooks";

function LastMinuteOffers() {
  const {offers, isLoading} = useOffers({filter: {isLastMinute: true}});

  return (
    <Container>
      <SectionLayout
        title="Paskutinės minutės pasiūlymai"
        center
        description="Norėdami matyti tikslias kainas, paieškoje įveskite tikslią kelionės kryptį, datą ir keleivių skaičių"
      >
        <HorizontalSlider
          renderSkeleton={() => <OfferCardSkeleton/>}
          isLoading={isLoading}
          spaceBetween={30}
          renderSlide={(offer) => <OfferCard offer={offer}/>}
          slides={offers}
        />
      </SectionLayout>
    </Container>
  );
}

export default LastMinuteOffers;
