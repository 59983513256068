import React from 'react';
import PropTypes from 'prop-types';
import { SharedTypes, TextUtils } from 'utils';
import {
  AdvanceLabel,
  Catering,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Title,
} from './styles';
import { Colors, Grid } from 'styles';
import Price from '../Price';
import Radio from '../Radio';

function RoomSelect(props) {
  const { rooms, value, onChange } = props;

  const renderRoom = (room) => (
    <TableRow key={room.id} selected={room.id === value} onClick={() => onChange(room.id)}>
      <TableCell>
        <Title maxLine={2} text={TextUtils.decode('Standart')} />
      </TableCell>
      <TableCell>
        <Catering maxLine={2} text={TextUtils.decode('Ultra viskas iskaiciuota')} />
      </TableCell>
      <TableCell>
        <Grid.StaticRow>
          <Grid.StaticCol mr={25}>
            <Radio checked={room.id === value} disabled name={room.name} />
          </Grid.StaticCol>
          <Grid.StaticCol>
            <Grid.StaticRow mb={10}>
              <Price fontSize={18} color={Colors.TITLE} value={room.price} />
            </Grid.StaticRow>
            <Grid.StaticRow>
              <Grid.StaticCol mr={5}>
                <AdvanceLabel>Avansas: </AdvanceLabel>
              </Grid.StaticCol>
              <Grid.StaticCol>
                <Price fontSize={12} fontWeight={400} color={Colors.DROPDOWN_ITEM} value={room.advance} />
              </Grid.StaticCol>
            </Grid.StaticRow>
          </Grid.StaticCol>
        </Grid.StaticRow>
      </TableCell>
    </TableRow>
  );

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Kambarys</TableHeaderCell>
          <TableHeaderCell>Maitinimas</TableHeaderCell>
          <TableHeaderCell>Kaina asmeniui</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>{[...rooms, ...rooms].map((room, i) => renderRoom(i === 0 ? { ...room, id: 123 } : room))}</TableBody>
    </Table>
  );
}

RoomSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  rooms: PropTypes.arrayOf(SharedTypes.OfferRoomType).isRequired,
};

RoomSelect.defaultProps = {
  value: null,
};

export default RoomSelect;
