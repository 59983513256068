import React, {useEffect} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {AuthenticationActions} from "actions";
import {LoginForm} from "containers";
import {AuthenticationWrapper, FacebookButton, GoogleButton} from "components";
import {Form, Grid} from 'styles';
import {BottomLink, Divider, DividerLabel} from './styles';
import {useLocation} from 'react-router-dom';

function LoginPage(props) {
  const {login, isLoading, error, resetError, providerLogin} = props;
  let location = useLocation();

  useEffect(() => {
    resetError();
  }, [location]);

  const handleLogin = (request) => {
    login(request);
  };

  return (
    <AuthenticationWrapper
      description="Dar neturite Baltictours paskyros?"
      title="Prisijunkite"
      link={{label: 'Registruokitės', to: '/registracija'}}
    >
      <Grid.Row mb={15}>
        <FacebookButton onSuccess={providerLogin}/>
      </Grid.Row>
      <Grid.Row mb={25}>
        <GoogleButton onSuccess={providerLogin}/>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Divider>
          <DividerLabel>Arba</DividerLabel>
        </Divider>
      </Grid.Row>
      {error && (
        <Grid.Row justifyContent="center" mb={15}>
          <Form.Error>{error}</Form.Error>
        </Grid.Row>
      )}
      <Grid.Row mb={15}>
        <LoginForm isLoading={isLoading} onSubmit={handleLogin}/>
      </Grid.Row>
      <Grid.Row justifyContent="center">
        <BottomLink to="/slaptazodzio-priminimas">Pamiršote slaptažodį?</BottomLink>
      </Grid.Row>
    </AuthenticationWrapper>
  );
}

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  providerLogin: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetError: PropTypes.func.isRequired,
  error: PropTypes.string,
};

LoginPage.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => {
  const {authentication} = state;
  const {isLoading, error} = authentication;
  return {isLoading, error};
};

const mapDispatchToProps = {
  login: AuthenticationActions.login,
  resetError: AuthenticationActions.resetError,
  providerLogin: AuthenticationActions.providerLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
