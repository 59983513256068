import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
`;

export const ShowMoreLine = styled.div`
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  top: -15px;
  height: 1px;
  z-index: 1;
  background-color: ${Colors.MENU_SHADOW};
`;

export const ShowButtonContainer = styled.div`
  padding: 0 15px;
  z-index: 2;
  background-color: ${Colors.MENU_BACKGROUND};
`;

export const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
