import React from 'react';
import PropTypes from "prop-types";
import {Buttons, Grid} from "styles";
import {Container, Label, Icon} from './styles';
import Loader from "../Loader";

function AuthenticationButton(props) {
  const {style, label, onClick, disabled, type, loading, icon, theme} = props;

  const renderContent = () => {
    if (loading) {
      return <Loader/>;
    }

    return (
      <Grid.Row>
        {icon && <Icon src={icon}/>}
        <Label theme={theme}>{label}</Label>
      </Grid.Row>
    );
  };

  return (
    <Container theme={theme} type={type} disabled={disabled || loading} onClick={onClick} style={style}>
      {renderContent()}
    </Container>
  );
}

AuthenticationButton.propTypes = {
  style: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string,
  theme: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

AuthenticationButton.defaultProps = {
  style: {},
  icon: null,
  disabled: false,
  loading: false,
  type: 'button',
  onClick: () => {},
  theme: Buttons.PRIMARY,
};

export default AuthenticationButton;
