import React from 'react';
import NewsletterForm from "../../NewsletterForm";
import {Container} from './styles';

function Newsletter() {
  return (
    <Container>
      <NewsletterForm/>
    </Container>
  );
}

export default Newsletter;
