import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import _ from "lodash";

function useCountries(limit) {
  const {countries, isLoading, isLoaded} = useSelector(state => state.destinations);
  const [pagedCountries, setPagedCountries] = useState(countries);

  useEffect(() => {
    const newPagedCountries = limit ? _.take(countries, limit) : countries;
    setPagedCountries(newPagedCountries);
  }, [limit, isLoaded]);

  return {
    countries: pagedCountries,
    isLoading,
    isLoaded,
    count: countries.length,
  };
}

export default useCountries;
