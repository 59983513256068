import React from 'react';
import {Container, Details, ImagesContainer} from './styles';
import {Grid} from 'styles';
import RatingSkeleton from "../RatingSkeleton";

function OffersListItemSkeleton() {
  return (
    <Container>
      <ImagesContainer/>
      <Details>
        <Grid.Row mb={18}>
          <RatingSkeleton/>
        </Grid.Row>
        <Grid.Row mb={15}>
          <Grid.Skeleton width={100} height={20} animationDelay={0.25}/>
        </Grid.Row>
        <Grid.Row mb={35}>
          <Grid.Skeleton height={80} animationDelay={0.2}/>
        </Grid.Row>
        <Grid.Row justifyContent="flex-end">
          <Grid.Skeleton width={100} height={36} animationDelay={0.5}/>
        </Grid.Row>
      </Details>
    </Container>
  )
}

export default OffersListItemSkeleton;
