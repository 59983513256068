import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ProfileWrapper} from "components";
import {ProfileMessagePage, ProfileMessagesPage, ProfilePage, ProfileTripsPage} from "pages";

function ProfileNavigation() {
  return (
    <ProfileWrapper>
      <Switch>
        <Route exact path='/profilis' component={ProfilePage}/>
        <Route exact path='/profilis/keliones' component={ProfileTripsPage}/>
        <Route exact path='/profilis/zinutes' component={ProfileMessagesPage}/>
        <Route exact path='/profilis/zinutes/:id' component={ProfileMessagePage}/>
      </Switch>
    </ProfileWrapper>
  );
}

export default ProfileNavigation;
