import React from "react";
import PropTypes from "prop-types";
import {DateUtils, SharedTypes} from "utils";
import {Grid} from 'styles';
import {Date, Description, Name} from './styles';
import Response from "./Response";
import RatingInput from "../RatingInput";
import Gallery from "../Gallery";

function Review(props) {
  const {review} = props;

  return (
    <Grid.Row alignItems="flex-start">
      <Grid.Col mr={60}>
        <Grid.Row>
          <Name>{review.name}</Name>
        </Grid.Row>
        <Grid.Row mb={20}>
          <Date>{DateUtils.formatDate(review.date)}</Date>
        </Grid.Row>
        <Grid.Row mb={20}>
          <RatingInput readonly size={10} value={review.rating}/>
        </Grid.Row>
      </Grid.Col>
      <Grid.Col flex={1}>
        <Grid.Row>
          <Description>{review.message}</Description>
        </Grid.Row>
        {review.images && (
          <Grid.Row mt={20}>
            <Gallery images={review.images}/>
          </Grid.Row>
        )}
        {review.response && (
          <Grid.Row>
            <Response response={review.response}/>
          </Grid.Row>
        )}
      </Grid.Col>
    </Grid.Row>
  )
}

Review.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  review: SharedTypes.ReviewType.isRequired,
};

export default Review;
