import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {DestinationsService} from "services";

const defaultProps = {
  parentId: null,
};

function useDestinations(props = defaultProps) {
  const {parentId} = props;
  const {destinations, isLoading, isLoaded, count} = useSelector(state => state.destinations);
  const [filteredDestinations, setFilteredDestinations] = useState(DestinationsService.getCities(parentId));

  useEffect(() => {
    const newFilteredDestinations = DestinationsService.getCities(parentId);
    setFilteredDestinations(newFilteredDestinations);
  }, [parentId]);

  return {
    destinations: filteredDestinations,
    allDestinations: destinations,
    isLoading,
    isLoaded,
    count: filteredDestinations.length,
    canLoadMore: !isLoading && filteredDestinations.length < count,
  };
}

export default useDestinations;
