import {TvsPageTypes} from "types";
import {TvsPageService} from "services";

export function getTvsPage(slug) {
  return async dispatch => {
    try {
      dispatch({type: TvsPageTypes.GET_TVS_PAGE_START});
      const tvsPage = await TvsPageService.getPage(slug);
      dispatch({type: TvsPageTypes.GET_TVS_PAGE, payload: {tvsPage}});
    } catch (exception) {
      dispatch({type: TvsPageTypes.GET_TVS_PAGE_ERROR});
    }
  };
}
