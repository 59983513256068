export const LOGIN_START = 'LOGIN_START';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const PROVIDER_LOGIN_START = 'PROVIDER_LOGIN_START';
export const PROVIDER_LOGIN = 'PROVIDER_LOGIN';
export const PROVIDER_LOGIN_ERROR = 'PROVIDER_LOGIN_ERROR';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER = 'REGISTER';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const GET_LOGGED_USER_START = 'GET_LOGGED_USER_START';
export const GET_LOGGED_USER = 'GET_LOGGED_USER';
export const GET_LOGGED_USER_ERROR = 'GET_LOGGED_USER_ERROR';

export const LOGOUT = 'LOGOUT';

export const REFRESH_TOKEN_START = 'REFRESH_TOKEN_START';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export const REMIND_PASSWORD_START = 'REMIND_PASSWORD_START';
export const REMIND_PASSWORD = 'REMIND_PASSWORD';
export const REMIND_PASSWORD_ERROR = 'REMIND_PASSWORD_ERROR';

export const RESET_ERROR = 'RESET_ERROR';
