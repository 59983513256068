import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  padding: 40px 0;
  background-color: ${Colors.MENU_BACKGROUND};
`;
