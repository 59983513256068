import tvsAxiosInstance from "../tvsAxiosInstance";

class FaqsService {
  static async getFaqs() {
    const response = await tvsAxiosInstance.get(`/faqs`);

    const faqs = response.faqs.map(faq => {
      return {title: faq.q, content: faq.a, category: faq.topic};
    });

    const categories = response.categories.map(category => {
      return category.name;
    });

    return {categories, faqs};
  }
}

export default FaqsService;
