import update from 'immutability-helper';
import {TvsPageTypes} from 'types';

const initialState = {
  tvsPage: null,
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case TvsPageTypes.GET_TVS_PAGE_START: {
      return update(state, {
        isLoading: {$set: true},
        isLoaded: {$set: false},
      });
    }

    case TvsPageTypes.GET_TVS_PAGE: {
      return update(state, {
        tvsPage: {$set: payload.tvsPage},
        isLoading: {$set: false},
        isLoaded: {$set: true},
      });
    }

    case TvsPageTypes.GET_TVS_PAGE_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
