import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding-top: 25px;
  padding-bottom: 70px;
`;

export const Logo = styled.img`
  height: 100%;
  width: auto;
`;

export const LogoLink = styled(Link)`
  height: 33px;
`;
