import React from 'react';
import {Images} from 'styles';
import {Partner, Container, PartnerWrapper} from "./styles";

function Partners() {
  return (
    <Container>
      <PartnerWrapper>
          <Partner height={29} src={Images.SWEDBANK}/>
      </PartnerWrapper>
      <PartnerWrapper>
          <Partner height={34} src={Images.SEB}/>
      </PartnerWrapper>
      <PartnerWrapper>
          <Partner height={23} src={Images.LUMINOR}/>
      </PartnerWrapper>
      <PartnerWrapper>
          <Partner height={25} src={Images.DANSKE}/>
      </PartnerWrapper>
      <PartnerWrapper>
          <Partner height={35} src={Images.SB}/>
      </PartnerWrapper>
      <PartnerWrapper>
          <Partner height={37} src={Images.CITADELE}/>
      </PartnerWrapper>
      <PartnerWrapper>
          <Partner height={26} src={Images.PAYSERA}/>
      </PartnerWrapper>
    </Container>
  );
}

export default Partners;
