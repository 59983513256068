import update from 'immutability-helper';
import {ArticlesTypes} from 'types';

const initialState = {
  articles: [],
  categories: [],
  count: 0,
  selectedCategories: [],
  isLoading: false,
  isLoaded: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ArticlesTypes.GET_ARTICLES_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case ArticlesTypes.SET_SELECTED_CATEGORIES: {
      return update(state, {
        selectedCategories: {$set: payload.selectedCategories},
      });
    }

    case ArticlesTypes.GET_ARTICLES: {
      return update(state, {
        articles: {$set: payload.articles},
        count: {$set: payload.articles.length},
        categories: {$set: payload.categories},
        isLoading: {$set: false},
        isLoaded: {$set: true},
      });
    }

    case ArticlesTypes.GET_ARTICLES_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
