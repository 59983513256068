import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  border-radius: 6px;
  border: 1px solid ${Colors.BORDER};
  padding: 25px;
  width: 100%;
`;

export const Name = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: ${Colors.BLACK};
`;

export const Description = styled.p`
  font-size: 14px;
  margin-bottom: 15px;
  color: ${Colors.INCLUSIVE_TITLE};
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;

    > span {
      text-decoration: underline;
    }
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  margin-top: 2px;
`;

export const LinkText = styled.span`
  color: ${Colors.LIGHT_PRIMARY};
  font-size: 14px;
  font-weight: 600;
`;
