import * as PartnersCategories from './partnersCategories';

const PARTNERS = [
  {
    id: 1,
    icon: 'https://baltic.webstrum.com/uploads/5e15857b5e79c.png',
    category: PartnersCategories.BRAND
  },
  {
    id: 2,
    icon: 'https://baltic.webstrum.com/uploads/5e15857b5f29a.png',
    category: PartnersCategories.BRAND
  },
  {
    id: 3,
    icon: 'https://baltic.webstrum.com/uploads/5e15857b6a3cb.png',
    category: PartnersCategories.BRAND
  },
  {
    id: 4,
    icon: 'https://baltic.webstrum.com/uploads/5e15857b77b40.png',
    category: PartnersCategories.BRAND
  },
  {
    id: 5,
    icon: 'https://baltic.webstrum.com/uploads/5e1584962507d.png',
    category: PartnersCategories.PARTNER
  },
  {
    id: 6,
    icon: 'https://baltic.webstrum.com/uploads/5e1584963fee6.png',
    category: PartnersCategories.PARTNER
  },
  {
    id: 7,
    icon: 'https://baltic.webstrum.com/uploads/5e1584960e98b.png',
    category: PartnersCategories.PARTNER
  },
  {
    id: 8,
    icon: 'https://baltic.webstrum.com/uploads/5e15849623f47.png',
    category: PartnersCategories.PARTNER
  },
  {
    id: 9,
    icon: 'https://baltic.webstrum.com/uploads/5e15849605119.png',
    category: PartnersCategories.PARTNER
  },
  {
    id: 10,
    icon: 'https://baltic.webstrum.com/uploads/5e15849604a62.png',
    category: PartnersCategories.PARTNER
  },
  {
    id: 11,
    icon: 'https://baltic.webstrum.com/images/stipriausi-lietuvoje.gif?15c93a796f781485ca1b7ada7f504dee',
    category: PartnersCategories.TROPHY
  },
];

export default PARTNERS;
