import MESSAGES from "./mocks";

class MessagesService {
  static async getMessages() {
    return Promise.resolve(MESSAGES);
  }

  static getMessageById(id) {
    return MESSAGES.data.find(message => message.id === id);
  }
}

export default MessagesService;
