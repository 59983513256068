import {Grid, Typography} from "styles";
import {Container, Address} from "./styles";
import React from "react";
import {Map} from "components";
import {SharedTypes} from "utils";

function Location(props) {
  const {location} = props;

  // const renderClosestObjects = (objects) => {
  //   return objects.map((object, index) => (
  //     <Item key={index}>
  //       <ItemSide>
  //         <Icon src={Images.ARROW_RIGHT_LONG}/>
  //         <Name>{object.name}</Name>
  //       </ItemSide>
  //       <ItemSide>
  //         <Distance>{NumberUtils.getDistance(object.distance)}</Distance>
  //       </ItemSide>
  //     </Item>
  //   ));
  // };

  return (
    <Container>
      <Grid.Row mb={5}>
        <Typography.PageTitle>Vieta</Typography.PageTitle>
      </Grid.Row>
      <Grid.Row mb={10}>
        <Typography.SecondaryDescription>
          {location.description}
        </Typography.SecondaryDescription>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Address>{location.address}</Address>
      </Grid.Row>
      <Grid.Row mb={30}>
        <Map
          address={location.address}
          longitude={location.longitude}
          latitude={location.latitude}
          height={280}
        />
      </Grid.Row>
      {/*<Grid.Row alignItems="flex-start">*/}
      {/*  <Grid.Col flex={1}>*/}
      {/*    <Section>*/}
      {/*      <Grid.Row mb={25}>*/}
      {/*        <Title>Artimiausi lankomi objektai</Title>*/}
      {/*      </Grid.Row>*/}
      {/*      <Grid.Row mb={15}>*/}
      {/*        <Grid.Col flex={1}>*/}
      {/*          {renderClosestObjects(location.closestObjects)}*/}
      {/*        </Grid.Col>*/}
      {/*      </Grid.Row>*/}
      {/*    </Section>*/}
      {/*    <Section>*/}
      {/*      <Grid.Row mb={25}>*/}
      {/*        <Title>Artimiausi oro uostai</Title>*/}
      {/*      </Grid.Row>*/}
      {/*      <Grid.Row mb={15}>*/}
      {/*        <Grid.Col flex={1}>*/}
      {/*          {renderClosestObjects(location.closestAirports)}*/}
      {/*        </Grid.Col>*/}
      {/*      </Grid.Row>*/}
      {/*    </Section>*/}
      {/*  </Grid.Col>*/}
      {/*  <Grid.Col ml={60} flex={1}>*/}
      {/*    <Section>*/}
      {/*      <Grid.Row mb={25}>*/}
      {/*        <Title>Restoranai ir turgūs</Title>*/}
      {/*      </Grid.Row>*/}
      {/*      <Grid.Row mb={15}>*/}
      {/*        <Grid.Col flex={1}>*/}
      {/*          {renderClosestObjects(location.closestMarketplaces)}*/}
      {/*        </Grid.Col>*/}
      {/*      </Grid.Row>*/}
      {/*    </Section>*/}
      {/*    <Section>*/}
      {/*      <Grid.Row mb={25}>*/}
      {/*        <Title>Gamtos grožis</Title>*/}
      {/*      </Grid.Row>*/}
      {/*      <Grid.Row mb={15}>*/}
      {/*        <Grid.Col flex={1}>*/}
      {/*          {renderClosestObjects(location.closestNatureObjects)}*/}
      {/*        </Grid.Col>*/}
      {/*      </Grid.Row>*/}
      {/*    </Section>*/}
      {/*    <Section>*/}
      {/*      <Grid.Row mb={25}>*/}
      {/*        <Title>Paplūdimiai kaimynystėje</Title>*/}
      {/*      </Grid.Row>*/}
      {/*      <Grid.Row mb={15}>*/}
      {/*        <Grid.Col flex={1}>*/}
      {/*          {renderClosestObjects(location.closestBeaches)}*/}
      {/*        </Grid.Col>*/}
      {/*      </Grid.Row>*/}
      {/*    </Section>*/}
      {/*  </Grid.Col>*/}
      {/*</Grid.Row>*/}
    </Container>
  );
}

Location.propTypes = {
  location: SharedTypes.OfferLocationType.isRequired,
};

export default Location;
