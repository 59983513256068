import React from 'react';
import {Container, Content} from "./styles";
import {Button, HorizontalSlider, ReviewCard, ReviewCardSkeleton} from "components";
import {Grid} from "styles";
import {useHistory} from 'react-router-dom';
import {useReviews} from "hooks";
import {SectionLayout} from "layouts";

function Reviews() {
  const {reviews, isLoading} = useReviews();
  const history = useHistory();

  return (
    <Container>
      <SectionLayout
        description="Daugiau kaip 10 000 laimingų poilsiautojų"
        center
        title="Klientai apie mus kalba"
      >
        <Content>
          <Grid.Row mb={30}>
            <HorizontalSlider
              renderSkeleton={() => <ReviewCardSkeleton/>}
              isLoading={isLoading}
              renderSlide={(review) => <ReviewCard review={review}/>}
              slides={reviews}
            />
          </Grid.Row>
          <Grid.Row alignItems="center" justifyContent="center">
            <Button large label="Rodyti visus atsiliepimus" onClick={() => history.push('/atsiliepimai')}/>
          </Grid.Row>
        </Content>
      </SectionLayout>
    </Container>
  );
}

export default Reviews;
