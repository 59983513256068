import styled from 'styled-components/macro';
import { Colors } from 'styles';
import { Link } from 'react-router-dom';
import hexToRgba from 'hex-to-rgba';
import LinesEllipsis from 'react-lines-ellipsis';

export const Container = styled.div`
  width: 100%;
  height: ${(props) => (props.large ? 510 : 250)}px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  padding: ${(props) => (props.large ? 60 : 30)}px;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  font-size: ${(props) => (props.large ? 36 : 24)}px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    background: url(${(props) => props.backgroundImage}) no-repeat center/cover;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: ${hexToRgba(Colors.BLACK, 0.3)};
  }
`;

export const TitleLink = styled(Link)`
  text-decoration: none;
`;

export const Title = styled(LinesEllipsis)`
  line-height: 1.42;
  color: ${Colors.WHITE};
  font-size: inherit;

  &:hover {
    text-decoration: underline;
  }
`;
