import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: 100%;
`;

export const Summary = styled.div`
  border: 1px solid ${Colors.BORDER};
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
`;
