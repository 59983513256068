import {useSelector} from "react-redux";
import {DestinationsService} from "services";
import {useEffect, useState} from "react";

function useDestination(id) {
  const {isLoaded} = useSelector(state => state.destinations);
  const [destination, setDestination] = useState(DestinationsService.getDestinationById(id));

  useEffect(() => {
    setDestination(DestinationsService.getDestinationById(id));
  }, [id]);

  return {destination, isLoaded};
}

export default useDestination;
