import React from 'react';
import PropTypes from "prop-types";
import {Buttons, Images} from "styles";
import {Container} from './styles';
import {FACEBOOK_TOKEN} from "../../config";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AuthenticationButton from "../AuthenticationButton";

function FacebookButton(props) {
  const {onSuccess} = props;

  const handleCallback = (response) => {
    const {accessToken} = response;
    if (response.accessToken) {
      onSuccess({provider: 'facebook', token: accessToken});
    }
  };

  const renderFacebookButton = (renderProps) => (
    <AuthenticationButton
      icon={Images.FACEBOOK}
      theme={Buttons.FACEBOOK}
      label="Tęskite su Facebook"
      onClick={renderProps.onClick}
    />
  );

  return (
    <Container>
      <FacebookLogin
        fields="name,email,picture"
        appId={FACEBOOK_TOKEN}
        render={renderFacebookButton}
        autoLoad={false}
        callback={handleCallback}
      />
    </Container>
  );
}

FacebookButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default FacebookButton;
