import React from "react";
import PropTypes from 'prop-types';
import {Container, ButtonContainer, DropdownContainer} from './styles';
import autoBind from "auto-bind";
import {Grid} from 'styles'
import {Button} from 'components'

class Dropdown extends React.Component {

  constructor(props) {
    super(props);

    this.dropdownElementRef = React.createRef();

    this.state = {
      active: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    const dropdownItemsElements = document.getElementsByClassName('dropdown-item');
    for (let dropdownItemElement of dropdownItemsElements) {
      dropdownItemElement.addEventListener('mouseup', this.handleDropdownItemClick);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);

    const dropdownItemsElements = document.getElementsByClassName('dropdown-item');
    for (let dropdownItemElement of dropdownItemsElements) {
      dropdownItemElement.removeEventListener('mouseup', this.handleDropdownItemClick);
    }
  }

  handleDropdownItemClick(event) {
    const {active} = this.state;

    if (this.dropdownElementRef && this.dropdownElementRef.current.contains(event.target) && active) {
      this.close();
    }
  }

  handleClickOutside(event) {
    const {active} = this.state;

    if (this.dropdownElementRef && !this.dropdownElementRef.current.contains(event.target) && active) {
      this.close();
    }
  }

  handleButtonClick() {
    const {disabled} = this.props;
    const {active} = this.state;

    if (!disabled) {
      this.setState({active: !active})
    }
  }

  close() {
    const {onClose} = this.props;
    this.setState({active: false});
    onClose();
  }

  render() {
    const {left, fullWidth, transparent, style, renderButton, renderDropdown, forceOpen, withButton} = this.props;
    const {active} = this.state;

    return (
      <Container ref={this.dropdownElementRef} style={style}>
        {!forceOpen && <ButtonContainer active={active} onClick={this.handleButtonClick}>
          {renderButton()}
        </ButtonContainer>}
        <DropdownContainer forceOpen={forceOpen} left={left} fullWidth={fullWidth} transparent={transparent} active={forceOpen ? forceOpen : active}>
          {renderDropdown()}
          {withButton &&  <Grid.Mobile>
            <Grid.Row mt={60} mb={27} justifyContent='flex-end'>
              <Button small label='Išsaugoti' onClick={()=>{this.close()}}/>
            </Grid.Row>
          </Grid.Mobile>
          }
        </DropdownContainer>
      </Container>
    );
  }
}

Dropdown.propTypes = {
  renderButton: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  renderDropdown: PropTypes.func.isRequired,
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  left: PropTypes.bool,
  forceOpen: PropTypes.bool,
  withButton: PropTypes.bool,
  style: PropTypes.object,
};

Dropdown.defaultProps = {
  style: {},
  onClose: () => {},
  fullWidth: true,
  disabled: false,
  left: false,
  transparent: false,
  forceOpen: false,
  withButton: false,
};

export default Dropdown;
