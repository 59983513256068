import update from 'immutability-helper';
import {MessagesTypes} from 'types';

const initialState = {
  messages: [],
  count: 0,
  isLoading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case MessagesTypes.GET_MESSAGES_START: {
      return update(state, {
        isLoading: {$set: true},
      });
    }

    case MessagesTypes.GET_MESSAGES: {
      return update(state, {
        messages: {$set: payload.messages},
        count: {$set: payload.count},
        isLoading: {$set: false},
      });
    }

    case MessagesTypes.GET_MESSAGES_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
