import React from "react";
import autoBind from "auto-bind";
import PropTypes from 'prop-types';
import {Validations} from "utils";
import Form from "../Form";
import {Grid} from "styles";
import {AuthenticationButton, Input} from "components";
import {Container} from "./styles";

class RegistrationForm extends Form {
  constructor(props) {
    super(props);

    this.validations = {
      name: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      surname: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      phone: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      email: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
      password: value => Validations.notEmpty(value, 'Laukas yra privalomas'),
    };

    this.state = {
      fields: {
        email: {value: '', error: null, dirty: false},
        surname: {value: '', error: null, dirty: false},
        name: {value: '', error: null, dirty: false},
        phone: {value: '', error: null, dirty: false},
        password: {value: '', error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  submit() {
    const {fields} = this.state;
    const {onSubmit} = this.props;

    if (this.validateForm()) {
      onSubmit({
        name: fields.name.value,
        surname: fields.surname.value,
        phone: fields.phone.value,
        email: fields.email.value,
        password: fields.password.value,
      });
    }
  }

  render() {
    const {fields} = this.state;
    const {isLoading} = this.props;

    return (
      <Container onSubmit={(event) => event.preventDefault()}>
        <Grid.Row alignItems="flex-start" mb={15}>
          <Grid.Col mr={10} flex={1}>
            <Input
              label="Vardas"
              placeholder="Įveskite vardą"
              onChange={this.handleChange}
              value={fields.name.value}
              dirty={fields.name.dirty}
              error={fields.name.error}
              name="name"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
          <Grid.Col flex={1}>
            <Input
              label="Pavardė"
              placeholder="Įveskite pavardę"
              onChange={this.handleChange}
              value={fields.surname.value}
              dirty={fields.surname.dirty}
              error={fields.surname.error}
              name="surname"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row alignItems="flex-start" mb={15}>
          <Grid.Col mr={10} flex={1}>
            <Input
              label="Telefono numeris"
              placeholder="Įveskite telefono numerį"
              onChange={this.handleChange}
              value={fields.phone.value}
              dirty={fields.phone.dirty}
              error={fields.phone.error}
              name="phone"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
          <Grid.Col flex={1}>
            <Input
              label="El. paštas"
              placeholder="Įveskite el. paštą"
              onChange={this.handleChange}
              value={fields.email.value}
              dirty={fields.email.dirty}
              error={fields.email.error}
              name="email"
              onBlur={this.handleBlur}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row mb={20}>
          <Input
            type="password"
            label="Slaptažodis"
            placeholder="Įveskite slaptažodį"
            onChange={this.handleChange}
            value={fields.password.value}
            dirty={fields.password.dirty}
            error={fields.password.error}
            name="password"
            onBlur={this.handleBlur}
          />
        </Grid.Row>
        <Grid.Row>
          <AuthenticationButton
            type="submit"
            loading={isLoading}
            onClick={this.submit}
            label="Registruotis"
          />
        </Grid.Row>
      </Container>
    );
  }
}

RegistrationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RegistrationForm;
