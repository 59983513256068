import React from 'react';
import PropTypes from "prop-types";
import {Container, Sender, Date, Title, Description} from './styles';
import {Grid} from 'styles';
import Avatar from "../Avatar";
import {SharedTypes} from "utils";

function MessagesListItem(props) {
  const {style, message, onClick} = props;

  return (
    <Container onClick={onClick} style={style}>
      <Grid.Row>
        <Grid.Col mr={20}>
          <Avatar size={80}/>
        </Grid.Col>
        <Grid.Col flex={1}>
          <Grid.Row fullWidth mb={10}>
            <Grid.Col flex={1}>
              <Sender>{message.sender}</Sender>
            </Grid.Col>
            <Grid.Col>
              <Date>{message.date}</Date>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Title>{message.title}</Title>
          </Grid.Row>
          <Grid.Row>
            <Description maxLine={2} text={message.description}/>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
}

MessagesListItem.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  message: SharedTypes.MessagesListItemType.isRequired,
};

MessagesListItem.defaultProps = {
  style: {},
};

export default MessagesListItem;
