import React from "react";
import {Container, Description, Icon, Title} from './styles';
import {Images, Grid} from "styles";

function Statistics() {
  return (
    <Container>
      <Grid.Row fullWidth justifyContent="center" mb={60}>
        <Grid.Col>
          <Grid.Row fullWidth justifyContent="center" mb={35}>
            <Icon src={Images.FLIGHT}/>
          </Grid.Row>
          <Grid.Row fullWidth justifyContent="center" mb={10}>
            <Title>255 tūkst.</Title>
          </Grid.Row>
          <Grid.Row fullWidth justifyContent="center">
            <Description>Klientų išlydime į keliones kasmet</Description>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row fullWidth justifyContent="center" mb={60}>
        <Grid.Col>
          <Grid.Row fullWidth justifyContent="center" mb={35}>
            <Icon src={Images.DIRECTION}/>
          </Grid.Row>
          <Grid.Row fullWidth justifyContent="center" mb={10}>
            <Title>64 kryptimis</Title>
          </Grid.Row>
          <Grid.Row fullWidth justifyContent="center">
            <Description>Išlydėjome keliautojus 2018 metais</Description>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row fullWidth justifyContent="center">
        <Grid.Col>
          <Grid.Row fullWidth justifyContent="center" mb={35}>
            <Icon src={Images.PEOPLE}/>
          </Grid.Row>
          <Grid.Row fullWidth justifyContent="center" mb={10}>
            <Title>28 metus</Title>
          </Grid.Row>
          <Grid.Row fullWidth justifyContent="center">
            <Description>Kuriame kelionių melodijas</Description>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
}

export default Statistics;
