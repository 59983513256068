import {AuthenticationTypes} from "types";
import {AuthenticationService} from "services";

export function register(request) {
  return async dispatch => {
    try {
      dispatch({type: AuthenticationTypes.REGISTER_START});
      const {token} = await AuthenticationService.register(request);
      const profile = await AuthenticationService.getLoggedUser(token);
      dispatch({type: AuthenticationTypes.REGISTER, payload: {token, profile}});
    } catch (exception) {
      const {message} = exception;
      dispatch({type: AuthenticationTypes.REGISTER_ERROR, payload: {error: message}});
    }
  };
}

export function login(request) {
  return async dispatch => {
    try {
      dispatch({type: AuthenticationTypes.LOGIN_START});
      const {token} = await AuthenticationService.login(request);
      const profile = await AuthenticationService.getLoggedUser(token);
      dispatch({type: AuthenticationTypes.LOGIN, payload: {token, profile}});
    } catch (exception) {
      const {message} = exception;
      dispatch({type: AuthenticationTypes.LOGIN_ERROR, payload: {error: message}});
    }
  };
}

export function remindPassword(request) {
  return async dispatch => {
    try {
      dispatch({type: AuthenticationTypes.REMIND_PASSWORD_START});
      await AuthenticationService.remindPassword(request);
      dispatch({type: AuthenticationTypes.REMIND_PASSWORD});
    } catch (exception) {
      dispatch({type: AuthenticationTypes.REMIND_PASSWORD_ERROR});
    }
  };
}

export function providerLogin(request) {
  return async dispatch => {
    try {
      dispatch({type: AuthenticationTypes.PROVIDER_LOGIN_START});
      const {token} = await AuthenticationService.providerLogin(request);
      const profile = await AuthenticationService.getLoggedUser(token);
      dispatch({type: AuthenticationTypes.PROVIDER_LOGIN, payload: {token, profile}});
    } catch (exception) {
      dispatch({type: AuthenticationTypes.PROVIDER_LOGIN_ERROR});
    }
  };
}

export function getLoggedUser() {
  return async dispatch => {
    try {
      dispatch({type: AuthenticationTypes.GET_LOGGED_USER_START});
      await AuthenticationService.getLoggedUser();
      dispatch({type: AuthenticationTypes.GET_LOGGED_USER});
    } catch (exception) {
      dispatch({type: AuthenticationTypes.GET_LOGGED_USER_ERROR});
    }
  };
}

export function refreshToken() {
  return async dispatch => {
    try {
      dispatch({type: AuthenticationTypes.REFRESH_TOKEN_START});
      await AuthenticationService.refreshToken();
      dispatch({type: AuthenticationTypes.REFRESH_TOKEN});
    } catch (exception) {
      dispatch({type: AuthenticationTypes.REFRESH_TOKEN_ERROR});
    }
  };
}

export function logout() {
  return {
    type: AuthenticationTypes.LOGOUT
  };
}

export function resetError() {
  return {
    type: AuthenticationTypes.RESET_ERROR
  };
}
