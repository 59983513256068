import React from 'react';
import {BadgeContainer, Container, Details, ImagesContainer} from './styles';
import PropTypes from "prop-types";
import {Grid} from "styles";

function CardSkeleton(props) {
  const {height, width} = props;

  return (
    <Container width={width}>
      <BadgeContainer>
        <Grid.Skeleton
          width={100}
          animationDelay={0.25}
          height={22}
        />
      </BadgeContainer>
      <ImagesContainer height={height}/>
      <Details>
        <Grid.Row mb={5}>
          <Grid.Skeleton
            width={120}
            animationDelay={0.2}
            height={25}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Skeleton
            width={160}
            animationDelay={0.5}
            height={20}
          />
        </Grid.Row>
      </Details>
    </Container>
  )
}

CardSkeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

CardSkeleton.defaultProps = {
  height: 150,
};

export default CardSkeleton;
