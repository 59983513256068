import PropTypes from "prop-types";
import {SharedTypes} from "utils";
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';
import {Dropdown} from "components";
import {Container, DropdownContainer, DropdownItem, DropdownItemContainer} from "./styles";
import React from "react";

function CategorySelect(props) {
  const {style, categories, renderButton, isLoading} = props;
  let history = useHistory();

  const renderSelectOption = (category) => (
    <DropdownItemContainer
      className="dropdown-item"
      key={category.id}
      onClick={() => history.push(`/kategorijos/${category.id}`)}
    >
      <DropdownItem>{category.title}</DropdownItem>
    </DropdownItemContainer>
  );

  const renderDropdown = () => (
    <DropdownContainer>
      {categories.map(category => renderSelectOption(category))}
    </DropdownContainer>
  );

  return (
    <Container style={style}>
      <Dropdown
        disabled={isLoading}
        fullWidth={false}
        renderButton={renderButton}
        renderDropdown={renderDropdown}
      />
    </Container>
  );
}

CategorySelect.propTypes = {
  style: PropTypes.object,
  renderButton: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(SharedTypes.CategoryType).isRequired,
};

CategorySelect.defaultProps = {
  style: {},
};

function mapStateToProps(state) {
  const {categories} = state;
  const {isLoading} = categories;
  return {categories: categories.categories, isLoading};
}

export default connect(mapStateToProps)(CategorySelect);
