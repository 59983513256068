import {Container, Content, Header} from "./styles";
import {Grid, Typography} from "styles";
import PropTypes from "prop-types";
import React from "react";

function PageLayout(props) {
  const {children, title, description, center, pb} = props;

  return (
    <Container pb={pb}>
      <Grid.Container>
        <Header>
          <Grid.Row>
            <Typography.PageTitle center={center}>{title}</Typography.PageTitle>
          </Grid.Row>
          {description && (
            <Grid.Row mt={7}>
              <Typography.Description center={center}>{description}</Typography.Description>
            </Grid.Row>
          )}
        </Header>
      </Grid.Container>
      <Content>
        {children}
      </Content>
    </Container>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  center: PropTypes.bool,
  pb: PropTypes.number,
  description: PropTypes.string,
};

PageLayout.defaultProps = {
  description: null,
  pb: 70,
  center: false,
};

export default PageLayout;
