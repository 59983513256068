import React from 'react';
import {Container} from "./styles";
import {Card, CardSkeleton, HorizontalSlider} from "components";
import {Images} from "styles";
import {useDestinations, useFilter} from "hooks";
import {UrlUtils} from "utils";
import {useHistory} from 'react-router-dom';

function DestinationsSlider() {
  const {destinationId} = useFilter();
  const history = useHistory();
  const {destinations, isLoading} = useDestinations({parentId: destinationId});

  const navigate = (destinationId) => {
    const queryString = UrlUtils.setQueryParam("destinationId", destinationId);
    history.push({pathname: '/pasiulymai', search: queryString})
  };

  const renderDestination = (destination) => (
    <Card
      priceFrom={destination.price}
      width={270}
      title={destination.title}
      offersCount={destination.offersCount || 0}
      image={destination.image || Images.IMAGE_PLACEHOLDER}
      onClick={() => navigate(destination.id)}
    />
  );

  const renderDestinationSkeleton = () => (
    <CardSkeleton width={270}/>
  );

  if (!isLoading && destinations.length === 0) {
    return <React.Fragment/>
  }

  return (
    <Container>
      <HorizontalSlider
        renderSkeleton={renderDestinationSkeleton}
        isLoading={isLoading}
        renderSlide={renderDestination}
        slides={destinations}
      />
    </Container>
  );
}

export default DestinationsSlider;
