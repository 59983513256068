import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const Image = styled.div`
  width: 100%;
  height: 300px;
`;
