import styled from 'styled-components/macro';
import { Colors, Media } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';

export const Table = styled.table`
  width: 100%;
  display: block;
`;

export const TableHeader = styled.thead`
  display: table;
  width: 100%;
  table-layout: fixed;
  width: 100%;
`;

export const TableHeaderRow = styled.tr`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 10px 10px 20px;

  @media only screen and (max-width: ${Media.MOBILE}px) {
    padding: 0 10px 10px 10px;
  }
`;

export const TableHeaderCell = styled.th`
  color: ${Colors.HELP};
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  max-width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${Media.MOBILE}px) {
    font-size: 13px;
  }
`;

export const TableBody = styled.tbody`
  max-height: 477px;
  display: block;
  width: 100%;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 0 0 1px ${Colors.BORDER};
`;

export const TableRow = styled.tr`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  height: 120px;
  padding: 0 10px 0 20px;

  width: 100%;
  background-color: ${(props) => (props.selected ? Colors.MENU_BACKGROUND : Colors.WHITE)};

  &:hover {
    background-color: ${Colors.MENU_BACKGROUND};
    cursor: pointer;
  }

  @media only screen and (max-width: ${Media.MOBILE}px) {
    padding: 0 10px;
  }
`;

export const TableCell = styled.td`
  max-width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
`;

export const Title = styled(LinesEllipsis)`
  color: ${Colors.TITLE};
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
`;

export const Catering = styled(LinesEllipsis)`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  line-height: 17px;
`;

export const AdvanceLabel = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 12px;
`;
