import React, {useEffect} from 'react';
import {Container, Title, Link} from "./styles";
import {Grid} from "styles";
import {NoData} from "components";
import {MessagesActions} from "actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {SharedTypes} from "utils";
import {useHistory} from 'react-router-dom';

function Messages(props) {
  const {getMessages} = props;
  let history = useHistory();

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <Container>
      <Grid.Row mb={35}>
        <Title>Naujausios žinutės</Title>
      </Grid.Row>
      <Grid.Row mb={20}>
        <NoData text="Žinučių nėra"/>
      </Grid.Row>
      <Grid.Row>
        <Link onClick={() => history.push('/profilis/zinutes')}>Žiūrėti visas žinutes</Link>
      </Grid.Row>
    </Container>
  );
}

Messages.propTypes = {
  getMessages: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(SharedTypes.MessagesListItemType).isRequired,
};

const mapStateToProps = (state) => {
  const {messages} = state;
  const {isLoading} = messages;
  return {isLoading, messages: messages.messages};
};

const mapDispatchToProps = {
  getMessages: MessagesActions.getMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
