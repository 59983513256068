import React from 'react';
import {Grid} from "styles";
import {CollapsibleList, InformationBox} from "components";
import {Side} from './styles';
import {PageLayout, RightSideLayout} from "layouts";
import Categories from "./Categories";
import {useFaqs} from "hooks";
import {useHistory} from 'react-router-dom';

function FaqsPage() {
  const {faqs} = useFaqs();
  const history = useHistory();

  const renderSide = () => (
    <Side>
      <Grid.Row mb={20}>
        <Categories/>
      </Grid.Row>
      <Grid.Row>
        <InformationBox
          buttonLabel="Susisiekti"
          description="Susisiekite su kelionių ekspertu ir gaukite atsakymą ir rūpiną klausimą!"
          onClick={() => history.push('/kontaktai')}
          title="Nerandate atsakymo?"
        />
      </Grid.Row>
    </Side>
  );

  return (
    <PageLayout
      title="Dažniausiai užduodami klausimai"
      description="Žemiau pateikiame dažniausiai keliautojų užduodamus klausimus ir atsakymus į juos. Pasirinkę temą lengviau rasite atsakymą į rūpimą klausimą."
    >
      <Grid.Container>
        <RightSideLayout showNewsletter={false} renderSide={renderSide}>
          <CollapsibleList items={faqs}/>
        </RightSideLayout>
      </Grid.Container>
    </PageLayout>
  );
}

export default FaqsPage;
