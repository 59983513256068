import styled from 'styled-components/macro';

export const StaticRow = styled.div`
  display: flex;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  flex: ${(props) => (props.flex ? props.flex : '0 1 auto')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
  margin-top: ${(props) => (props.mt ? props.mt : 0)}px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const Row = styled(StaticRow)`
  &.force-break {
    flex-direction: column;
    width: 100%;
  }

  @media only screen and (max-width: ${(props) => (props.breakpoint ? props.breakpoint : '1110')}px) {
    &:not(.no-break) {
      flex-direction: column;
      justify-content: ${(props) => (props.alignItems ? props.alignItems : 'center')};
      align-items: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
    }
  }
`;

export const StaticCol = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'auto')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-start')};
  margin-right: ${(props) => (props.mr ? props.mr : 0)}px;
  margin-left: ${(props) => (props.ml ? props.ml : 0)}px;
  flex: ${(props) => (props.flex ? props.flex : '0 1 auto')};
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
`;

export const Col = styled(StaticCol)`
  &.force-break {
    margin: 0;
    margin-bottom: ${(props) => (props.mr ? props.mr : 0)}px;
    width: 100%;
  }

  @media only screen and (max-width: ${(props) => (props.breakpoint ? props.breakpoint : '1110')}px) {
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
    &.hide-mobile {
      display: none;
    }

    &:not(.no-break) {
      margin: 0;
      margin-bottom: ${(props) => (props.mr ? props.mr : 0)}px;
      width: 100%;
    }
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => (props.itemsCount ? props.itemsCount : 4)}, 1fr);
  grid-row-gap: ${(props) => (props.rowGap ? props.rowGap : 15)}px;
  grid-column-gap: ${(props) => (props.columnGap ? props.columnGap : 30)}px;
`;

export const Skeleton = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #f3f5f7 5%, #fff 38%, #f3f5f7 50%);
  background-size: 1000px 640px;
  position: relative;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 6)}px;
  animation-delay: ${(props) => (props.animationDelay ? `${props.animationDelay}px` : 0.25)}s;
`;

export const Desktop = styled.div`
  display: block;

  @media only screen and (max-width: 1110px) {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media only screen and (max-width: 1110px) {
    display: block;
  }
`;
