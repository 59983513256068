import React from 'react';
import {PageLayout} from "layouts";
import Team from "./Team";
import Activities from "./Activities";
import Partners from "./Partners";
import Video from "./Video";
import Statistics from "./Statistics";
import Report from "./Report";

function AboutUsPage() {
  return (
    <PageLayout
      pb={0}
      center
      description="Baltic Tours - kelionių ekspertų komanda"
      title="Apie mus"
    >
      <Video/>
      <Statistics/>
      <Report/>
      <Team/>
      <Activities/>
      <Partners/>
    </PageLayout>
  );
}

export default AboutUsPage;
