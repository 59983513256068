import React from 'react';
import {Grid} from "styles";
import {BlogArticles, BlogCategories} from "containers";
import {PageLayout} from "layouts";

const DESCRIPTION = "Čia nutūpia istorijos, keliautojų lūpomis kalba prisiminimai ir pasakojimai virsta patarimais. " +
  "Ir tai ne šiaip sau kelionių tinklaraštis. Tai – nuoširdūs interviu prie puodelio kavos ir ilgi pašnekesiai " +
  "gvildenant gražiausius pasaulio kampelius. Baltic Tours kelionių blogas – vieta, kur rasite viską: nuo " +
  "karščiausių turizmo industrijos naujienų iki stereotipus laužančių keliautojų pasakojimų.";

function BlogPage() {
  return (
    <PageLayout center title="Kelionių istorijos ir patarimai" description={DESCRIPTION}>
      <Grid.Container>
        <Grid.Row mb={67}>
          <BlogCategories/>
        </Grid.Row>
        <Grid.Row>
          <BlogArticles/>
        </Grid.Row>
      </Grid.Container>
    </PageLayout>
  );
}

export default BlogPage;
