import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Container, Logo, Content, ButtonContainer} from './styles';
import GoogleMapReact from 'google-map-react';
import {MAP} from "constant";
import {Images} from "styles";
import {createMapOptions} from "./config";
import Modal from "../Modal";
import Badge from "../Badge";

function Map(props) {
  const {style, height, longitude, latitude, icon, address} = props;
  const [modalVisible, setModalVisible] = useState(false);

  const renderContent = (height, title, onClick) => (
    <Content height={height}>
      <GoogleMapReact options={createMapOptions} bootstrapURLKeys={{key: MAP.API_KEY}} center={{lat: latitude, lng: longitude }} defaultZoom={16}>
        <Logo lat={latitude} lng={longitude} src={icon}/>
      </GoogleMapReact>
      <ButtonContainer>
        <Badge onClick={onClick} selected title={title}/>
      </ButtonContainer>
    </Content>
  );

  return (
    <Container style={style}>
      {renderContent(height, 'Atidaryti google maps', () => setModalVisible(true))}
      <Modal transparent large visible={modalVisible} onClose={() => setModalVisible(false)}>
        {renderContent(600, address, () => window.open(`https://maps.google.com/?q=${longitude},${latitude}`))}
      </Modal>
    </Container>
  );
}

Map.propTypes = {
  style: PropTypes.object,
  height: PropTypes.number,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  address: PropTypes.string,
  icon: PropTypes.string,
};

Map.defaultProps = {
  style: {},
  address: 'Vašingtono a. 1, Vilnius – Atidaryti google maps',
  icon: Images.PIN,
  height: 400,
  longitude: 25.269710,
  latitude: 54.691940,
};

export default Map;
