import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: ${(props) => props.height}px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img``;
