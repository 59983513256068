import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  padding: 30px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${Colors.BORDER};
`;

export const Title = styled.h3`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${Colors.DROPDOWN_ITEM};
`;
