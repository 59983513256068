import styled from 'styled-components/macro';

export const Container = styled.div`
  padding: 66px 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const PageContent = styled.div`
  display: flex;
  flex: 1;
  padding-left: 65px;
`;
