import React from "react";
import autoBind from "auto-bind";
import {Grid, Images} from 'styles';
import {Container, SubmitIcon} from './styles';
import Form from "../Form";
import {DatePicker, PassengersCountSelect, Select, DestinationSelect} from "components";
import {DURATIONS} from "constant";
import {withRouter} from "react-router-dom";
import {UrlUtils} from "utils";
import PropTypes from "prop-types";
import {withFilter} from "hooks";
import update from 'immutability-helper';
import _ from "lodash";
import SearchFormMobile from "../SearchFormMobile";

class SearchForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        destinationId: {value: null, error: null, dirty: false},
        date: {value: {dateFrom: null, dateTo: null}, error: null, dirty: false},
        duration: {value: {durationFrom: null, durationTo: null}, error: null, dirty: false},
        passengers: {value: {adultsCount: 0, childrenCount: 0, infantsCount: 0}, error: null, dirty: false},
      },
    };

    autoBind(this);
  }

  componentDidMount() {
    const {filter} = this.props;
    this.setForm(filter);
  }

  componentDidUpdate(prevProps) {
    const {filter} = this.props;

    if (!_.isEqual(filter, prevProps.filter)) {
      this.setForm(filter);
    }
  }

  setForm(filter) {
    this.setState(
      state =>
        update(state, {
          fields: {
            destinationId: { value: { $set: filter.destinationId } },
            date: { value: { $set: {dateFrom: filter.dateFrom, dateTo: filter.dateTo} } },
            duration: { value: { $set: {durationFrom: filter.durationFrom, durationTo: filter.durationTo} } },
            passengers: { value: { $set: {adultsCount: filter.adultsCount, childrenCount: filter.childrenCount, infantsCount: filter.infantsCount} } },
          },
        }),
      () => this.validateForm(),
    );
  }

  submit() {
    const {fields} = this.state;
    const {history} = this.props;

    if (this.validateForm()) {
      const newQueryString = UrlUtils.setQueryParams({
        destinationId: fields.destinationId.value,
        dateFrom: fields.date.value.dateFrom,
        dateTo: fields.date.value.dateTo,
        durationFrom: fields.duration.value.durationFrom,
        durationTo: fields.duration.value.durationTo,
        adultsCount: fields.passengers.value.adultsCount,
        childrenCount: fields.passengers.value.childrenCount,
        infantsCount: fields.passengers.value.infantsCount,
      });
      history.push({ search: newQueryString });
    }
  }

  render() {
    const {fields} = this.state;
    const {style} = this.props;

    return (
      <Container onSubmit={(event) => event.preventDefault()} style={style}>
        <Grid.Container>
          <Grid.Desktop>
            <Grid.Row>
              <Grid.Col flex={2} mr={10}>
                <DestinationSelect
                    value={fields.destinationId.value}
                    dirty={fields.destinationId.dirty}
                    error={fields.destinationId.error}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    name="destinationId"
                    placeholder="Rinkitės šalį/kurortą"
                />
              </Grid.Col>
              <Grid.Col flex={2} mr={10}>
                <DatePicker
                    destinationId={fields.destinationId.value}
                    departureId={1}
                    value={fields.date.value}
                    dirty={fields.date.dirty}
                    error={fields.date.error}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    name="date"
                    placeholder="Rinkitės datą"
                />
              </Grid.Col>
              <Grid.Col flex={1} mr={10}>
                <Select
                    options={DURATIONS}
                    value={fields.duration.value}
                    dirty={fields.duration.dirty}
                    error={fields.duration.error}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    name="duration"
                    placeholder="Rinkitės trukmę"
                />
              </Grid.Col>
              <Grid.Col mr={20} flex={1}>
                <PassengersCountSelect
                    value={fields.passengers.value}
                    error={fields.passengers.error}
                    dirty={fields.passengers.dirty}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    name="passengers"
                    placeholder="Keleiviai"
                />
              </Grid.Col>
              <Grid.Col>
                <SubmitIcon onClick={this.submit} src={Images.SEARCH}/>
              </Grid.Col>
            </Grid.Row>
          </Grid.Desktop>
          <SearchFormMobile handleBlur={this.handleBlur} handleChange={this.handleChange} fields={fields}/>
        </Grid.Container>
      </Container>
    );
  }
}

SearchForm.propTypes = {
  history: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
};

export default withFilter(withRouter(SearchForm));
