import styled from 'styled-components/macro';
import { Colors, Grid } from 'styles';

export const Container = styled(Grid.Row)``;

export const Image = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 60px;
`;

export const Name = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 16px;
  font-weight: 700;
`;

export const Email = styled.a`
  color: ${Colors.LIGHT_PRIMARY};
  font-size: 13px;
  text-decoration: none;
`;

export const Phone = styled.a`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 15px;
  font-weight: 700;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    color: ${Colors.LIGHT_PRIMARY};
  }
`;
