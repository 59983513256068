import React from 'react';
import PropTypes from "prop-types";
import {ItemContainer} from './styles';
import _ from "lodash";

function Loadable(props) {
  const {loading, renderSkeleton, items, renderItem, skeletonsCount, inline} = props;

  if (!loading) {
    return items.map((item, index) => (
      <ItemContainer inline={inline} key={index}>
        {renderItem(item)}
      </ItemContainer>
    ));
  }

  if (loading) {
    return _.range(skeletonsCount).map(index => (
      <ItemContainer inline={inline} key={index}>
        {renderSkeleton()}
      </ItemContainer>
    ));
  }
}

Loadable.propTypes = {
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  renderSkeleton: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  skeletonsCount: PropTypes.number,
  inline: PropTypes.bool,
};

Loadable.defaultProps = {
  loading: false,
  skeletonsCount: 4,
  inline: false,
};

export default Loadable;
