import tvsAxiosInstance from "../tvsAxiosInstance";
import {store} from "store";

class CategoriesService {
  static async getCategories() {
    const paging = {limit: 30, offset: 0};
    const params = {...paging};
    return await tvsAxiosInstance.get(`/categories`, {params});
  }

  static getCategoryById(id) {
    const categories = store.getState().categories.categories;
    return categories.find(category => category.id === id);
  }
}

export default CategoriesService;
