import {Route, Redirect} from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

function AuthenticationRoute(props) {
  const {isLogged} = props;
  const {state} = useLocation();

  if (isLogged) {
    if (state && state.from) {
      return <Redirect to={state.from}/>;
    }

    return <Redirect to="/"/>;
  }

  return <Route {...props}/>;
}

AuthenticationRoute.propTypes = {
  isLogged: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { isLogged } = authentication;
  return { isLogged };
}

export default connect(mapStateToProps)(AuthenticationRoute);
