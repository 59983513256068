import React from 'react';
import {Container, ListContent} from "./styles";
import {ArticleCard, ArticleCardSkeleton, Badge, Button} from "components";
import Masonry from 'react-masonry-css';
import {Grid} from 'styles';
import {useArticles} from "hooks";
import _ from "lodash";

const breakpoints = {
  default: 3,
  991: 2,
  600: 1,
};

function BlogArticles() {
  const {isLoading, articles, canLoadMore, loadMore, count} = useArticles();

  const renderButtonBadge = () => (
    <Badge
      selected
      disabled
      title={`${articles.length}/${count}`}
    />
  );

  const renderContent = () => {
    if (isLoading) {
      return _.range(9).map(index => (
        <ArticleCardSkeleton key={index}/>
      ));
    }

    return _.drop(articles, 2).map(article => (
      <ArticleCard key={article.title} article={article}/>
    ));
  };

  return (
    <Container>
      {articles.length > 1 && (
        <Grid.Row alignItems="flex-start" mb={60}>
          <Grid.Col flex={4} mr={50}>
            {!isLoading && <ArticleCard large article={articles[0]}/>}
            {isLoading && <ArticleCardSkeleton height={400}/>}
          </Grid.Col>
          <Grid.Col flex={3}>
            {!isLoading && <ArticleCard reversed large article={articles[1]}/>}
            {isLoading && <ArticleCardSkeleton height={300} reversed/>}
          </Grid.Col>
        </Grid.Row>
      )}
      <ListContent>
        <Masonry
          breakpointCols={breakpoints}
          className="masonry-grid"
          columnClassName="masonry-grid-column"
        >
          {renderContent()}
        </Masonry>
      </ListContent>
      {canLoadMore && (
        <Grid.Row mt={50} justifyContent="center">
          <Button renderBadge={renderButtonBadge} large label="Daugiau istorijų" onClick={loadMore}/>
        </Grid.Row>
      )}
    </Container>
  );
}

export default BlogArticles;
