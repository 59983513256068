import {DestinationsTypes} from "types";
import {DestinationsService} from "services";
import _ from "lodash";

export function getDestinations() {
  return async dispatch => {
    try {
      dispatch({type: DestinationsTypes.GET_DESTINATIONS_START});
      const {data, count} = await DestinationsService.getDestinations();
      const destinations = _.orderBy(data, 'offersCount', 'desc');
      const cities = destinations.filter(destination => destination.parentId !== null);
      const countries = destinations.filter(destination => destination.parentId === null);
      dispatch({type: DestinationsTypes.GET_DESTINATIONS, payload: {destinations, count, cities, countries}});
    } catch (exception) {
      dispatch({type: DestinationsTypes.GET_DESTINATIONS_ERROR});
    }
  };
}
