
export const SECONDARY = '#555555';
export const TERTIARY = '#ffc9cd';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const BACKGROUND = '#dddddd';
export const DANGER = '#ff7e8a';
export const PLACEHOLDER = '#999999';
export const BADGE_TEXT = '#555555';
export const BADGE_BACKGROUND = '#eeeeee';
export const LIGHT_GREY = '#f5f5f5';
export const MODAL_BORDER = '#cccccc';

export const GREEN = '#17b25b';
export const PRIMARY = '#449bd7';
export const LIGHT_PRIMARY = '#2394e2';
export const FORM_BORDER = '#dce0e5';
export const DROPDOWN_ITEM = '#404b69';
export const CHECKBOX_BORDER = '#c6cfe0';
export const SEASON = '#95a3b8';
export const TITLE = '#212529';
export const BORDER = '#dde3e8';
export const SECTION_TITLE = '#1b2125';
export const SECTION_DESCRIPTION = '#7f91a8';
export const WARNING = '#193d77';
export const FACEBOOK = '#3b5998';
export const RATING = '#fad156';
export const MENU_BACKGROUND = '#f5f7fa';
export const MENU_SHADOW = '#e0e5ee';
export const HELP = '#a5adbb';
export const NEWSLETTER_BACKGROUND = '#f3f5f7';
export const INCLUSIVE_TITLE = '#788995';
export const SUCCESS_TITLE = '#126938';
