import React from 'react';
import PropTypes from "prop-types";
import {Image} from './styles';

function AnimatedImage(props) {
  const {width, source, height, style} = props;

  return (
    <Image
      style={style}
      height={height}
      width={width}
      src={source}
    />
  );
}

AnimatedImage.propTypes = {
  source: PropTypes.string.isRequired,
  width: PropTypes.number,
  style: PropTypes.object,
  height: PropTypes.number,
};

AnimatedImage.defaultProps = {
  width: null,
  style: {},
  height: null,
};

export default AnimatedImage;
