import React from 'react';
import PropTypes from "prop-types";
import {Grid, Images} from 'styles';
import {useHistory, useLocation} from "react-router-dom";
import {
  Button,
  ButtonIcon,
  ButtonLabel,
  Container,
  Content,
  Description,
  Logo,
  LogoLink,
  StyledLink,
  Title
} from "./styles";

function AuthenticationWrapper(props) {
  const {style, children, title, description, link} = props;
  let history = useHistory();
  let {state} = useLocation();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container style={style}>
      <Grid.Container>
        <Grid.Row mb={30}>
          <LogoLink to="/">
            <Logo src={Images.LOGO}/>
          </LogoLink>
        </Grid.Row>
        <Grid.Row mb={10}>
          <Button onClick={handleGoBack}>
            <ButtonIcon src={Images.ARROW_BACK}/>
            <ButtonLabel>Atgal</ButtonLabel>
          </Button>
        </Grid.Row>
        <Content>
          <Grid.Row mb={5}>
            <Title>{title}</Title>
          </Grid.Row>
          <Grid.Row justifyContent="center" mb={25}>
            <Description>{description}</Description>
            {link && <StyledLink to={{pathname: link.to, state}}>{link.label}</StyledLink>}
          </Grid.Row>
          {children}
        </Content>
      </Grid.Container>
    </Container>
  );
}

AuthenticationWrapper.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  linkLabel: PropTypes.string,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

AuthenticationWrapper.defaultProps = {
  style: {},
  link: null,
};

export default AuthenticationWrapper;
