import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  width: calc(100% - 30px);
  margin-left: 30px;
  background-color: ${Colors.BORDER};
  border: 1px solid ${Colors.BORDER};
`;

export const Bank = styled.div`
  height: 100px;
  display: flex;
  padding: 33px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.WHITE};
  transition: 0.3s;

  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  height: 100%;
  width: auto;
`;
