import React, {useEffect} from 'react';
import {Container, Link, Title} from "./styles";
import {Grid} from "styles";
import {NoData} from "components";
import {TripsActions} from "actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {SharedTypes} from "utils";
import {useHistory} from 'react-router-dom';

function Trips(props) {
  const {getTrips} = props;
  let history = useHistory();

  useEffect(() => {
    getTrips();
  }, []);

  return (
    <Container>
      <Grid.Row mb={35}>
        <Title>Artėjančios kelionės</Title>
      </Grid.Row>
      <Grid.Row mb={20}>
        <NoData text="Artėjančių kelionių nėra"/>
      </Grid.Row>
      <Grid.Row>
        <Link onClick={() => history.push('/profilis/keliones')}>Visos jūsų kelionės</Link>
      </Grid.Row>
    </Container>
  );
}

Trips.propTypes = {
  getTrips: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  trips: PropTypes.arrayOf(SharedTypes.TripListItemType).isRequired,
};

const mapStateToProps = (state) => {
  const {trips} = state;
  const {isLoading} = trips;
  return {isLoading, trips: trips.trips};
};

const mapDispatchToProps = {
  getTrips: TripsActions.getTrips,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trips);
