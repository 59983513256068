import React, {useEffect} from 'react';
import {Container, Title} from "./styles";
import {Grid} from 'styles';
import PropTypes from "prop-types";
import {TripsActions} from "actions";
import {connect} from "react-redux";
import {SharedTypes} from "utils";
import {NoData} from "components";

function ProfileTripsPage(props) {
  const {getTrips} = props;

  useEffect(() => {
    getTrips();
  }, []);

  return (
    <Container>
      <Grid.Row mb={35}>
        <Title>Artėjančios kelionės</Title>
      </Grid.Row>
      <Grid.Row mb={50}>
        <NoData text="Artėjančių kelionių nėra"/>
      </Grid.Row>
      <Grid.Row mb={35}>
        <Title>Įvykusios kelionės</Title>
      </Grid.Row>
      <Grid.Row>
        <NoData text="Įvykusių kelionių nėra"/>
      </Grid.Row>
    </Container>
  );
}

ProfileTripsPage.propTypes = {
  getTrips: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  trips: PropTypes.arrayOf(SharedTypes.TripListItemType).isRequired,
};

const mapStateToProps = (state) => {
  const {trips} = state;
  const {isLoading} = trips;
  return {isLoading, trips: trips.trips};
};

const mapDispatchToProps = {
  getTrips: TripsActions.getTrips,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTripsPage);
