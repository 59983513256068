import React from 'react';
import PropTypes from "prop-types";
import {Grid} from 'styles';
import {Container, Title, Description, Button, Value} from './styles';

function PassengerCount(props) {
  const {style, title, description, count, minCount, onChange} = props;
  const disabled = count <= minCount;

  const handleDecrease = () => {
    if (disabled) {
      return;
    }

    return onChange(count - 1);
  };

  const handleIncrease = () => {
    return onChange(count + 1);
  };

  return (
    <Container style={style}>
      <Grid.Row className='no-break' justifyContent='space-between'>
        <Grid.Desktop>
          <Grid.Col mr={100} flex={1}>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Grid.Col>
        </Grid.Desktop>
        <Grid.Mobile style={{width:'100%'}}>
          <Grid.Col flex={1}>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Grid.Col>
        </Grid.Mobile>
       <Grid.Desktop>
         <Grid.Col>
           <Grid.Row className='no-break'>
             <Grid.Col>
               <Button onClick={handleDecrease} disabled={count <= minCount} outline>-</Button>
             </Grid.Col>
             <Grid.Col>
               <Value>{count}</Value>
             </Grid.Col>
             <Grid.Col>
               <Button onClick={handleIncrease}>+</Button>
             </Grid.Col>
           </Grid.Row>
         </Grid.Col>
       </Grid.Desktop>
        <Grid.Mobile>
          <Grid.Col>
            <Grid.Row className='no-break'>
              <Grid.Col>
                <Button onClick={handleDecrease} disabled={count <= minCount} outline>-</Button>
              </Grid.Col>
              <Grid.Col>
                <Value>{count}</Value>
              </Grid.Col>
              <Grid.Col>
                <Button onClick={handleIncrease}>+</Button>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Mobile>
      </Grid.Row>
    </Container>
  );
}

PassengerCount.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  minCount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

PassengerCount.defaultProps = {
  style: {},
  minCount: 0,
};

export default PassengerCount;
