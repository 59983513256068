import {ContactsTypes} from "types";
import {ContactsService} from "services";

export function sendMessage(request) {
    return async dispatch => {
        try {
            dispatch({ type: ContactsTypes.SEND_MESSAGE_START });
            await ContactsService.sendMessage(request);
            dispatch({type: ContactsTypes.SEND_MESSAGE});
        } catch (exception) {
            dispatch({ type: ContactsTypes.SEND_MESSAGE_ERROR });
        }
    };
}
