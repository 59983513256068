import React, { useState } from 'react';
import { Grid, Typography } from 'styles';
import { Container, Link, Help } from './styles';
import { Gallery, RatingInput } from 'components';
import PropTypes from 'prop-types';

function Details(props) {
  const { summary, title, images, rating } = props;
  const [lightBoxVisible, setLightBoxVisible] = useState(false);

  return (
    <Container>
      <Grid.Row mb={15}>
        <Typography.PageTitle>{title}</Typography.PageTitle>
      </Grid.Row>
      <Grid.Row mb={20}>
        <RatingInput value={rating} size={14} readonly />
      </Grid.Row>
      <Grid.Row mb={30}>
        <Typography.Description>{summary}</Typography.Description>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Gallery
          height={175}
          onClose={() => setLightBoxVisible(false)}
          visible={lightBoxVisible}
          images={images}
          imagesCount={3}
        />
      </Grid.Row>
      <Grid.Row>
        <Grid.Col flex={1}>
          <Link onClick={() => setLightBoxVisible(true)}>Žiūrėti visas</Link>
        </Grid.Col>
        <Grid.Col>
          <Help>Pasiūlyme pateiktos nuotraukos yra pavyzdinės</Help>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
}

Details.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Details;
