import React from "react";
import PropTypes from 'prop-types';
import {Container, LoaderContainer, SelectWrapper} from "./styles";
import {SelectUtils, SharedTypes} from "utils";
import {Form} from "styles";
import {default as ReactSelect} from 'react-select';
import Loader from "../Loader";

function Select(props) {
  const {
    label,
    style,
    dirty,
    error,
    placeholder,
    options,
    onChange,
    name,
    large,
    loading,
    disabled,
    onBlur,
    searchable,
    renderGroupLabel,
    value,
    menuIsOpen
  } = props;

  const handleOnChange = (event) => {
    const {value} = event;
    onChange({name, value});
  };

  const handleOnBlur = () => {
    onBlur({name});
  };

  return (
    <Container style={style}>
      {label && <Form.Label>{label}</Form.Label>}
      <SelectWrapper>
        <ReactSelect
          menuIsOpen={menuIsOpen}
          className={dirty && error ? 'error' : null}
          classNamePrefix={large ? 'react-select-large' : 'react-select'}
          name={name}
          value={renderGroupLabel ? SelectUtils.getGroupsValue(options, value) : SelectUtils.getOptionsValue(options, value)}
          formatGroupLabel={renderGroupLabel}
          isSearchable={searchable}
          isDisabled={disabled || loading}
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          options={options}
          noOptionsMessage={() => "Nėra pasirinkimo variantų"}
        />
        {loading && (
          <LoaderContainer>
            <Loader/>
          </LoaderContainer>
        )}
      </SelectWrapper>
      {dirty && error && <Form.Error>{error}</Form.Error>}
    </Container>
  )
}

Select.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired
    })
  ]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.any,
  dirty: PropTypes.bool,
  searchable: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  large: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  renderGroupLabel: PropTypes.func,
};

Select.defaultProps = {
  style: {},
  disabled: false,
  loading: false,
  value: null,
  large: false,
  label: null,
  dirty: false,
  error: null,
  searchable: false,
  placeholder: null,
  renderGroupLabel: null,
  onBlur: () => {
  },
};

export default Select;
