import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Colors } from 'styles';

export const Container = styled.header`
  position: ${(props) => (props.transparent ? 'absolute' : 'relative')};
  width: 100%;
  z-index: 99;
  background-color: ${(props) => (props.transparent ? 'transparent' : Colors.WHITE)};
  border-bottom: ${(props) => (props.transparent ? 'transparent' : `1px solid ${Colors.FORM_BORDER}`)};

  @media only screen and (max-width: 1110px) {
    border-bottom: none;
  }
`;

export const Navigation = styled.nav`
  padding: 15px 0;
`;

export const Logo = styled.img`
  height: 100%;
  width: auto;
`;

export const LogoLink = styled(NavLink)`
  height: 33px;

  @media only screen and (max-width: 1110px) {
    height: 30px;
  }
`;

export const NavigationItemContainer = styled.div`
  padding-left: 15px;
  padding-right: ${(props) => (props.noPaddingRight ? 0 : 15)}px;
  height: 43px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.transparent ? Colors.WHITE : Colors.DROPDOWN_ITEM)};

  &:hover,
  &.active {
    color: ${(props) => (props.transparent ? Colors.WHITE : Colors.LIGHT_PRIMARY)};
    cursor: pointer;
  }
`;

export const NavigationLink = styled(NavLink)`
  text-decoration: none;

  &.active > div {
    color: ${(props) => (props.transparent ? Colors.WHITE : Colors.LIGHT_PRIMARY)};
  }

  @media only screen and (max-width: 1110px) {
    > div {
      color: ${Colors.WHITE};
    }
  }
`;

export const NavigationIcon = styled.img`
  height: 100%;
`;

export const Badge = styled.span`
  display: block;
  width: 24px;
  border-radius: 12px;
  background-color: ${Colors.RATING};
  color: ${Colors.DROPDOWN_ITEM};
  height: 24px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
`;

export const NavigationItem = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  color: inherit;
  line-height: 43px;
`;

export const ProfileName = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: inherit;
`;

export const ProfileExpandIcon = styled.img`
  height: 43px;
`;

export const ProfileButton = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.transparent ? Colors.WHITE : Colors.DROPDOWN_ITEM)};

  &:hover,
  &.active {
    color: ${(props) => (props.transparent ? Colors.WHITE : Colors.LIGHT_PRIMARY)};
    cursor: pointer;
  }
`;

export const PhoneIcon = styled.img`
  padding-top: 3px;
  cursor: pointer;
`;

export const PhoneContainer = styled.div`
  position: relative;

  &:hover > div {
    display: flex;
  }
`;

export const PhoneContentWrapper = styled.div`
  padding-top: 6px;
  display: none;
  z-index: 999;
  position: absolute;
  right: 0;
  top: 100%;
`;

export const PhoneContent = styled.div`
  display: flex;
  background-color: ${Colors.WHITE};
  box-shadow: 0 5px 11px 0 rgba(185, 175, 175, 0.5);
  border-radius: 8px;
  padding: 10px 15px;
  flex-direction: column;
`;

export const Phone = styled.a`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 18px;
  white-space: nowrap;
  font-weight: 700;
  line-height: 27px;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    color: ${Colors.LIGHT_PRIMARY};
  }
`;

export const Help = styled.span`
  color: ${Colors.HELP};
  font-size: 11px;
  line-height: 13px;
  white-space: nowrap;
`;
