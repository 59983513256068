import {Container, Icon, Item} from "./styles";
import React from "react";
import {Grid, Images} from "styles";
import PropTypes from "prop-types";

function Inclusive(props) {
  const {inclusive} = props;

  return (
    <Container>
      {inclusive.map(item => (
        <Grid.Row key={item}>
          <Icon src={Images.CHECK_MARK}/>
          <Item>{item}</Item>
        </Grid.Row>
      ))}
    </Container>
  );
}

Inclusive.propTypes = {
  inclusive: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Inclusive;
