import styled from 'styled-components/macro';
import { Colors } from 'styles';
import Rating from 'react-rating';

export const Container = styled.div``;

export const TooltipContainer = styled.div`
  background-color: ${Colors.DROPDOWN_ITEM};
  height: 24px;
  padding: 0 15px;
  margin-left: 30px;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid ${Colors.DROPDOWN_ITEM};
  }
`;

export const Tooltip = styled.span`
  font-size: 12px;
  color: ${Colors.WHITE};
`;

export const RatingOption = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.RATING};
  border-radius: 50%;
  margin-right: 3px;
`;

export const RatingOptionContent = styled.div`
  background-color: ${Colors.RATING};
  width: ${(props) => Math.floor((props.size / 100) * 60)}px;
  height: ${(props) => Math.floor((props.size / 100) * 60)}px;
  border-radius: 50%;
`;

export const RatingContainer = styled(Rating)`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
`;
