import React from 'react';
import PropTypes from "prop-types";
import {Container, Image} from "./styles";
import {Swiper, SwiperSlide} from "swiper/react";
import {ImageUtils} from "utils";

function PhotosHorizontalSlider(props) {
  const {images} = props;

  return (
    <Container>
      <Swiper
        className="inline-cards-container"
        navigation
        pagination={{dynamicBullets: true}}
        slidesPerView={1}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <Image src={ImageUtils.getHighQualityImage(image)}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}

PhotosHorizontalSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PhotosHorizontalSlider;
