const MESSAGES = {
  data: [
    // {
    //   id: 1,
    //   sender: 'Viktorija',
    //   title: 'Keliaukite į Graikiją šį rudenį! 7 naktys vaizdingame 4* viešbutyje su pusryčiais ir vakarienėmis',
    //   date: '2019 spalio 4 d.',
    //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vel odio malesuada, porta ante vel, auctor odio. Mauris tincidunt sapien neque, at dignissim mauris maximus a. Ut nec lobortis dolor. Nulla laoreet faucibus velit at biben …'
    // },
    // {
    //   id: 2,
    //   sender: 'Baltic Tours',
    //   title: 'Sistemos pranešimas',
    //   date: '2019 spalio 4 d.',
    //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vel odio malesuada, porta ante vel, auctor odio. Mauris tincidunt sapien neque, at dignissim mauris maximus a. Ut nec lobortis dolor. Nulla laoreet faucibus velit at biben …'
    // },
    // {
    //   id: 3,
    //   sender: 'Baltic Tours',
    //   title: 'Sistemos pranešimas',
    //   date: '2019 spalio 4 d.',
    //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vel odio malesuada, porta ante vel, auctor odio. Mauris tincidunt sapien neque, at dignissim mauris maximus a. Ut nec lobortis dolor. Nulla laoreet faucibus velit at biben …'
    // },
  ],
  count: 0,
};

export default MESSAGES;
