import {useSelector} from "react-redux";

function useFaqs() {
  const {faqs, isLoading, isLoaded, count, selectedCategory} = useSelector(state => state.faqs);

  const getFaqs = () => {
    if (selectedCategory !== null) {
      return faqs.filter(faq => faq.category === selectedCategory);
    }

    return faqs;
  };


  return {
    faqs: getFaqs(),
    isLoading,
    isLoaded,
    count,
  };
}

export default useFaqs;
