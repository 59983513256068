import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {RegistrationForm} from "containers";
import {AuthenticationActions} from "actions";
import {AuthenticationButton, AuthenticationWrapper, FacebookButton, GoogleButton} from "components";
import {Form, Grid} from "styles";
import {Divider, DividerLabel} from "./styles";
import {useLocation} from 'react-router-dom';

function RegistrationPage(props) {
  const {isLoading, register, error, resetError, providerLogin} = props;
  const [formActive, setFormActive] = useState(false);
  let location = useLocation();

  useEffect(() => {
    resetError();
  }, [location]);

  const handleRegister = (request) => {
    register(request);
  };

  return (
    <AuthenticationWrapper
      description="Jau turite BalticTours paskyrą?"
      link={{label: 'Prisijunkite', to: '/prisijungimas'}}
      title="Registruokitės"
    >
      <Grid.Row mb={15}>
        <FacebookButton onSuccess={providerLogin}/>
      </Grid.Row>
      <Grid.Row mb={25}>
        <GoogleButton onSuccess={providerLogin}/>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Divider>
          <DividerLabel>Arba</DividerLabel>
        </Divider>
      </Grid.Row>
      {error && (
        <Grid.Row justifyContent="center" mb={15}>
          <Form.Error>{error}</Form.Error>
        </Grid.Row>
      )}
      <Grid.Row>
        {!formActive && (
          <AuthenticationButton
            label="Registruokitės su el. paštu"
            onClick={() => setFormActive(true)}
          />
        )}
        {formActive && (
          <RegistrationForm isLoading={isLoading} onSubmit={handleRegister}/>
        )}
      </Grid.Row>
    </AuthenticationWrapper>
  );
}

RegistrationPage.propTypes = {
  register: PropTypes.func.isRequired,
  providerLogin: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetError: PropTypes.func.isRequired,
  error: PropTypes.string,
};

RegistrationPage.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => {
  const {authentication} = state;
  const {isLoading, error} = authentication;
  return {isLoading, error};
};

const mapDispatchToProps = {
  register: AuthenticationActions.register,
  providerLogin: AuthenticationActions.providerLogin,
  resetError: AuthenticationActions.resetError,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
