import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 30px;
  border-radius: 15px;
  transition: 0.3s;
  border: 1px solid ${(props) => (props.selected ? Colors.RATING : Colors.MENU_SHADOW)};
  background-color: ${(props) => (props.selected ? Colors.RATING : Colors.WHITE)};

  &:hover {
    background-color: ${Colors.RATING};
    border: 1px solid ${Colors.RATING};
    cursor: pointer;
  }
`;

export const Title = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 11px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1px;
`;
