import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const ItemContainer = styled.div`
  margin-bottom: ${(props) => (props.inline ? 0 : 20)}px;
  margin-right: ${(props) => (props.inline ? 10 : 0)}px;
`;
