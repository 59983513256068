import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
`;

export const Title = styled.h2`
  font-size: 21px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Description = styled.p`
  font-size: 14px;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Section = styled.div`
  border: 1px solid ${Colors.BORDER};
  border-radius: 8px;
  width: 100%;
`;

export const SectionContent = styled.div`
  padding: 30px;
`;

export const PassengersWrapper = styled.div`
  padding-top: 30px;
`;

export const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${Colors.BORDER};
  width: 100%;
`;
