import _ from "lodash";

export const getOptionsValue = (options, value) => {
  return options.find(option => _.isEqual(option.value, value));
};

export const getGroupsValue = (groups, value) => {
  for (let group of groups) {
    const selectedValue = getOptionsValue(group.options, value);
    if (selectedValue) {
      return selectedValue;
    }
  }

  return null;
};
