import PropTypes from "prop-types";

export const ReviewResponseType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});

export const ReviewType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
  response: ReviewResponseType,
});

export const CategoryType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  offersCount: PropTypes.number.isRequired,
  image: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.string.isRequired,
});

export const ProfileType = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  photo: PropTypes.string,
});

export const OptionType = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.string,
});

export const DateValueType = PropTypes.shape({
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
});

export const OffersListItemType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  departureDatetime: PropTypes.string.isRequired,
  hotelClass: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string)
});

export const OfferRoomType = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  catering: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  advance: PropTypes.number.isRequired,
});

export const OfferLocationObjectType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
});

export const OfferLocationType = PropTypes.shape({
  description: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  closestObjects: PropTypes.arrayOf(OfferLocationObjectType).isRequired,
  closestAirports: PropTypes.arrayOf(OfferLocationObjectType).isRequired,
  closestMarketplaces: PropTypes.arrayOf(OfferLocationObjectType).isRequired,
  closestNatureObjects: PropTypes.arrayOf(OfferLocationObjectType).isRequired,
  closestBeaches: PropTypes.arrayOf(OfferLocationObjectType).isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
});

export const OfferFlightType = PropTypes.shape({
  airline: PropTypes.string.isRequired,
  airlineIcon: PropTypes.string.isRequired,
  arrivalCity: PropTypes.string.isRequired,
  arrivalTime: PropTypes.string.isRequired,
  departureCity: PropTypes.string.isRequired,
  departureTime: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
});

export const OfferTravelType = PropTypes.shape({
  date: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  flights: PropTypes.arrayOf(OfferFlightType).isRequired,
});

export const OfferTravelPerkType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
});

export const OfferTravelsType = PropTypes.shape({
  perks: PropTypes.arrayOf(OfferTravelPerkType).isRequired,
  arrival: OfferTravelType.isRequired,
  departure: OfferTravelType.isRequired,
});

export const OfferPerkType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const OfferPricingType = PropTypes.shape({
  priceForAdult: PropTypes.number.isRequired,
  priceForInfant: PropTypes.number.isRequired,
  priceForChild: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
});

export const OfferType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  advance: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  destinationId: PropTypes.number.isRequired,
  hotelClass: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  nightsCount: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  location: OfferLocationType.isRequired,
  perks: PropTypes.arrayOf(OfferPerkType).isRequired,
  hotelRooms: PropTypes.arrayOf(OfferRoomType).isRequired,
  travels: OfferTravelsType.isRequired,
  adultsCount: PropTypes.number.isRequired,
  childrenCount: PropTypes.number.isRequired,
  infantsCount: PropTypes.number.isRequired,
  pricing: OfferPricingType.isRequired,
  inclusive: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const TripListItemType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  orderNumber: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  nightsCount: PropTypes.number.isRequired,
  inPast: PropTypes.bool.isRequired,
});

export const MessagesListItemType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  sender: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
});

export const TvsPageType = PropTypes.shape({
  content: PropTypes.string.isRequired,
  meta_description: PropTypes.string.isRequired,
  meta_title: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export const FilterOptionType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
});

export const FilterType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(FilterOptionType).isRequired,
});

export const OffersFilterType = PropTypes.shape({
  isLastMinute: PropTypes.bool,
  isTopOffer: PropTypes.bool,
});

export const BlogCategoryType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
});

export const ArticlesListItemType = PropTypes.shape({
  label: PropTypes.string,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  time: PropTypes.string,
  title: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
});

export const ArticleType = PropTypes.shape({
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  similar_posts: PropTypes.arrayOf(ArticlesListItemType).isRequired,
  date: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  reading_time: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export const FaqFilterType = PropTypes.shape({
  category: PropTypes.string,
});

export const EmployeeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const CheckoutAdditionalType = PropTypes.shape({
  insurance: PropTypes.bool.isRequired,
  additionalLuggage: PropTypes.bool.isRequired,
  seatSelection: PropTypes.bool.isRequired,
  transportation: PropTypes.bool.isRequired,
  carParkSpot: PropTypes.bool.isRequired,
});

export const CheckoutPassengerType = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthDate: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  ageGroup: PropTypes.string.isRequired,
  additionalLuggage: PropTypes.number.isRequired,
});

export const CheckoutAdditionalFormDataType = PropTypes.shape({
  insurance: PropTypes.bool.isRequired,
  additionalLuggage: PropTypes.bool.isRequired,
  seatSelection: PropTypes.bool.isRequired,
  transportation: PropTypes.bool.isRequired,
  carParkSpot: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  passengers: PropTypes.arrayOf(CheckoutPassengerType).isRequired,
});
