import React from 'react';
import PropTypes from "prop-types";
import {Grid} from 'styles';
import {Container, Title, TitleLink} from './styles';
import {ImageUtils, SharedTypes} from "utils";
import Badge from "../Badge";

function ArticleImageCard(props) {
  const {style, article, large} = props;

  return (
    <Container
      large={large}
      backgroundImage={ImageUtils.getTvsImage(article.photo)}
      className="card"
      style={style}
    >
      {article.label && (
        <Grid.Row mb={5}>
          <Badge disabled selected title={article.label}/>
        </Grid.Row>
      )}
      <Grid.Row>
        <TitleLink to={article.link}>
          <Title text={article.title} maxLine={3}/>
        </TitleLink>
      </Grid.Row>
    </Container>
  );
}

ArticleImageCard.propTypes = {
  style: PropTypes.object,
  large: PropTypes.bool,
  article: SharedTypes.ArticlesListItemType.isRequired,
};

ArticleImageCard.defaultProps = {
  style: {},
  large: false,
};

export default ArticleImageCard;
