import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Colors } from 'styles';

export const Container = styled.div`
  max-width: 285px;
  width: 100%;
  border-radius: ${(props) => (props.dropdown ? 6 : 8)}px;
  overflow: hidden;
  min-width: 180px;
`;

export const LinkContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid ${Colors.MENU_SHADOW};
  padding: 0 20px;
  text-decoration: none;
  background-color: ${Colors.MENU_BACKGROUND};

  &:last-of-type {
    border-bottom: none;
  }

  &:hover,
  &.active {
    cursor: pointer;
    background-color: ${Colors.DROPDOWN_ITEM};

    > span {
      color: ${Colors.WHITE};
    }

    > img {
      filter: brightness(0) invert(1);
    }
  }

  &.dropdown-item {
    height: 46px;
    padding: 0;
    background-color: ${Colors.WHITE};

    &:hover,
    &.active {
      background-color: rgba(64, 75, 105, 0.07);

      > span {
        color: ${Colors.DROPDOWN_ITEM};
      }
    }
  }
`;

export const LinkIcon = styled.img`
  margin-right: 15px;
`;

export const LinkLabel = styled.span`
  padding: 0 ${(props) => (props.dropdown ? 20 : 0)}px;
  font-size: ${(props) => (props.dropdown ? 12 : 14)}px;
  font-weight: ${(props) => (props.dropdown ? 600 : 700)};
  text-transform: ${(props) => (props.dropdown ? 'none' : 'uppercase')};
  white-space: nowrap;
  color: ${Colors.DROPDOWN_ITEM};
`;
