import {Container} from "./styles";
import PropTypes from "prop-types";
import React from "react";
import {Countries} from "containers";
import SectionLayout from "../SectionLayout";
import {connect} from "react-redux";
import {countriesText} from "utils/pluralisation";

const LOADING_TEXT = 'Ieškoma šalių...';
const NO_RESULTS_TEXT = 'Kelionių pasiūlymų pagal jūsų pateiktą paiešką nėra';
const RESULTS_TEXT = (count) => `Kelionių pasiūlymai į ${countriesText(count)}`;
const DESCRIPTION_TEXT = 'Norėdami matyti daugiau pasiūlymų ir tikslias kainas, paieškoje įveskite konkrečią kelionės kryptį, datą ir keleivių skaičių.';

function CountriesLayout(props) {
  const {limit, center, isLoaded, count, isLoading} = props;

  return (
    <Container>
      <SectionLayout
        center={center}
        noResultsText={NO_RESULTS_TEXT}
        loadingText={LOADING_TEXT}
        loading={isLoading}
        hasResults={isLoaded && count > 0}
        description={DESCRIPTION_TEXT}
        title={RESULTS_TEXT(count)}
      >
        <Countries limit={limit}/>
      </SectionLayout>
    </Container>
  );
}

CountriesLayout.propTypes = {
  limit: PropTypes.number,
  center: PropTypes.bool,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};

CountriesLayout.defaultProps = {
  center: false,
  limit: null,
};

function mapStateToProps(state) {
  const {destinations} = state;
  const {isLoaded, countries, isLoading} = destinations;
  return {isLoaded, count: countries.length, isLoading};
}

export default connect(mapStateToProps)(CountriesLayout);
