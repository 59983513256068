import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  padding: 30px 0 50px 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Link = styled.a`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 14px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Icon = styled.img``;
