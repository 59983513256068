import update from 'immutability-helper';
import {OffersTypes} from 'types';

const initialState = {
  offers: [],
  count: 0,
  paging: {limit: 1000, offset: 0},
  isLoading: false,
  isLoaded: false,
  isLoadingMore: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case OffersTypes.GET_OFFERS_START: {
      return update(state, {
        isLoaded: {$set: false},
        isLoading: {$set: true},
      });
    }

    case OffersTypes.GET_MORE_OFFERS_START: {
      return update(state, {
        isLoadingMore: {$set: true},
      });
    }

    case OffersTypes.GET_OFFERS: {
      return update(state, {
        offers: {$set: payload.offers},
        count: {$set: payload.count},
        isLoading: {$set: false},
        isLoaded: {$set: true},
      });
    }

    case OffersTypes.GET_MORE_OFFERS: {
      return update(state, {
        offers: {$set: state.offers.concat(payload.offers)},
        count: {$set: payload.count},
        isLoadingMore: {$set: false},
      });
    }

    case OffersTypes.SET_PAGING: {
      return update(state, {
        paging: {$set: payload.paging},
      });
    }

    case OffersTypes.GET_MORE_OFFERS_ERROR:
    case OffersTypes.GET_OFFERS_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
