import styled from 'styled-components/macro';
import { Colors } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';

export const Container = styled.div`
  border-radius: 6px;
  width: 100%;
  border: 1px solid ${Colors.BORDER};
  overflow: hidden;
  transition: 0.3s;

  &:hover,
  &:active,
  &:focus {
    box-shadow: rgb(198, 207, 224) 0px 0px 5px;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${Colors.BORDER};
  background-color: ${Colors.MENU_BACKGROUND};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OrderNumberLabel = styled.span`
  color: ${Colors.DROPDOWN_ITEM};
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

export const OrderNumber = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Content = styled.div`
  padding: 20px;
`;

export const Image = styled.img`
  width: 130px;
  height: 100px;
  border-radius: 4px;
`;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 700;
  color: ${Colors.TITLE};
`;

export const Description = styled(LinesEllipsis)`
  font-size: 13px;
  line-height: 1.38;
  color: ${Colors.DROPDOWN_ITEM};
`;

export const ItemLabel = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${Colors.DROPDOWN_ITEM};
  text-transform: uppercase;
  margin-bottom: 3px;
`;

export const ItemValue = styled.span`
  font-size: 13px;
  color: ${Colors.TITLE};
`;
