import _ from 'lodash';
import * as DateUtils from '../date';

export const notEmpty = (value, errorMessage) => !value || _.trim(value).length === 0 ? errorMessage : null;

export const notEmptyArray = (value, errorMessage) => !value || !Array.isArray(value) || value.length === 0 ? errorMessage : null;

export const dateNotEmpty = (value, errorMessage) => DateUtils.getDateValue(value) ? null : errorMessage;

export const isIntegerGreaterOrEqual = (value, compareValue) => {
  if (!parseInt(value)) {
    return false;
  }

  return value >= compareValue;
};

export const isNullOrInteger = (value) => {
  if (value === null) {
    return true;
  }

  return !!parseInt(value);
};

export const isDateOrNull = (value) => {
  if (value === null) {
    return true;
  }

  return !!Date.parse(value);
};


export const isTrue = (value, errorMessage) => {
  return value === false ? errorMessage : null;
};
