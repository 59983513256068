import React, {useState} from 'react';
import {Container, Title, SectionTitle, Value, Section} from './styles';
import {Buttons, Grid} from 'styles';
import {BackButton, Button, Checkbox, Radio} from "components";
import Banks from './Banks';
import PropTypes from "prop-types";
import {SharedTypes} from "utils";
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';

function CheckoutPaymentPage(props) {
  const {passengers, phone, email} = props;
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [commercials, setCommercials] = useState(false);
  const [paymentType, setPaymentType] = useState(null);
  const history = useHistory();

  return (
    <Container>
      <Grid.Row mb={25}>
        <Title>Apmokėkite už kelionę</Title>
      </Grid.Row>
      <Grid.Row mb={10}>
        <SectionTitle>Keleiviai</SectionTitle>
      </Grid.Row>
      {passengers.map((passenger, index) => (
        <Grid.Row key={index} mb={5}>
          <Value>{`${passenger.reference} ${passenger.firstName} ${passenger.lastName} ${passenger.birthDate}`}</Value>
        </Grid.Row>
      ))}
      <Grid.Row mt={20} mb={10}>
        <SectionTitle>Pirkėjas</SectionTitle>
      </Grid.Row>
      <Grid.Row mb={5}>
        <Value>{email}</Value>
      </Grid.Row>
      {phone && (
        <Grid.Row mb={5}>
          <Value>{phone}</Value>
        </Grid.Row>
      )}
      <Grid.Row mt={20} mb={10}>
        <SectionTitle>Organizatorius</SectionTitle>
      </Grid.Row>
      <Grid.Row mb={30}>
        <Value>Baltic Tours</Value>
      </Grid.Row>
      <Grid.Row mb={15}>
        <Section>
          <Grid.Row mb={10}>
            <Radio
              checked={paymentType === 'bankLink'}
              onChange={({value}) => setPaymentType(value ? 'bankLink' : null)}
              name="bankLink"
              label="Bankiniu pavedimu"
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Col ml={30}>
              <Value>Pasirinkite e. banką, per kurį norite sumokėti</Value>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row mt={25}>
            <Banks onChange={() => {}}/>
          </Grid.Row>
        </Section>
      </Grid.Row>
      <Grid.Row mb={30}>
        <Section>
          <Grid.Row mb={10}>
            <Radio
              checked={paymentType === 'cash'}
              onChange={({value}) => setPaymentType(value ? 'cash' : null)}
              name="cash"
              label="Grynaisiais "
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Col ml={30}>
              <Value>Galite atvykti į BalticTours, UAB biurą (Vašingtono a. 1, Vilnius)</Value>
            </Grid.Col>
          </Grid.Row>
        </Section>
      </Grid.Row>
      <Grid.Row mb={25}>
        <Checkbox
          checked={termsAndConditions}
          onChange={({value}) => setTermsAndConditions(value)}
          name="termsAndConditions"
          label="Susipažinau su kelionės sutartimi, „Privatumo politika“,„Svetainės naudojimosi taisyklėmis“ ir su jomis sutinku."
        />
      </Grid.Row>
      <Grid.Row mb={30}>
        <Checkbox
          checked={commercials}
          onChange={({value}) => setCommercials(value)}
          name="commercials"
          label="Noriu, kad man būtų teikiami asmeniniai pasiūlymai, remiantis mano pirkimo ir naršymo istorija baltictours.lt, bei būti informuojamas apie bendras akcijas, nuolaidas ir loterijas."
        />
      </Grid.Row>
      <Grid.Row justifyContent="space-between">
        <BackButton onClick={history.goBack}/>
        <Button
          disabled={!termsAndConditions || !paymentType}
          large
          label="Apmokėti"
          onClick={() => history.push(`/atsiskaitymas/apmokejimas-sekmingas`)}
          theme={Buttons.SUCCESS}
        />
      </Grid.Row>
    </Container>
  );
}

CheckoutPaymentPage.propTypes = {
  passengers: PropTypes.arrayOf(SharedTypes.CheckoutPassengerType).isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {checkout, authentication} = state;
  const {profile} = authentication;
  const {email, phone} = profile;
  const {passengers} = checkout;
  return {passengers, email, phone};
};

export default connect(mapStateToProps)(CheckoutPaymentPage);
