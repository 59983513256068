import styled from 'styled-components/macro';
import { Colors } from 'styles';
import LinesEllipsis from 'react-lines-ellipsis';

export const Container = styled.div``;

export const Title = styled.span`
  color: ${Colors.TITLE};
  font-size: 16px;
  font-weight: 700;
`;

export const Email = styled.a`
  color: ${Colors.LIGHT_PRIMARY};
  font-size: 13px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Description = styled(LinesEllipsis)`
  font-size: 13px;
  text-align: center;
  height: 32px;
  line-height: 16px;
  color: ${Colors.TITLE};
`;
