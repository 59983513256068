import styled from 'styled-components/macro';
import { Colors, Images } from 'styles';

export const Container = styled.div``;

export const Header = styled.div`
  width: 100%;
  padding: 100px 0 80px 0;
  background: url('${Images.BACKGROUND}') no-repeat center/cover, ${Colors.LIGHT_PRIMARY};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1110px) {
    padding: 80px 0 45px;
    margin-bottom: 40px;

    > div {
      padding: 0;
    }

    form {
      padding: 0 15px;
      box-shadow: none;
      background: transparent;

      label {
        color: ${Colors.WHITE};
      }
    }
  }
`;
