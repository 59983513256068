import {useSelector} from "react-redux";

function useCategories() {
  const {categories, isLoaded, count, isLoading} = useSelector(state => state.categories);

  return {
    categories,
    isLoaded,
    count,
    isLoading,
  };
}

export default useCategories;
