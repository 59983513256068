import styled from 'styled-components/macro';
import { Colors, Media } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
  width: 390px;
  box-shadow: 0 1px 5px 0 ${hexToRgba(Colors.BLACK, 0.1)};
  border: solid 1px ${Colors.NEWSLETTER_BACKGROUND};
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  overflow: hidden;

  @media only screen and (max-width: ${Media.TABLET}px) {
    max-width: 477px;
  }

  @media only screen and (max-width: ${Media.MOBILE}px) {
    max-width: 370px;
  }

  @media only screen and (max-width: ${Media.SMALL_MOBILE}px) {
    max-width: 330px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 246px;
  background-color: ${Colors.NEWSLETTER_BACKGROUND};
  border-radius: 8px;
`;

export const Details = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;
