import React from 'react';
import {Container} from "./styles";
import {Banner} from "components";
import {Grid} from "styles";
import {OffersLayout} from "layouts";
import {useFilter} from "hooks";

function LastMinuteOffersPage() {
  const filter = useFilter();

  return (
    <Container>
      <Grid.Row mb={40}>
        <Banner title="Paskutinės minutės pasiūlymai"/>
      </Grid.Row>
      <Grid.Container>
        <OffersLayout filter={{...filter, isLastMinute: true}}/>
      </Grid.Container>
    </Container>
  );
}

export default LastMinuteOffersPage;
