import styled from 'styled-components/macro';
import { Colors } from 'styles';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
  width: 100%;
  display: flex;
  border-radius: ${(props) => props.borderRadius}px;
  overflow: hidden;
  align-items: stretch;
`;

export const Details = styled.div`
  background-color: ${hexToRgba(Colors.GREEN, 0.28)};
  padding: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  display: flex;
  width: 54px;
  align-items: center;
  background-color: ${Colors.GREEN};
  justify-content: center;
`;

export const Icon = styled.img``;

export const Title = styled.span`
  font-size: 14px;
  line-height: 1.71;
  color: ${Colors.SUCCESS_TITLE};
  font-weight: 700;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 1.71;
  color: ${Colors.SUCCESS_TITLE};
`;
