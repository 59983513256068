import {TripsTypes} from "types";
import {TripsService} from "services";

export function getTrips(request) {
  return async dispatch => {
    try {
      dispatch({type: TripsTypes.GET_TRIPS_START});
      const {result, count} = await TripsService.getTrips(request);
      dispatch({type: TripsTypes.GET_TRIPS, payload: {trips: result, count}});
    } catch (exception) {
      dispatch({type: TripsTypes.GET_TRIPS_ERROR});
    }
  };
}
