import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${Colors.LIGHT_PRIMARY};
`;

export const Icon = styled.img`
  margin-right: 15px;
  height: 14px;
`;
