import axios from 'axios';
import {TVS_URL} from "../config";
import {ApiUtils} from "utils";

const tvsAxiosInstance = axios.create({
  baseURL: `${TVS_URL}/api`,
});

tvsAxiosInstance.interceptors.response.use(async response => {
  await ApiUtils.sleep();
  return response.data;
});

export default tvsAxiosInstance;
