import styled from 'styled-components/macro';
import { Colors } from 'styles';

const getRight = ({ left, fullWidth }) => {
  if (fullWidth || left) {
    return 0;
  }

  return 'auto';
};

export const Container = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div``;

export const DropdownContainer = styled.div`
  z-index: 999;
  position: ${(props) => (props.forceOpen ? 'relative' : 'absolute')};
  overflow: hidden;
  top: ${(props) => (props.forceOpen ? '20px' : 'calc(100% + 6px)')};
  left: ${(props) => (props.fullWidth ? 0 : 'auto')};
  right: ${(props) => getRight(props)};
  display: ${(props) => (props.active ? 'block' : 'none')};
  background-color: ${(props) => (props.transparent ? 'transparent' : Colors.WHITE)};
  box-shadow: ${(props) => (props.transparent ? 'none' : `0 5px 11px 0 rgba(185, 175, 175, 0.5)`)};
  border-radius: ${(props) => (props.transparent ? 0 : 8)}px;
`;
