import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const ListContent = styled.div`
  position: relative;
  width: calc(100% + 50px);
  left: -25px;
  right: -25px;
`;
