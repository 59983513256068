import styled from 'styled-components/macro';
import { Colors } from 'styles';

export const Container = styled.div`
  padding: 30px 0;
  background-color: ${Colors.WHITE};
`;

export const DropdownItemContainer = styled.div`
  padding: 0 50px 0 20px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;
